import React from "react"
import Stories from "../../../components/employerHomePage/stories"
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent"
import DetailsPage from "./details-page"

const CaseStudy = props => {
  const { ID } = props.params
  return (
    <>
      <PageContentEmployerScreen location={props.location} pageSize={24}>
        <DetailsPage id={ID} />
        <Stories heading={""} isReadMore={true} />
      </PageContentEmployerScreen>
    </>
  )
}

export default CaseStudy
