import React from "react";
import { Col, Row } from "antd";
import LogoSlider from "react-slick";
import { ServiceData } from "../../../services/EmployerHomePageDara";

const Service = () => {
  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <section className="hrServiceSection px-3 px-md-6">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="heading">
            <h2>Why Adecco?</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="Data">
            <LogoSlider {...slider}>
              {ServiceData?.data?.map(data => (
                <div key={data.title} className="items wyAdecoCard">
                  <img loading="lazy"  src={data.img} alt={data.title} />
                  <h2>{data.title}</h2> <p>{data.desc}</p>
                </div>
              ))}
            </LogoSlider>
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default Service
