import { HelpCenterData } from "../../../services/HelpCenterData"
import React, { useEffect, useState } from "react"
import { Row, Col, Breadcrumb, Modal, Select } from "antd"
//@ts-ignore
import pfPresentation from "../pdf/PF Self Help Online Portal_Final.pdf"
//@ts-ignore
import PageContent, {
  PageContentEmployerScreen,
} from "../../components/PageContent/PageContent"
import { text } from "../../constants"
import { Banner } from "../../components/PfHeplCenter/banner"
import { Link } from "gatsby"
import RenderMenu from "../../components/Menu/RenderMenu"
import { contactUs } from "../../../services/HeaderData"
import { PfData } from "../../../services/PfHelpCenterData"

const { Option } = Select
const PFHelpCenter = props => {
  const pageData = HelpCenterData.PFHelpCenter
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState([])
  const [id, setId] = useState(null)

  const onclose = () => {
    setVisible(false)
    setId(null)
    setValue([])
  }
  const onOpen = data => {
    setId(data)
    setVisible(true)
  }
  const onChange = e => {
    setValue([e])
  }
  // useEffect(()=>{
  //   if(id && value && value[0] === "Hindi") {
  //     window.open(id?.hindi_link, '_blank');
  //     // onclose();
  //   }
  //   if(id && value && value[0] === "English") {
  //     window.open(id?.english_link, '_blank');
  //     // onclose();
  //   }
  // },[value,id])
  return (
    <>
      <Modal
        title="Select Language"
        visible={visible}
        footer={null}
        // onOk={this.handleOk}
        // confirmLoading={confirmLoading}
        maskClosable
        onCancel={() => onclose()}
      >
        <Select
          showSearch
          style={{ width: "100%" }}
          className="categorySort"
          placeholder="Select Language"
          optionFilterProp="children"
          onChange={onChange}
          value={value}
        >
          <Option value="Hindi">Hindi</Option>
          <Option value="English">English</Option>
        </Select>

        {value[0] === "Hindi" && id?.hindi_iframe && (
          <div
            className="my-2"
            dangerouslySetInnerHTML={{ __html: id?.hindi_iframe }}
          />
        )}
        {value[0] === "English" && id?.english_iframe && (
          <div
            className="my-2"
            dangerouslySetInnerHTML={{ __html: id?.english_iframe }}
          />
        )}
      </Modal>

      <Banner />
      <Row className="customer-breadcrumb mt-5 p-3">
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item overlay={<RenderMenu data={contactUs} />}>
              Contact Us
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="">PF Help Center</a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <PageContentEmployerScreen class="" location={props.location}>
        <div className="px-8">
          <Row className="text-align-center">
            <h1 className="c-primary">
              <b>{pageData.title}</b>
            </h1>
            <Row
              className="fw-500 font-normal"
              style={{ opacity: ".8", lineHeight: "1.38" }}
              dangerouslySetInnerHTML={{ __html: pageData.description }}
            ></Row>
          </Row>
          <Row className="mt-4">
            <h2>{pageData.contents[0].subTitle}</h2>
            <p>
              <ul>
                {pageData.contents[0].contentList?.map((data, index) => (
                  <li key={index}>{data}</li>
                ))}
              </ul>
            </p>
            <p>{pageData.contents[0].footerContent}</p>
          </Row>
          <Row>
            <h2>{pageData.contents[1].subTitle}</h2>
            <div>
              <img loading="lazy" 
                src={pageData.contents[1].image}
                alt="image loading"
                className="w-100 h-100"
                style={{
                  objectFit: "cover",
                  borderWidth: "10px",
                  borderColor: "black",
                }}
              />
            </div>
          </Row>
          <Row justify="center" align="middle" className="mt-4">
            <Row justify="center" align="middle" type="flex">
              <img loading="lazy" 
                src={pageData.attachmentPreviewImage}
                alt="image loading"
                className="w-8 h-8 center ml-3 mt-2"
                style={{
                  objectFit: "cover",
                  borderWidth: "10px",
                  borderColor: "black",
                }}
              />
            </Row>
            <Row justify="center" align="middle" type="flex">
              <a
                download
                href={pageData.attachment}
                className="font-h6 pt-2 c-primary align-center mt-2"
              >
                <span>{text.CLICK_TO_DOWNLOAD_PF_ATTACHMENT}</span>
              </a>
            </Row>
            <Row gutter={[24, 24]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="cardImg">
                  {PfData.map(data => (
                    <div>
                      <a key={data.id} onClick={() => onOpen(data)}>
                        <img loading="lazy"  src={data.img} alt="cardImg" />
                      </a>
                      <p>{data.title}</p>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Row>
        </div>
      </PageContentEmployerScreen>
    </>
  )
}

export default PFHelpCenter
