import { Col, Row } from "antd"
import React from "react"
import "./common.less"
import BannerImg from "../../images/OurValues/Updated-Banner_4x.webp"

export const Banner = () => {
  return (
    <section className="overvalueBannerSection">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div
            className="hero-section"
            style={{ backgroundImage: `url(${BannerImg})`,backgroundColor:"#FFFFFF" }}
          >
            <div className="overlay"></div>
            <div className="content-section">
              <h1>Our Values</h1>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  )
}
