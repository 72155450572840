import { Row } from "antd"
import React, { useEffect } from "react"
import AdviceDetails from "../../components/CareerAdvice/adviceDetails"
import Advice from "../../components/JobSeekerHomePage/advice"
import PageContent, {
  PageContentSubmitCvScreen,
} from "../../components/PageContent/PageContent"
import "../../components/HomePage/HomePageStyle.less"
import "../../styles/app.less"

export default function CareerAdvice(props) {
  const { ID } = props.params
  useEffect(() => {}, [ID])

  return (
    <PageContentSubmitCvScreen location={props.location}>
      <AdviceDetails id={ID} />
      <Advice title={""} slidesToShow={3} />
    </PageContentSubmitCvScreen>
  )
}
