import React from "react"
import { navigate } from "gatsby"
import {
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  message,
  Row,
  Col,
  Icon,
} from "antd"
import Axios from "axios"
import {
  characterValidation,
  countryCodeData,
  emailValidation,
  phoneNumberValidation,
  validateFileSize,
  validateFileType,
} from "./FormUtils"
import { requestData } from "../../helper/dimensionHelper"
import ModalComponent from "../ModalComponent/Modal"
import { TermsConditionData } from "../../../services/termsAndConditionData"
import uuid from "react-uuid"
import CommonInput from "./commonInput"
import CommonSelect from "./commonSelect";
const { Option } = Select

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

function SubmitYourCvF(props) {
  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
  } = props.form
  const [agreement, setAgreement] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [country, setCountry] = React.useState("India:+91")
  const [modalVisibility, setModalVisibility] = React.useState()
  const [othersCountryCode, setOthersCountryCode] = React.useState(false)
  const [isFileUploading, setIsFileUploading] = React.useState(false)
  const [filename, setFilename] = React.useState("")

  React.useEffect(() => {
    props.form.validateFields()
  }, [])

  const handleCountry = value => {
    if (value === "others_country") {
      setOthersCountryCode(true)
    } else {
      setOthersCountryCode(false)
    }
    setCountry(value)
  }

  const getBase64 = file => {
    let reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file)
      reader.onload = function() {
        let result: any = reader.result
        result = result.split("base64")[1]
        resolve(result)
      }
    })
  }

  const onFileChange = async (data: any) => {
    setIsFileUploading(true)
    let files = data.target.files
    let filenameSplit = files[0].name.split(".")
    if (!validateFileType(filenameSplit[filenameSplit.length - 1])) {
      data.target.value = null
      setIsFileUploading(false)
      return
    }
    if (!validateFileSize(files[0])) {
      data.target.value = null
      setIsFileUploading(false)
      return
    }
    setIsFileUploading(true)
    let filename = `${uuid()}_resume.${filenameSplit[filenameSplit.length - 1]}`
    const base64Format = await getBase64(files[0])
    if (props.jobApply && props.jobApply.type === "JOB_APPLY"){
      var bodyFormData = new FormData();
    bodyFormData.append('files', files[0]); 
    bodyFormData.append('applicantID',  Date.now()); 
      const headers :any= {
        "Access-Control-Allow-Origin": "*", 
        "Content-Type": "multipart/form-data",
      }
      Axios.post(
        `https://api.adecco.co.in/JobSite/JobFileUpload`,
        bodyFormData,
        headers
        )
        .then(res => {
          console.log(res)
          if(res?.data && res?.data?.fileName){
            setFilename(res.data.fileName)  
            setIsFileUploading(false)
            message.success("File upload successfully!");
          }else{
          message.error("Something went wrong!")
          }
        })
        .catch(err => {
          if (err?.data?.errors) {
            Object.keys(err.data.errors).forEach(key => {
              err.data.errors[key].forEach(error => {
                message.error(error)
              })
            })
          }
  
          message.error("Something went wrong!")
          setIsFileUploading(false)
        })
    }else{
      Axios.post(
        `${process.env.GATSBY_MIDDLEWARE_API}api/uploadFile`,
        JSON.stringify({
          file_base_64: base64Format,
          filename: filename,
        })
      )
        .then(data => {
          setIsFileUploading(false)
          setFilename(filename)
        })
        .catch(err => setIsFileUploading(false))
      setFilename(filename)
    }
   
  }
  const onFinish = (e: any) => {
    e.preventDefault()
    props.form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err) {
        setLoading(true)
        if (props.jobApply && props.jobApply.type === "JOB_APPLY") {
          const countryData = values.countryCode.split(":")
          const data = {
            appliedJobCode: props.jobApply.appliedJobCode,
            firstName: values.employeeFirstName,
            CountryCode: countryData[0].substring(0, 3).toUpperCase(),
            CreatedBy: "Adecco_Web_PRD",
            lastName: values.employeeLastName,
            CreatedDate: new Date(),
            phone: countryData[1] + values.phoneNumber,
            experience: values.experience,
            JobTitle: props.jobApply.data,
            email: values.referralEmail,
            currentLocation: values.location,
            resumeFileName: filename,
            linkedProfile: values.linkdin_profile,
          }
          requestData(
            "POST",
            "JobSite/JobApplication",
            JSON.stringify({ ...data })
          )
            .then(res => {
              message.success("The form has been successfully submitted.")
              props.form.resetFields()
              navigate("/job-seeker/thank-you")
            })
            .catch(err => {
              if (err?.data?.errors) {
                Object.keys(err.data.errors).forEach(key => {
                  err.data.errors[key].forEach(error => {
                    message.error(error)
                  })
                })
              }
              message.error(
                "We encountered an error while submitting the form."
              )
            })
            .finally(() => setLoading(false))
        } else {
          const countryData = values.countryCode.split(":")
          Axios.post(
            `${process.env.GATSBY_MIDDLEWARE_API}api/EmailTrigger`,
            JSON.stringify({
              employeeName: values.employeeFirstName + values.employeeLastName,
              referralEmail: values.referralEmail,
              phoneNumber: countryData[1] + values.phoneNumber,
              country: countryData[0],
              location: values.location,
              experience: values.experience,
              agreement: values.agreement.toString(),
              attachment: filename,
              type: "submit_your_cv",
            })
          )
            .then(res => {
              message.success("The form has been successfully submitted.")
              props.form.resetFields()
            })
            .catch(err => {
              message.error(
                "Something went wrong please try again after sometime!"
              )
              // message.error(
              //   "We encountered an error while submitting the form."
              // )
            })
            .finally(() => setLoading(false))
        }
      } else {
        message.error("We encountered an error while submitting the form.")
      }
    })
  }

  const handleModalView = () => {
    setModalVisibility(true)
  }

  const changeModalView = (value: boolean) => {
    setModalVisibility(value)
  }

  const checkCheckBox = (rule, value, callback) => {
    if (!value) {
      setAgreement(false)
      callback("Please agree the terms and conditions!")
    } else {
      setAgreement(true)
      callback()
    }
  }

  const employeeFirstNameError =
    isFieldTouched("employeeFirstName") && getFieldError("employeeFirstName")
  const employeeLastNameError =
    isFieldTouched("employeeLastName") && getFieldError("employeeLastName")
  const countryError =
    isFieldTouched("countryCode") && getFieldError("countryCode")
  const phoneNumberError =
    isFieldTouched("phoneNumber") && getFieldError("phoneNumber")
  const emailError =
    isFieldTouched("referralEmail") && getFieldError("referralEmail")
  const resumeError = isFieldTouched("resume") && getFieldError("resume")
  const experienceError =
    isFieldTouched("experience") && getFieldError("experience")
  const locationError = isFieldTouched("location") && getFieldError("location")

  const experienceOption = [
    "0 - 2 Years",
    "3 - 5 Years",
    "5 - 10 Years",
    "10+ Years",
  ]

  return (
    <>
      <Form name="basic" onSubmit={onFinish} autoComplete="off">
        <Row type="flex">
          <Col span={24} lg={12}>
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={employeeFirstNameError ? "error" : ""}
              help={employeeFirstNameError || ""}
              name={"employeeFirstName"}
              rules={[
                {
                  required: true,
                  message: "Please enter your first name",
                },
                { max: 50, message: "Character limit exceeded" },
                {
                  pattern: characterValidation,
                  message: "Please enter valid characters",
                },
              ]}
              placeholder={"First Name"}
              inputIcon={null}
              label={null}
            />
          </Col>
          <Col span={24} lg={12}>
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={employeeLastNameError ? "error" : ""}
              help={employeeLastNameError || ""}
              name={"employeeLastName"}
              rules={[
                {
                  required: true,
                  message: "Please enter your last name",
                },
                { max: 50, message: "Character limit exceeded" },
                {
                  pattern: characterValidation,
                  message: "Please enter valid characters",
                },
              ]}
              placeholder={"Last Name"}
              inputIcon={null}
              label={null}
            />
          </Col>
          <Col span={24} lg={12}>
            <CommonSelect
              getFieldDecorator={getFieldDecorator}
              validateStatus={countryError ? "error" : ""}
              help={countryError || ""}
              rules={[
                {
                  required: true,
                  message: "Please select your country code",
                },
              ]}
              name="countryCode"
              placeholder={"Select Country*"}
              onchange={value => handleCountry(value)}
              loading={loading}
              initialValue={"India:+91"}
              options={countryCodeData
                .sort((a: any, b: any) => {
                  if (a.country < b.country) return -1
                  if (a.country > b.country) return 1
                  return 0
                })
                ?.map((data, index) => {
                  return (
                    <Option
                      key={index}
                      value={data.country + ":" + data.countryCode}
                    >
                      {data.country + " : " + data.countryCode}
                    </Option>
                  )
                })}
              label={null}
              other={<Option value={"others_country"}>Others</Option>}
            />
          </Col>
          <Col span={24} lg={12}>
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={phoneNumberError ? "error" : ""}
              help={phoneNumberError || ""}
              name={"phoneNumber"}
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number",
                },
                {
                  pattern: phoneNumberValidation,
                  message: "Please enter valid digits!",
                },
                { min: 10, message: "Please enter valid digits!" },
                { max: 10, message: "Please enter valid digits!" },
              ]}
              placeholder={"Phone Number *"}
              inputIcon={null}
              label={null}
            />
          </Col>
          <Col span={24} lg={12}>
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={emailError ? "error" : ""}
              help={emailError || ""}
              name={"referralEmail"}
              rules={[
                {
                  pattern: emailValidation,
                  message: "Please enter a valid Email!",
                },
                {
                  required: true,
                  message: "Please enter your Email!",
                },
              ]}
              placeholder={"Email *"}
              inputIcon={null}
              label={null}
            />
          </Col>
          <Col span={24} lg={12}>
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={locationError ? "error" : ""}
              help={locationError || ""}
              name={"location"}
              rules={[
                {
                  required: true,
                  message: "Please enter Current Location",
                },
              ]}
              placeholder={"Current Location*"}
              inputIcon={null}
              label={null}
            />
          </Col>
          <Col span={24} lg={12}>
            <CommonSelect
              getFieldDecorator={getFieldDecorator}
              validateStatus={experienceError ? "error" : ""}
              help={experienceError || ""}
              rules={[
                {
                  required: true,
                  message: "Please select your experience",
                },
              ]}
              name="experience"
              placeholder={"Experience *"}
              onchange={value => {}}
              loading={loading}
              initialValue=""
              options={experienceOption.map(data => (
                <Option key={data} value={data}>
                  {data}
                </Option>
              ))}
              other={null}
              label={null}
            />
          </Col>
        </Row>
        <Row>
          {props.jobApply.type === "JOB_APPLY" &&<>
          <div className="mb-3 c-secondary">Attach Resume </div>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              name="resume"
              validateStatus={resumeError ? "error" : ""}
              help={resumeError || ""}
            >
              {getFieldDecorator("resume", {
                rules: [
                  {
                    required: true,
                    message: "Please upload resume!",
                  },
                ],
              })(
                <Input
                  type="file"
                  onChange={onFileChange}
                  accept=".pdf,.doc,.docx"
                  size="large"
                />
              )}
            </Form.Item>
            <Row className="text-left mr-1">
              <small className="c-secondary text-left">
                Supported Formats: doc,docx,pdf,rtf Max file size: 5 MB
              </small>
            </Row>
          </Col>
          </>}
{/* 
          <Col span={24} lg={12}>
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={""}
              help={""}
              name={"linkdin_profile"}
              rules={[
                {
                  required: false,
                },
                { max: 250, message: "Character limit exceeded" },
              ]}
              placeholder={"Linkedin Profile (optional)"}
              inputIcon={
                <Icon
                  type="linkedin"
                  theme="filled"
                  style={{ color: "#0e76a8" }}
                />
              }
              label={null}
            />
            <Row className="text-left ml-1">
              <small className="c-secondary text-left">
                Note: paste your linkedin profile link here
              </small>
            </Row>
          </Col> */}
        </Row>
        <Row>
          <Form.Item
            className=""
            name="remember"
            valuePropName="checked"
            wrapperCol={{ span: 20 }}
          >
            {getFieldDecorator("agreement", {
              valuePropName: "checked",
              initialValue: "true",
              rules: [{ validator: checkCheckBox }],
            })(
              <div>
                <Checkbox className="c-secondary pr-2" defaultChecked={true}>
                  I agree to the{" "}
                </Checkbox>
                <a>
                  <small className="c-info" onClick={handleModalView}>
                    Terms and conditions
                  </small>
                </a>
                {modalVisibility && (
                  <ModalComponent
                    title="Terms and condition"
                    isVisible={modalVisibility as boolean}
                    getValueModal={changeModalView}
                    modalData={TermsConditionData()}
                  />
                )}
              </div>
            )}
          </Form.Item>
        </Row>
        <Form.Item className="mediaFormButton" wrapperCol={{ span: 16 }}>
          {loading ? (
            <Button className="text-center" loading={loading} disabled={true}>
              {props.btnName ? props.btnName : "Submit"}
            </Button>
          ) : (
            <Button
              className="text-center"
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              {props.btnName ? props.btnName : "Submit"}
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  )
}

export const WrappedSubmitYourCvF = Form.create({
  name: "submit-your-cv",
})(SubmitYourCvF)
