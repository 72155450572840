import React from "react";
import { Link } from "gatsby";
import { Breadcrumb, Col, Row } from "antd";
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent";
import Banner from "./banner";
import CaseStudyList from "./caseStudyList";

const CaseStudy = props => {
  return (
    <>
      <PageContentEmployerScreen location={props.location} pageSize={24}>
        <Banner title={"Case Studies"} BannerImg={null} />
        <Row className="custome-breadcrumb  pt-3 pl-3 mb-0">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/employer"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href="">Case Studies</a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <CaseStudyList />
      </PageContentEmployerScreen>
    </>
  )
}

export default CaseStudy
