import React from "react"
import { Row } from "antd"
import { PageContentSubmitCvScreen } from "../components/PageContent/PageContent"
import { Banner } from "../components/Security/banner"
import CustomeBreadcrumb from "../components/common/breadcrumb"

const TermsOfUse = (props: any) => {
  return (
    <PageContentSubmitCvScreen class="bg-white pt-5">
      <Banner title={'Terms Of Use'} />
      <CustomeBreadcrumb
        home_path={"/"}
        home={"Home"}
        current={"Terms Of Use"}
      />
      <Row
        className="fw-500 font-normal  px-sm-7 px-4 py-2"
      >
       <p>These terms of use (the “Terms of Use”) tell you the rules for using the website adeccogroup.com operated by Adecco India Private Limited with registered address Summit B, Brigade Metropolis, B-9, 13th Floor,ITPL Main Road Garudachar Palya, Mahadevpura, Bengaluru Bangalore KA 560048 India (hereinafter referred to as “Adecco”, “we” or “us”) including the content made available through this website (the “Website”).</p>
</Row>
<Row
      className="fw-500 font-normal  px-sm-7 px-4 py-2">
       <p>There is an index below so you can go straight to the relevant sections if you prefer.</p>

<h3 className="fw-600">INDEX</h3>
<ol>
  <li className="fw-600"> Accepting these Terms of Use</li>
  <li className="fw-600">Access to the Website</li>
<li className="fw-600">Use of the Website</li>
<li className="fw-600">Content</li>
<li className="fw-600">No business relationship or investment offer</li>
<li className="fw-600">Intellectual property rights</li>
<li className="fw-600">Links from our Website and linking to our Website</li>
<li className="fw-600">Personal information</li>
<li className="fw-600">No warranties, limitation of liability</li>
<li className="fw-600">Our responsibility for loss or damage suffered by you</li>
<li className="fw-600">Governing law and jurisdiction</li>
<li className="fw-600">Amendments to these Terms of Use</li>
<li className="fw-600">Contact</li>






  </ol>
    <div>
      <h3 className="fw-600 pl-3">1. Accepting these Terms of Use</h3>
      <p>By using the Website (“you”), you confirm that you accept the Terms of Use in force at the time of your visit to the Website and agree to comply with such Terms of Use. If you do not agree to these Terms of Use, you must not use our Website. We recommend that you print or download a copy of these terms for future reference.</p>
     <p>The content we make available to you through the Website (the “Content”) may be governed by additional special conditions (“Special Conditions”) which you accept each time you access such Content. In the event of a conflict or inconsistency between these Terms of Use and any Special Conditions, the Special Conditions will prevail over these Terms of Use.</p>
      </div>

    <div>
      <h3 className="fw-600 pl-3">2. Access to the Website</h3>
      <p>Access to the Website is permitted on a temporary basis, and we reserve the right to withdraw or amend the Content we provide on the Website without notice to reflect changes to our products, our services, our users’ needs and our business priorities.</p>
     <p>From time to time, we may restrict access to some parts of the Website, or the entire Website. Any such restriction shall be at our sole discretion for any reason whatsoever including you violating the Terms of Use, using the site for unlawful or immoral purposes or infringing the integrity, security or the reputation of the Website, or to allow us to maintain, improve or modify the Website and/or the Content.</p>
     <p>We do not guarantee to provide continuous, uninterrupted and secure access to the Website and will not be liable if for any reason the Website is unavailable, totally or partially, at any time or for any period.</p>
     <p>We do not guarantee that the functional aspects of the Website and/or the Content will be error-free or that the servers that make it available are free of viruses or other harmful components.</p>
      </div>
      <div>
      <h3 className="fw-600 pl-3">3. Use of the Website</h3>
      <p>You remain free to make your own decisions and are solely responsible for the use you make of the Website.</p>
      <p>You are responsible for making all arrangements necessary for you to have access to the Website. You are also responsible for ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and any applicable Special Conditions, and that they comply with them.</p>
      <p>If you choose, or you are provided with, a user identification code, username, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any third party. We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our opinion you have failed to comply with any of the provisions of these Terms of Use.</p>
      <p>By accessing the Website, you agree that you will not perform any acts that may damage our image, interests or rights or those of any of our affiliates (“Adecco Group Companies”), or that may damage, render useless or overload the Website, or that may impede, in any way, the normal use of the Website by other visitors.</p>
      <p>We implement reasonable security measures which are adequate to detect the existence of viruses. Nevertheless, you must be aware that the existing security measures for computer systems on the internet are not entirely trustworthy and that, therefore, we cannot guarantee the non-existence of viruses or any other elements that may cause alterations to your computer systems (hardware and software) or to your data and files contained in your systems.</p>
      <p>You must not misuse our Website by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our Website, the server on which our Website is stored or any server, computer or database connected to our Website. You must not attack our Website via a denial-of-service attack or a distributed denial-of service attack.</p>
      <p>By breaching this provision, you would commit a criminal offence under India laws. We will report any such breach to the relevant law enforcement authorities and we will cooperate with those authorities. In the event of such a breach, your right to use our Website will cease.</p>
      </div>
      <div>
      <h3 className="fw-600 pl-3">4. Content</h3>
      <p>We shall not be under any duty to check the accuracy of the Content, and do not guarantee the usefulness, preciseness, completeness or relevance of the Content and/or that such Content is up to date. We expressly exclude any and all liability for errors or omissions with respect to the Content and the Website, save to the extent that such liability arises from our fraud or fraudulent misrepresentation or from any death or personal injury that arises due to our negligence.</p>
      <p>The inclusion of the Content on the Website does not in any way constitute the provision of staffing services or any other kind of services. Adecco and the Adecco Group Companies expressly exclude any and all kind of liability for decisions made by you based on the Content.</p>
      </div>
      <div>
      <h3 className="fw-600 pl-3">5. No business relationship or investment offer</h3>
      <p>The Website is not intended to establish a business relationship of any kind between you and us.</p>
      <p>The information provided on the Website does not constitute an offer of or solicitation for the purchase or disposal of, trading or any transaction in any Adecco securities. You should not rely on this information to make investment decisions.</p>
      </div>
      <div>
      <h3 className="fw-600 pl-3">6. Intellectual property rights</h3>
      <p>All Content on the Website is either owned by us or is licensed for use by us. Everything you see or read on the Website (such as images, photographs, illustrations, texts, video clips and other materials) is protected worldwide by copyright, design, trademark and other intellectual property laws.</p>
      <p>You must always respect all intellectual property rights in the Website and the Content, whether owned by us, any of the Adecco Group Companies or a third party. You must not obtain or attempt to obtain any of the Content by means or procedures other than those which have been made available to you by the Website.</p>
      <p>In no event will these Terms of Use or your use of the Website grant you any rights in the Website or the Content other than those set out in these Terms of Use or in any Special Conditions.</p>
      <p>You are therefore expressly prohibited from carrying out any reproduction, transformation, distribution or public communication of, or making available, extracting, reusing, resending or in any other way using, by any means or procedure, any parts of the Website or the Content, except as allowed by these Terms of Use or any relevant Special Conditions, or when you are allowed to do so by applicable law, or when explicit authorization has been provided by the Adecco Group or the holder of the relevant rights.</p>
      </div>
      <div>
        <h3 className="fw-600 pl-3">7. Links from our Website and linking to our Website</h3>
        <p>Where the Website contains links to other websites and resources provided by third parties, these links and resources are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.</p>
        <p>We have no control over the content of those sites or resources.</p>
        <p>We recommend that you read the terms of use of third-party websites and check them regularly. You are solely responsible for deciding whether to access third-party websites. Following any link to any third-party site is at your own risk.</p>
        <p>You must not establish a link to our Website from a third-party website unless you have obtained our prior written authorization. We reserve the right to withdraw any linking permission without notice.</p>
      </div>
      <div>
        <h3 className="fw-600 pl-3">8. Personal information</h3>
        <p>For further information on how we use your personal information, please see our Privacy Notice (as updated from time to time).</p>
       </div>
       <div>
        <h3 className="fw-600 pl-3">9. No warranties, limitation of liability</h3>
        <p>The Content is provided for general information only. The Website and the Content is not intended to provide you with advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the Content.</p>
        <p>Although we make reasonable efforts to update the information on the Website, we make no representations, warranties or guarantees that the Website and/or the Content is accurate, complete, error-free or up to date or that use of the Website and/or the Content will not infringe rights of third parties.</p>
        <p>The inclusion of the Content on the Website does not in any way constitute the provision of staffing services or any other kind of services. Adecco and the Adecco Group Companies expressly exclude any and all kind of liability for decisions made by you based on the Content.</p>
       </div>
       <div>
        <h3 className="fw-600 pl-3">10. Our responsibility for loss or damage suffered by you</h3>
        <p>This Website is provided to you without charge. As a result, use of the Website is at your own risk but we do not exclude or limit in any way our liability to you where it would be unlawful to do so.</p>
        <p>Where we are permitted to exclude liability by applicable law, we will not be liable for any loss or damage arising out of or in connection with the use of the Website or the Content. In particular, we will not be liable for any indirect or consequential losses such as the loss of use, interruption of business, lost profits or lost data.</p>
        </div>
        <div>
        <h3 className="fw-600 pl-3">11. Governing law and jurisdiction</h3>
        <p>These Terms of Use and all issues regarding this Website and its Content are governed by Indian laws and shall be subject to the exclusive jurisdiction of the courts of Bangalore, India save that (i) we retain the right to bring proceedings against you for breach of these Terms of Use before a court of competent jurisdiction in your country of residence or a court of competent jurisdiction in any other country; and (ii) you can bring proceedings against us for breach of these Terms of Use in the courts of the jurisdiction where you usually live.</p>
        </div>
        <div>
        <h3 className="fw-600 pl-3">12. Amendments to these Terms of Use</h3>
        <p>We may update these Terms of Use from time to time. If you are a visitor to the Website, please check these Terms of Use to ensure that you understand the Terms of Use that apply at that time. If you do not agree to them, please do not use the Website. If you are a registered user of our services and have agreed to these Terms of Use, we will provide a notice to you, using one of the typical methods we communicate with you.</p>
        </div>
        <div>
        <h3 className="fw-600 pl-3">13. Contact</h3>
        <p>Questions, comments and requests regarding this Website are welcomed and should be addressed to Summit B, Brigade Metropolis, B-9, 13th Floor,ITPL Main Road Garudachar Palya, Mahadevpura, Bengaluru Bangalore KA 560048 India, or via our contact form.</p>
        </div>
      </Row>
      
    </PageContentSubmitCvScreen>
  )
}

export default TermsOfUse
