import React, { Fragment } from "react";
import { Row, Col } from "antd";
import securityImage from "../../images/security.webp";

export function SecurityScreen({ pageData }) {
  return (
    <Fragment>
      <Row type="flex" justify="space-between" align="middle">
        <Col xs={{ span: 24 }} lg={{ span: 11 }} md={{ span: 24 }}>
          <Row
            className="font-medium lh-x-large"
            dangerouslySetInnerHTML={{ __html: pageData.description }}
            style={{ opacity: "0.9" }}
          ></Row>
        </Col>
        <Col
          xs={{ span: 24 }}
          lg={{ span: 11 }}
          md={{ span: 24 }}
          className="pt-5 pt-md-0"
        >
          <img loading="lazy"  src={securityImage} alt="security-image" className="mw-100" />
        </Col>
      </Row>
    </Fragment>
  )
}
