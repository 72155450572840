import React from "react";
import { Banner } from "../../components/CareerAdvice/banner";
import CareerAdviceList from "../../components/CareerAdvice/careerAdviceList";
import CustomeBreadcrumb from "../../components/common/breadcrumb";
import { PageContentSubmitCvScreen } from "../../components/PageContent/PageContent";

const CareerAdvice = props => {
  return (
    <PageContentSubmitCvScreen location={props.location}>
      <Banner BannerImg={null} />
      <CustomeBreadcrumb
        home_path={"/"}
        home={"Home"}
        current={"Career Advice"}
      />
      <CareerAdviceList />
    </PageContentSubmitCvScreen>
  )
}

export default CareerAdvice
