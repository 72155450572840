
export const linkResourceData = [
    // {
    //     title:'Workday - Techno Functional Consultant',
    //     jobLocation:'Hyderabad',
    //     linkName:'find out more',
    //     link:'https://foeurope.my.salesforce-sites.com/jobboard/JobRegisterForm?JobId=a1h6M000007l34cQAA'
    // },
    // {
    //     title:'Oracle SCM Manual/Automation Tester',
    //     jobLocation:'Bangalore',
    //     linkName:'find out more',
    //     link:'https://foeurope.my.salesforce-sites.com/jobboard/JobRegisterForm?JobId=a1h6M000007l35pQAA'
    // },
    // {
    //     title:'Oracle EBS SCM',
    //     jobLocation:'Hyderabad',
    //     linkName:'find out more',
    //     link:'https://foeurope.my.salesforce-sites.com/jobboard/JobRegisterForm?JobId=a1h6M000008sIgGQAU'
    // },
    // {
    //     title:'Orchestration 3',
    //     jobLocation:'Hyderabad',
    //     linkName:'find out more',
    //     link:'https://foeurope.my.salesforce-sites.com/jobboard/JobRegisterForm?JobId=a1h6M000007kyPhQAI'
    // },
    // {
    //     title:'Frontend Developer',
    //     jobLocation:'Bangalore',
    //     linkName:'find out more',
    //     link:'https://foeurope.my.salesforce-sites.com/jobboard/JobRegisterForm?JobId=a1h6M000008sHeYQAU'
    // },
    {
        title:'Data Analyst',
        jobLocation:'Bangalore',
        linkName:'find out more',
        link:'https://foeurope.my.salesforce-sites.com/jobboard/JobRegisterForm?JobId=a1h6M000007l2cnQAA'
    }
]