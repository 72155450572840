import React from "react"
import { Row, Table } from "antd"
import { PageContentSubmitCvScreen } from "../components/PageContent/PageContent"
import { cookiesPolicyPageData } from "../../services/CookiePolicyData"
import { Banner } from "../components/Security/banner"
import CustomeBreadcrumb from "../components/common/breadcrumb"

const CookiesPolicy = (props: any) => {
  return (
    <PageContentSubmitCvScreen class="bg-white pt-5">
      <Banner  title={'Cookie Policy'}/>
      <CustomeBreadcrumb
        home_path={"/"}
        home={"Home"}
        current={"Cookie Policy"}
      />
      <Row
        className="fw-500 font-normal  px-sm-7 px-4 py-2"
      >
        <p>We, Adecco India Private Limited having our registered office at Summit B, Brigade Metropolis, B-9, 13th Floor, ITPL Main Road Garudachar Palya, Mahadevpura, Bengaluru Bangalore KA 560048 India (“we” or “us”) is committed to providing you with the best online experience while visiting this website (the “Website”) by using cookies.</p>
        <p>We will explain to you what cookies are, how we use them and how you can exercise your choices about our use of cookies.
          <br/>
In case we collect further personal information about you, such collection is covered by our Privacy Policy.</p>
<p>There is an index below so you can go straight to the relevant section if you prefer.</p>
</Row>
<Row
      className="fw-500 font-normal  px-sm-7 px-4 py-2">
<h3 className="fw-600">INDEX</h3>
<ol>
  <li className="fw-600"> What is a cookie and why do we use it?</li>
  <li className="fw-600">What types of cookies do we use and how long are they stored?</li>
<li className="fw-600">How do we use the cookies on our Website?</li>
<li className="fw-600">How can you manage cookies?</li>
<li className="fw-600">Third-party cookies</li>
<li className="fw-600">Changes to this Policy</li>
<li className="fw-600">Contact</li>
  </ol>
    <div >
      <h3 className="fw-600 pl-3">1. What is a cookie and why do we use it?</h3>
      <p>A cookie is a small piece of data (text file) that a website stores on your device (i.e., your computer, tablet or cell phone) in order to remember information about you and facilitate your use of the Website, improve the performance and your user experience and/or increase the relevance of our offerings on the Website and on third party websites.</p>
      <p>For example, cookies set by ourselves or by our partners (so-called third-party cookies) allow us to track and offer you a better user experience by remembering your saved vacancies or your language preferences when you return to the Website.</p>
    </div>
    <div>
      <h3 className="fw-600 pl-3">2. What types of cookies do we use and how long are they stored?</h3>
      <p>We have explained the different types of cookies that we use below.</p>
    
      
      <div
        className=""
        style={{ width: "100%", overflow: "auto" }}
      >
        <Table
          dataSource={cookiesPolicyPageData.dataSource}
          columns={cookiesPolicyPageData.columns}
          pagination={false}
          bordered
        />
      </div>
      <p>For more information on the specific cookies that we use, please see the Cookies List for an overview of the cookies used on the Website, sorted per cookie type and confirming the lifespan of each cookie.</p>
      </div>
      <div>
      <h3 className="fw-600 pl-3">3. How do we use the cookies on our Website?</h3>
<p>By default, only Strictly Necessary cookies are active. Strictly Necessary cookies allow the Website to be accessible, and without them some parts of the Website will not work. They are essential for the basic functionalities of the Website to operate.</p>
<p>Performance, Functional or Targeting cookies are disabled by default, but you have the chance to activate them the first time you access the Website in the Cookie banner (by clicking the <i>“Accept all”</i> button or via your customized settings) or at any other time via our Cookie Preference Center (see below the section <i>“How can you manage cookies?”</i>).</p>
<p>We use industry standard cookies, as appropriate, to track your behaviour online and gain statistical information at an aggregated level to monitor the operation of the Website, to improve the Website based on performance and to assess the effectiveness of campaigns.</p>
<p>For example, we track the number of visits to the Website, where each visitor came from (i.e. the website you visited prior to coming to the Website) and where each visitor goes to from the Website (i.e. the website you visit after leaving ours).</p>
      </div>
      <div>
      <h3 className="fw-600 pl-3">4. How can you manage cookies?</h3>
      <p>Unless you have adjusted your browser settings so that it will block cookies, our systems will issue Strictly Necessary Cookies each time you access the Website.</p>
      <p>In the Cookie Preference Center, which you can access via this link, you can manage your preferences to allow or refuse the storing of cookies per type (as described at section 2 above). You can withdraw your consent at any time through that same Cookie Preference Center.</p>
      <p>Do note that if you disable certain types of cookies, you may be unable to access certain parts of the Website or use certain functionality.</p>
      </div>
      <div>
      <h3 className="fw-600 pl-3">5. Third-party cookies</h3>
      <p>Whilst you use the Website, you may encounter links to third-party websites and/or content from third parties.</p>
      <p>The Website may also offer you the opportunity to further engage with us and share information with others using social networks such as Facebook, LinkedIn and Twitter.</p>
      <p>The third party content is added to our Website so we can keep you up to date with carefully selected information that you may find relevant or of interest, however this can result in these third-party providers storing additional cookies on your device that we have no control over.</p>
      <p>Consequently, we suggest you check the websites of any such third parties and their respective privacy and cookie policies for more information about their cookies and how you can manage them.</p>
      <p>Please note however that if you choose to access third party sites through this Website, the cookies we have listed in the previous section may not form an exhaustive list.</p>
      </div>
      <div>
      <h3 className="fw-600 pl-3">6. Changes to this Policy</h3>
      <p>The terms of this Policy may change from time to time. We shall publish any material changes to this Policy through appropriate notices either on our Website or contacting you using other communication channels.</p>
      </div>
      <div>
      <h3 className="fw-600 pl-3">7. Contact</h3>
      <p>Questions, comments and requests regarding this Policy are welcomed and should be addressed via<a href="mailto:IN.PrivacyLead-India@adecco.com"> IN.PrivacyLead-India@adecco.com</a>. </p>
      </div>
      </Row>
      
    </PageContentSubmitCvScreen>
  )
}

export default CookiesPolicy
