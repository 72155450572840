import React, { Fragment } from "react";
import { Row } from "antd";
import { PageContentSubmitCvScreen } from "../components/PageContent/PageContent";
import { Banner } from "../components/PrivacyPolicy/banner";
import Collapsible from "../components/Collapsible/Collapsible";
import CustomeBreadcrumb from "../components/common/breadcrumb";
import { privacyPolicyData } from "../../services/PrivacyPolicyData";
import "../styles/app.less";

export default function privacyPolicy(props) {
  return (
    <PageContentSubmitCvScreen class="bg-white" location={props.location}>
      <Banner />
      <CustomeBreadcrumb
        home_path={"/"}
        home={"Home"}
        current={"Privacy Policy"}
      />
      <div className="px-sm-7 px-2 mt-3">
        <Row
          type="flex"
          justify="space-around"
          className="text-align-center font-large lh-lg-x-large lh-medium px-lg-0 px-sm-6 px-2"
          style={{ opacity: "0.9" }}
        >
          {privacyPolicyData.description.map(data =>
            data.split("\n").map(item => {
              return (
                <Fragment key={item}>
                  {item}
                  <br />
                </Fragment>
              )
            })
          )}
        </Row>
        <Collapsible faqData={privacyPolicyData.privacyFaqs} />
      </div>
    </PageContentSubmitCvScreen>
  )
}
