import React from "react"
import { List } from "antd"
import PageContent from "../components/PageContent/PageContent"
import { reportsData } from "../../services/ReportsData"
import "../styles/app.less"

export default function faqs(props) {
  return (
    <PageContent location={props.location} class="bg-white">
      <div className="pt-9 pt-lg-0 pt-xl-0 pt-md-5 px-3">
        <h3 className="text-align-center heading-3 pt-5">
          {reportsData.title}
        </h3>

        <List
          itemLayout="horizontal"
          dataSource={reportsData.documents}
          renderItem={item =>
            item?.isChiled ? (
              <List.Item
                style={{ display: "block", borderBottom: "1px solid #e8e8e8" }}
              >
                <List.Item.Meta
                  className="align-items-center"
                  avatar={
                    <img loading="lazy" 
                      src={reportsData.icons[0].path}
                      style={{ width: "30px" }}
                      alt={item.title}
                    />
                  }
                  title={
                    <>
                      <p>{item.title}</p>
                    </>
                  }
                />
                <div className="pl-7">
                  {item.data.map((data, index) => (
                    <List.Item.Meta
                      className="align-items-center"
                      style={
                        index === item.data.length - 1
                          ? { paddingTop: "10px" }
                          : {
                              borderBottom: "1px solid #e8e8e8",
                              paddingBottom: "10px",
                            }
                      }
                      avatar={
                        <img loading="lazy" 
                          src={reportsData.icons[0].path}
                          style={{ width: "30px" }}
                          alt="avtar"
                        />
                      }
                      title={
                        <>
                          <a href={data.file} target={"_blank"}>
                            {data.title}
                          </a>
                        </>
                      }
                    />
                  ))}
                </div>
              </List.Item>
            ) : (
              <List.Item>
                <List.Item.Meta
                  className="align-items-center"
                  avatar={
                    <img loading="lazy" 
                      src={reportsData.icons[0].path}
                      style={{ width: "30px" }}
                      alt="avtar"
                    />
                  }
                  title={
                    <>
                      <a href={item.file} target={"_blank"}>
                        {item.title}
                      </a>
                    </>
                  }
                />
              </List.Item>
            )
          }
        />
      </div>
    </PageContent>
  )
}
