import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "antd";
import AdviceSlider from "react-slick";
import { AdviceData } from "../../../services/jobSekeerHomePageData";
import Arrow from "../../images/sliderArrow.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function NextArrow(props) {
  const { onClick } = props
  return (
    <div className="nextArrow" onClick={onClick}>
      <img loading="lazy"  src={Arrow} alt={'nextArrow'}/>
    </div>
  )
}

function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className="prevArrow" onClick={onClick}>
      <img loading="lazy"  src={Arrow} alt="prevArrow" />
    </div>
  )
}

const adviceData = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const Advice = () => {
  return (
    <section className="adviceSection px-md-6">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="heading">
            <h3>Career Advice</h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="AdviceSlider">
          <AdviceSlider {...adviceData}>
            {AdviceData.map(data => (
              <Link to={data.link} key={data.title}>
                <div className="data">
                  <img loading="lazy"  src={data.image}  alt={data.title}/>
                  <div className="text">
                    <h6>{data.title}</h6>
                    <p>{data.desc}</p>
                  </div>
                </div>
              </Link>
            ))}
          </AdviceSlider>
        </Col>
      </Row>
    </section>
  )
}

export default Advice
