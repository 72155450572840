import React from "react";
import  {
  PageContentEmployerScreen,
} from "../../components/PageContent/PageContent";
import { Banner } from "../../components/OurValues/banner";
import { OurValuesCard } from "../../components/OurValues/our-values-card";
import { aboutUs } from "../../../services/EmployerHeaderData";
import { BredcrumbEmployer } from "../../components/common/bredcrumbEmployer";

function ourValue(props) {
  return (
    <>
      <PageContentEmployerScreen location={props.location}>
        <Banner />
        <BredcrumbEmployer
          home_path={'/employer'}
          home={'Home'}
          perent={'About Us'}
          data={aboutUs}
          current={'Our Values'}
        />
        <OurValuesCard />
      </PageContentEmployerScreen>
    </>
  )
}

export default ourValue
