import React, { useState } from "react";
import { Card, Col, Modal, Row } from "antd";
import "./common.less";

const videosCardSolution = (props: any) => {
  let styles: any = {
    height: "100%",
  }

  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState([])
  const [id, setId] = useState(null)

  const onclose = () => {
    setVisible(false)
    setId(null)
    setValue([])
  }
  const onOpen = data => {
    setId(data)
    setTimeout(() => {
      setVisible(true)
    }, 1000)
  }

  return (
    <>
      <Col key={props.key}>
        <Card
          className="card-hover pressReleaseBox cardPadding videoCards"
          key={props.data.title}
          hoverable
          cover={
            <div className="blackBG">
              <a onClick={() => onOpen(props.data)}>
                <img loading="lazy"  style={{height:'150px',objectFit:'unset'}} alt="example" src={props.data.img} />
              </a>
            </div>
          }
        >
          <Row style={styles}>
            <Row type="flex" className="text-align-center">
              <p className="font-medium fw-600  press-title">
                {props.data.title}
              </p>
            </Row>
          </Row>
        </Card>
        {/* </Col> */}
      </Col>
      {visible && (
        <Modal
          title="Video"
          visible={visible}
          footer={null}
          maskClosable
          width={700}
          onCancel={() => onclose()}
        >
          <div
            className="my-2"
            dangerouslySetInnerHTML={{ __html: id?.iframe }}
          />
        </Modal>
      )}
    </>
  )
}

export default videosCardSolution
