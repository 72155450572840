import React from "react";
import { Link } from "gatsby";
import { Col, Icon, Row } from "antd";
import { solutionsData } from "../../../services/EmployerHomePageDara";
import { solutions } from "../../../services/EmployerHeaderData";

const Solution = () => {
  return (
    <section className="solutionSection px-3 px-md-6">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="heading">
            <h2>{solutionsData?.title}</h2>
          </div>
        </Col>
      </Row>
      <Row>
        {solutions.map((data, i) => {
          if (!data?.sub_menu) {
            return (
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                className={`d-flex justify-content-start mb-3 `}
                key={data.key}
              >
                <div className="solutionData">
                  <h5>
                    {data.key === "hr_advisory_consulting_services" ? (
                      <a onClick={() => (window.location.pathname = data?.url)}>
                        {data.data !== "Payroll Outsourcing" ? (
                          data.data
                        ) : (
                          <>Payroll Outsourcing</>
                        )}
                        <Icon type="double-right" className="ml-4" />
                      </a>
                    ) : (
                      <Link to={data.url}>
                        {data.data !== "Payroll Outsourcing" ? (
                          data.data
                        ) : (
                          <>Payroll Outsourcing</>
                        )}
                        <Icon type="double-right" className="ml-4" />
                      </Link>
                    )}
                  </h5>
                </div>
              </Col>
            )
          } else {
            return (
              <Col
                xs={24}
                sm={12}
                md={12}
                lg={8}
                xl={8}
                className={`d-flex justify-content-start mb-3  `}
                key={data.key}
              >
                <div className="solutionData">
                  <h5>{data.data}</h5>
                  <ul>
                    {data.sub_menu?.map(subData => (
                      <li key={subData.data}>
                        <Link to={subData.url}>
                          {subData.data}
                          <Icon type="double-right" className="" />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            )
          }
        })}
      </Row>
    </section>
  )
}

export default Solution
