import React from "react"
import { List } from "antd"
import PageContent from "../components/PageContent/PageContent"
import { complianceData } from "../../services/ComplianceData"
import "../styles/app.less"

export default function Compliance(props) {
  return (
    <PageContent location={props.location} class="bg-white">
      <div>
        <h3 className="text-align-center heading-3 pt-5">
          {complianceData.title}
        </h3>
        <List
          itemLayout="horizontal"
          dataSource={complianceData.folder}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                className="align-items-center"
                avatar={
                  <img loading="lazy" 
                    src={complianceData.icons[1].path}
                    style={{ height: "50px" }}
                    alt="complianceData-imag"
                  />
                }
                title={<a target={"_blank"} href={item.path}>{item.title}</a>}
              />
            </List.Item>
          )}
        />
      </div>
    </PageContent>
  )
}
