import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "antd";
import searchIcon from "../../images/icons/search.webp";
import handIcon from "../../images/icons/client.webp";
import longRight from "../../images/icons/long-right.webp";
import Call from "../../images/icons/Call-our-team.webp";
import "./common.less";


const JobSekeerAndEmployer = () => {
  return (
    <section className="jobSekeerAndEmployerSection px-3 px-md-6">
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <div className="jobSeekers">
            <img loading="lazy"  className="mainImg" src={searchIcon} alt="SerrchIcon" />
            <h5>Job Seekers</h5>
            <p>Want a new job? We've got 1000s. And it's easy to apply.</p>
            <Link to="/job-seeker">
              Find yours
              <img loading="lazy"  src={longRight} alt={'Find yours'}/>
            </Link>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <div className="employers">
            <img loading="lazy"  className="mainImg" src={Call} alt="SerrchIcon" />
            <h5>Call our team</h5>
            <p>Contact your local branch and speak to a job expert</p>
            <Link to="/employer/locations">
              Find a branch
              <img loading="lazy"  src={longRight} alt={'Find a branch'}/>
            </Link>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <div className="employers">
            <img loading="lazy"  className="mainImg" src={handIcon} alt="SerrchIcon" />
            <h5>Employers</h5>
            <p>Get the very best employees, when and where you need them.</p>
            <Link to="/employer/business-enquiry">
              Find out how
              <img loading="lazy"  src={longRight} alt={'Find out how'}/>
            </Link>
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default JobSekeerAndEmployer
