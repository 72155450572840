import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "antd";
import StoriesSlider from "react-slick"
import { StoriesData } from "../../../services/EmployerHomePageDara";
import Arrow from "../../images/sliderArrow.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function NextArrow(props) {
  const { onClick } = props
  return (
    <div className="nextArrow" onClick={onClick}>
      <img loading="lazy"  src={Arrow} alt={'nextArrow'}/>
    </div>
  )
}

function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className="prevArrow" onClick={onClick}>
      <img loading="lazy"  src={Arrow} alt='prevArrow'/>
    </div>
  )
}

const storiesData = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const Stories = ({ heading, isReadMore }) => {
  return (
    <section className="storiesSection px-md-6">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="heading">{heading && <h3>{heading}</h3>}</div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="StoriesSlider">
          <StoriesSlider {...storiesData}>
            {StoriesData.data.map((data, index) => (
              <div className="data" key={index}>
                <img loading="lazy"  src={data.image} alt={data.title} />
                <div className="text">
                  <h6>{data.title}</h6>
                </div>
                <div className="boxFooter">
                  <ul>
                    <li>
                      <Link to={"/employer/case-study/" + data.link}>
                        Read the case study{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </StoriesSlider>
          {isReadMore && (
            <div className="text-center mt-3">
                <Link className="ant-btn ant-btn-danger ant-btn-lg btn"  to={"/employer/case-study/"}>
                  Read More
                </Link>
            </div>
          )}
        </Col>
      </Row>
    </section>
  )
}

export default Stories
