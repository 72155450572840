import { Col, Row } from "antd"
import React from "react"
import "./style.less"

const ImageTextComaponent = () => {
  return (
    <div className="half-half-image-text">
      <div className="px-6">
        <Row className="py-2 d-flex m-2">
          <Col lg={12} xl={12} sm={24} md={24} className={"m-2"}>
            <div className="side-text">
              <p className="p-0 m-0">
                At Fluid Automotive, our purpose is to make automotive parts
                easily accessible for everyone. Working with our partner brands,
                we aim to retail the highest quality parts, whilst maintaining a
                high level of customer satisfaction.
              </p>
            </div>
          </Col>
          <Col lg={12} xl={12} sm={24} md={24} className={"m-2"}>
            <div className="img">
              <iframe
                width="100%"
                height="315"
                style={{ borderRadius: "10px" }}
                src="https://www.youtube.com/embed/mI-XBZK0ifI"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Col>
        </Row>
        <Row className="py-2 d-flex m-2">
          <Col lg={12} xl={12} sm={24} md={24} className={"m-2"}>
            <div className="img">
              <iframe
                width="100%"
                height="315"
                style={{ borderRadius: "10px" }}
                src="https://www.youtube.com/embed/iqKkqlBPtHU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Col>
          <Col lg={12} xl={12} sm={24} md={24} className={"m-2"}>
            <div className="side-text">
              <p className="p-0 m-0">
                At Fluid Automotive, our purpose is to make automotive parts
                easily accessible for everyone. Working with our partner brands,
                we aim to retail the highest quality parts, whilst maintaining a
                high level of customer satisfaction.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="py-2 d-flex ">
          <Col lg={12} xl={12} sm={24} md={24} className={"m-2"}>
            <div className="side-text">
              <p className="p-0 m-0">
                At Fluid Automotive, our purpose is to make automotive parts
                easily accessible for everyone. Working with our partner brands,
                we aim to retail the highest quality parts, whilst maintaining a
                high level of customer satisfaction.
              </p>
            </div>
          </Col>
          <Col lg={12} xl={12} sm={24} md={24} className={"m-2"}>
            <div className="img">
              <iframe
                width="100%"
                height="315"
                style={{ borderRadius: "10px" }}
                src="https://www.youtube.com/embed/rDx3IsctPTk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ImageTextComaponent
