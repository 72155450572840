import React, { Fragment } from "react";
import { Row, Col, Breadcrumb, Card, Divider } from "antd";
import { Link } from "gatsby";
import { PressReleaseData } from "../../../../services/PressReleaseData";
import  {
  PageContentEmployerScreen,
} from "../../../components/PageContent/PageContent";
import banner_img1 from "../../../images/PressRelease/banner/UNIQUEOPPORTUNITIESFORCAREER.webp";
import backIcon from "../../../images/icons/icons8-back-24.webp";
import fbIcon from "../../../images/icons/facebook.webp";
import ldIcon from "../../../images/icons/linkedin.webp";
import ttIcon from "../../../images/icons/twitter.webp";
import "./common.less";

export default function PressRelease(props) {
  const data={
    press_id:"PRESS008",
    id:
      "the-adecco-group’s-initiatives-for-youth-unique-opportunities-for-career-advancement",
    banner_img: banner_img1,
    category: "Corporate Social Responsibility",
    date: "Mumbai, February 18, 2020",
    title:
      "The Adecco Group's Initiatives for Youth: Unique Opportunities for Career Advancement",
    description:
      "Adecco India aims at improving youth employment rates and helping employers integrate the talent into their workforce — particularly the millennials who now constitute 47 per cent of our workforce in Adecco India",
    content: `
    <h1>THE ADECCO GROUP’S INITIATIVES FOR YOUTH: UNIQUE OPPORTUNITIES FOR CAREER ADVANCEMENT</h1>
<p>Adecco endeavors to build an inclusive future of work with opportunities for the youth</p>
<p><b>Mumbai, February 18, 2020:</b> The world’s leading workforce solutions company, The Adecco Group, has brought forth two very interesting initiatives – <b>‘CEO for One Month’ and ‘Win4Youth’</b>, to support young individuals in achieving their highest of potential.</p>
<p>Currently in its seventh year - <b>‘CEO for One Month’</b>, the company’s flagship work-readiness programme for youth, offers a one of a kind opportunity to selected candidates to gain a practical know-how of running a multinational company. The programme is open to applications since November 27th, 2019. The programme has received an overwhelming response so far with over 21,000 applications from across India alone.</p>
<p>The programme is currently running in 49 countries across The Adecco Group’s global network. It is designed to give young leaders the hands-on work experience they need to build their careers – offering ‘real-world’ training that cannot be gained in school or university. The application duration has been extended to almost 4 months as compared to 2 months during the last year, due to the overwhelming response. The application deadline is 19th March, 2020.</p>
<p>The programme comprises of a series of assessments including interviews and boot camps completing which the successful applicants will undertake a national ‘CEO for One Month’ assignment. The top 10 Country ‘CEOs for One Month’ will be invited to attend a rigorous boot camp to test their collaboration and innovation skills, learning about themselves and the world of work. Only one will then be selected to work alongside CEO, Alain Dehaze, for one month and shadow him in running a multinational company with 34,000 employees in 60 countries. It is the experience of a lifetime and an ideal launching pad for personal and professional development. Alongside this, Adecco runs it’s another annual programme - Win4Youth, which encourages its employees, associates and allied clients to participate in athletic events by converting every kilometer and every hour of sports into donation. These funds support our one global charity partner, Plan International, with their vital work to improve young people&#39;s lives, education and employment prospects around the world.</p>
<p>This programme aims at improving youth employment rates and helping employers integrate this talent into their workforce — particularly the millennials who now constitute 47 per cent of our workforce in Adecco India. We are now witnessing how socio-economic, demographic and technological trends are rapidly transforming the workplace impacting both employers and workers.</p>
<p>Commenting on the programme, <b>Marco Valsechhi, Country Manager and MD, The Adecco Group India</b> said: “Our ‘CEO for One Month initiative’ is a targeted programme that identifies the most promising young talent and prepares them to assume the role of global leaders. The programme is designed to provide the most practical knowledge of the innermost workings of a company. The success of the program is in the mutual growth it offers to us and the candidates and we are looking forward to welcoming this year’s candidates.<br /> I particularly look forward to the opportunity to understand new generational attributes and learn a lot from this savvy and digital native generation and use them to rewire our management strategy. Whereas, our other programme Win4youth is concerned, it is a great initiative as it does not only help with the economic upliftment of the youth of society but also aid in personal fitness and engagement with colleagues.” To participate, candidates should <b>apply via the</b> Adecco Group ‘CEO for One Month’ website. Shortlisted applicants will be invited to an interview and further assessment in their own country. The position includes a salary intended to support the successful candidate’s education and/or professional development. Selected ‘CEOs for One Month’ will receive ongoing guidance and professional opportunities, becoming part of a global network. </p>
<p><b>‘CEO for One Month’ Programme</b><br />The ‘CEO for One Month’ programme began as a local initiative in Norway in 2011 and was formalised in to a global programme in 2014, since growing in to a flagship initiative for the Adecco Group under the Adecco Group Foundation. It helps young people increase their employability and career prospects through highly effective work-based learning. The programme operates under the Adecco Group Foundation which aims to improve work readiness of young people and other underserved populations who struggle to enter the workforce.<br />Website: <a href="www.ceofor1month.com">www.ceofor1month.com</a><br />Facebook: <a href="https://www.facebook.com/ceofor1month">https://www.facebook.com/ceofor1month</a><br />Twitter: <a href="https://twitter.com/CEOfor1Month">https://twitter.com/CEOfor1Month</a><br />Instagram: <a href="https://www.instagram.com/ceofor1month/">https://www.instagram.com/ceofor1month/</a></p>
<p><b>‘Win4youth’ Programme</b><br />Every year, the Win4Youth program brings Adecco Group employees, associates and clients together to share their passion for sport and to have a positive impact on the lives of young people around the world. The Adecco Group aspires to empower people, fuel economies, and enrich societies by providing opportunities for meaningful lifetime employment. Through the Win4Youth program, Adecco unites its employees, associates and clients as they clock up kilometres and minutes for our global charity partner, Plan International, to improve the lives, education and employment prospects of young people around the world.<br />Website: <a href="https://www.win4youth.com/what-is-win4youth">https://www.win4youth.com/what-is-win4youth</a><br />Facebook: <a href="https://www.facebook.com/win4youth/">https://www.facebook.com/win4youth/</a><br />Twitter: <a href="https://twitter.com/win4youth?lang=en">https://twitter.com/win4youth?lang=en</a><br />Instagram: <a href="https://www.instagram.com/win4youth/?hl=en">https://www.instagram.com/win4youth/?hl=en</a></p>
<p><b>About the Adecco Group</b><br />The Adecco Group is the world’s leading HR solutions partner. We provide more than 700,000 people with permanent and flexible employment every day. With more than 34,000 employees in 60 countries, we make the Future work for everyone. Our colleagues serve more than 100,000 organisations with the talent, HR services and cutting-edge technology they need to succeed in an ever-changing global economy. As a Fortune Global 500 company, we lead by example, creating shared value that meets social needs while driving business innovation. Our culture of inclusivity, fairness and teamwork empowers individuals and organisations, fuels economies, and builds better societies. These values resonate with our employees, who voted us number 5 on the Great Place to Work®—World’s Best Workplaces 2018 list. We make the future work for everyone. The Adecco Group is based in Zurich, Switzerland. Adecco Group AG is registered in Switzerland (ISIN: CH0012138605) and listed on the SIX Swiss Exchange (ADEN). The Adecco Group is based in Zurich, Switzerland. Adecco Group AG is registered in Switzerland (ISIN: CH0012138605) and listed on the SIX Swiss Exchange (ADEN). The group is powered by nine global brands: Adecco, Adia, Modis, Badenoch &amp; Clark, General Assembly, Lee Hecht Harrison, Pontoon, Spring Professional and YOSS.</p>
<h4><b>For further information please contact:</b></h4>
  <p>Cauvery Uthappa | <a href="mailto:cauvery.uthappa@adecco.com" class="taxt-dark">cauvery.uthappa@adecco.com</a> | <a href="tel:+919845172958" class="taxt-dark">+919845172958</a></p>
    `,
    link:
      "/employer/press-release/press-release-8",
  }

  

  const listData: any = []
  for (let i = 0; i < 6; i++) {
    listData.push({
      href: "https://ant.design",
      title: `ant design part ${i}`,
      avatar: "https://joeschmoe.io/api/v1/random",
      description:
        "Ant Design, a design language for background applications, is refined by Ant UED Team.",
      content:
        "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
    })
  }
  return (
    <>
      {data?.banner_img && (
        <section className="PressSection">
          <Row>
            <div className="hero-section" style={{backgroundImage: `url('${data.banner_img}')`}}>
              <div className="overlay"></div>
              <div className="content-section"></div>
            </div>
          </Row>
        </section>
      )}

      <PageContentEmployerScreen
        location={props.location}
      >
        <Row className="custome-breadcrumb mt-5">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/employer/press-release"}>
                  <img loading="lazy"  src={backIcon} /> Back to Press Release
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="float-right">
            <div className="d-flex">
              <span>Share|</span>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={fbIcon} alt="Facebook" />
              </a>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={ldIcon} alt="linkedin" />
              </a>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={ttIcon} alt="twitter" />
              </a>
            </div>
          </Col>
        </Row>
        <Row type="flex" className="mobile-flex">
          <Col lg={9} md={24} className="px-3 related-press-release-card">
            <Card title="Related Press Release" size="small" className="">
              {PressReleaseData.map(
                (data, i) =>
                  i > 4 && (
                    <Fragment key={data.id}>
                      <div className="d-flex">
                        <img loading="lazy" 
                          src={data.releted_img}
                          alt="img"
                          className="rounded"
                          width={100}
                        />
                        <Link
                          to={data.link}
                          className="ml-2 fw-600 press-title"
                        >
                          {data.title}
                        </Link>
                      </div>
                      <Divider />
                    </Fragment>
                  )
              )}
            </Card>
          </Col>
          <Col lg={14} md={24} className="press-release-section">
            {data && (
              <Row
                className="fw-500 font-normal"
                dangerouslySetInnerHTML={{ __html: data?.content }}
              ></Row>
            )}
          </Col>
        </Row>
      </PageContentEmployerScreen>
    </>
  )
}
