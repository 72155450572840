import React from "react";
import { Link } from "gatsby";
import { Breadcrumb, Col, Row } from "antd";
import RenderMenu from "../../../components/Menu/RenderMenu";
import { solutions } from "../../../../services/EmployerHeaderData";

const BreadcrumbSec = ({ current }) => {
  return (
    <Row className="custome-breadcrumb pt-3 pl-3 mb-0">
      <Col>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={"/employer"}>Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item overlay={<RenderMenu data={solutions} />}>
            Solutions
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="">{current}</a>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
    </Row>
  )
}
export default BreadcrumbSec
