import React from "react";
import {Col, Row } from "antd";
import newsroomImg from "../../images/ADA/what_is_ada.webp";

const WhatIsDefense = () => {
  return (
    <Row className="ada-what-is">
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <img className="ragisterImg" loading="lazy"  src={newsroomImg} alt={'newsroomImg'} />
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={14}
          className="float-md-right pl-3 pr-3 pl-md-0 pr-md-0 pr-lg-6"
        >
          <div className="data">
            <h3>What is Adecco Defense Alliance</h3>
            <p className="text-blod">
              Our Defense Alliance Program works firsthand with veteran talent
              and leading organizations to co-create better career outcomes and
              sustainable workforces alike.
            </p>
            <p>
              Although the resettlement rate of ex-servicemen and providing
              career opportunities for their spouses have improved, the issue of
              underemployment has become a strong barrier to a meaningful
              post-service career. The current labor market is not only tight
              but continuously evolving as technology shifts and the
              post-pandemic style of work takes shape.
            </p>
            <p>
              This is forcing employers to be more progressive and think outside
              of the box in their pursuit of talent. Military Veterans and
              spouses could be a seamless addition to any organization in any
              market. The traits that we seek in high performers, such as
              Leadership, accountability, passion, and resolve - traits that are
              not inherently teachable - are often found in our defense
              community.
            </p>
          </div>
        </Col>
      </Col>
    </Row>
  )
}

export default WhatIsDefense
