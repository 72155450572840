import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Row, Col, Breadcrumb, Skeleton, Button } from "antd"
import backIcon from "../../../images/icons/icons8-back-24.webp"
import PageContent, {
  PageContentEmployerScreen,
} from "../../../components/PageContent/PageContent"
import calendar from "../../../images/calendar.webp"
import wallet from "../../../images/permanent-recruitment-desc.svg"
import experience from "../../../images/experience.svg"
import { staticJobs } from "../../../../services/jobSeekerData"
import location from "../../../images/location.svg"
import { request } from "../../../helper/dimensionHelper"
import { WrappedSubmitYourCvF } from "../../../components/Forms/submit-your-cv-from"
import Baner from "../../../images/jobSeekerHome/Jobs-Banner.webp"

export default function JobSeeker(props) {
  const { ID } = props.params
  const [jobData, setJobData] = useState([])
  const [info, setInfo] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getJobDetails(ID)
    // const filterData = staticJobs.filter(
    //   data => data.jobCode.toString() === ID.toString()
    // )
    // setJobData(filterData[0])
  }, [])

  const getJobDetails = async ID => {
    setLoading(true)
    
    await request("GET", `JobSite/JobContent/${ID}`)
      .then(res => {
        
        console.log("1111111111",res.data)
        
        setJobData(res.data)
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }
  const [file, setFile] = useState("")

  const fileUpload = event => {
    setFile("")
    let fileName = event.target.files[0]
    setFile(fileName.name)
  }
  console.log('1111111111',{jobData})
  return (
    <>
      {/* <PageContentEmployerScreen location={props.location}> */}
      <Row>
        <div className="hero-section" style={{ background: `url(${Baner})` }}>
          <div className="overlay"></div>
          <div className="content-section">
            <h1>Job Seeker</h1>
          </div>
        </div>
      </Row>

      <PageContent class="text-align-justify">
        <Row className="custome-breadcrumb mt-4 pt-4">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/job-seeker"}>
                  <img loading="lazy" src={backIcon} /> Back to Job Seeker
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {loading ? (
          <>
            <Row className="m-3 m-sm-0 ">
              <Skeleton
                paragraph={{
                  rows: 12,
                }}
              />
            </Row>
            <Row className="m-3 m-sm-0">
              <Skeleton
                paragraph={{
                  rows: 15,
                }}
              />
            </Row>
          </>
        ) : (
          <Row className="m-3 m-sm-0 px-6 pb-5">
            <Col xs={24} xl={jobData?.jobData?.jobOriginRefId && jobData?.jobData?.jobOriginRefId?.length > 0 ?18:14} lg={jobData?.jobData?.jobOriginRefId && jobData?.jobData?.jobOriginRefId?.length > 0 ?18:14} md={jobData?.jobData?.jobOriginRefId && jobData?.jobData?.jobOriginRefId?.length > 0 ?14:12} sm={24} className="js_form">
              <Row>
                <Col xs={24} lg={24} md={24} sm={24}>
                  <h3
                    className="js_heading"
                    dangerouslySetInnerHTML={{
                      __html: jobData?.jobData && jobData?.jobData?.jobTitle,
                    }}
                  ></h3>
                </Col>
                <Col xs={24} lg={24} md={24} sm={24}>
                  <ul className="timeLocation">
                    <li>
                      <img loading="lazy" src={calendar} />{" "}
                      <span>
                        {jobData?.jobData && jobData?.jobData?.jobTypeDescription}
                      </span>
                    </li>
                    <li>
                      <img loading="lazy" src={location} />{" "}
                      <span>
                        {jobData?.jobData && jobData?.jobData?.locationDescription}
                      </span>
                    </li>
                    <li>
                      <img loading="lazy" src={wallet} />{" "}
                      <span>
                        {jobData?.jobData && jobData?.jobData?.industryDescription}
                      </span>
                    </li>
                    <li>
                      <img loading="lazy" src={experience} />{" "}
                      <span>
                        {jobData?.jobData && jobData?.jobData?.expFrom} -{" "}
                        {jobData?.jobData && jobData?.jobData?.expTo} Years
                      </span>
                    </li>
                  </ul>
                </Col>
                {/* <p style={{color:'red'}}>{jobData&&jobData[0].description}</p> */}
                {console.log("1111111",jobData,jobData?.jobData?.jobOriginRefId && jobData?.jobData?.jobOriginRefId?.length)}
                
                {jobData?.contentHeader && jobData?.contentHeader?.length > 0 && (
                  <Col xs={24} lg={24} md={24} sm={24} className="js_details">
                    {jobData?.contentHeader?.map(item=>
                    <React.Fragment key={item.headerCode}> 
                    <h4>
                      <strong>{item.title}</strong>{" "}
                    </h4>
                      {item?.headerType === "PONT"?
                        <ul>
                      {item?.contentDetail.map(data => (
                        <li
                        key={data.description}
                        dangerouslySetInnerHTML={{ __html: data.description }}
                        ></li>
                      ))}
                    </ul>
                      :item?.contentDetail?.map(data => (
                        <p
                          key={data.description}
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                      ))}
                    </React.Fragment>)}
                  </Col>
                )}
              </Row>
              <Row>
                {jobData?.jobData && jobData?.jobData?.jobOriginRefId?.length > 0 && (
                  <div className="text-center mt-5">
                    <Button
                      type="danger"
                      className="bg-primary fw-700"
                      size="large"
                    >
                      <Link to={jobData?.jobData?.jobOriginRefId} target="_blank">
                        QUICK APPLY
                      </Link>
                    </Button>
                    <div className="mt-3">
                      <p>
                        <strong>
                          And don’t forget to FOLLOW Adecco on LinkedIn for all
                          the best industry news, career opportunities and
                          networking opportunities!
                        </strong>
                      </p>
                    </div>
                  </div>
                )}
              </Row>
            </Col>
            {!jobData?.jobData?.jobOriginRefId && !jobData?.jobData?.jobOriginRefId > 0 && (
              <Col
                xs={24}
                xl={8}
                lg={8}
                md={11}
                sm={24}
                className="ml-md-auto float-md-right pos_sticy"
              >
                <div className="js_form">
                  <h6 className="js_application">Application</h6>

                  <WrappedSubmitYourCvF
                    btnName="Apply Now"
                    jobApply={{
                      type: "JOB_APPLY",
                      data: jobData?.jobData && jobData?.jobData?.jobTitle,
                      appliedJobCode: jobData?.jobData?.jobCode,
                    }}
                  />
                </div>
              </Col>
            )}
          </Row>
        )}
      </PageContent>
      {/*  </PageContentEmployerScreen> */}
    </>
  )
}
