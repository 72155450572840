import React from "react";
import { Col, Pagination, Row } from "antd";
import { Link } from "gatsby";
import { StoriesData } from "../../../../services/EmployerHomePageDara";

const CaseStudyList = () => {
  return (
    <section className="CaseStudyListSection px-3 px-md-6">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h2>Latest Case Studies</h2>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        {StoriesData.data.map(m => (
          <Col xs={24} sm={12} md={12} lg={8} xl={8}>
            <div className="box">
              <div className="images">
                <img loading="lazy"  src={m.image} alt="images"/>
              </div>
              <div className="details">
                <Link to={m.link}>
                  <h4>{m.title}</h4>
                </Link>
                <p>{m.description}</p>
              </div>
              <div className="pb-3">
                <Link className="red-more" to={m.link}>
                  Read More
                </Link>
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <Row className="text-center">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-4 mb-4">
          <Pagination className="text-center" defaultCurrent={1} total={20} />
        </Col>
      </Row>
    </section>
  )
}

export default CaseStudyList
