import React from "react"
import { Row } from "antd"
import { PageContentSubmitCvScreen } from "../components/PageContent/PageContent"
import { Banner } from "../components/FAQ/banner"
import CustomeBreadcrumb from "../components/common/breadcrumb"
import Collapsible from "../components/Collapsible/Collapsible"
import { faqData } from "../../services/FaqData"
import "../styles/app.less"

export default function faqs(props) {
  return (
    <PageContentSubmitCvScreen location={props.location} class="bg-white ">
      <Banner />
      <CustomeBreadcrumb home_path={"/"} home={"Home"} current={"FAQ"} />
      <div className="px-6 faqDataSection">
        <Row
          type="flex"
          justify="center"
          className="text-align-center pb-2 px-lg-0 px-5"
        >
          <Row
            className="font-large lh-md-x-large lh-medium mt-2"
            dangerouslySetInnerHTML={{ __html: faqData.description }}
            style={{ lineHeight: "2.5rem", opacity: "0.9" }}
          ></Row>
        </Row>
        <Row className="px-md-0 px-4 font-h1">
          <Collapsible faqData={faqData.faqs} />
        </Row>
      </div>
    </PageContentSubmitCvScreen>
  )
}
