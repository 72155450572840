import { Row } from "antd"
import React from "react"
import PressReleaseCard from "./PressReleaseCard"
import "./style.less"


export default function PressReleaseCardLayout(props: any) {
  return (
    <>
      <Row gutter={[16, 24]} className="custom-ant-row press-release-cards">
        {props?.data?.map(
          (data: any, index: any) =>
            parseInt(index) >= parseInt(props.minIndex) &&
            parseInt(index) < parseInt(props.maxIndex) && (
              <PressReleaseCard data={data} key={data.id} />
            )
        )}
      </Row>
    </>
  )
}
