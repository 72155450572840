/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import * as React from "react"

import "./static/fonts/fonts.css"

import WrapRootElement from "./src/wrap-root-element"

export const wrapRootElement = ({ element }) => (
  <WrapRootElement>{element}</WrapRootElement>
)

