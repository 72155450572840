import Accenture from "../src/images/trusted-best/Accenture.webp"
import Amazon from "../src/images/trusted-best/Amazon.webp"
import Apple from "../src/images/trusted-best/Apple.webp"
import AtosLogo from "../src/images/trusted-best/Atos-Logo.webp"
import AU from "../src/images/trusted-best/AU.webp"
import Bayer from "../src/images/trusted-best/Bayer.webp"
import DHL from "../src/images/trusted-best/DHL.webp"
import eKart from "../src/images/trusted-best/eKart.webp"
import Faurecia from "../src/images/trusted-best/Faurecia.webp"
import FoodPanda from "../src/images/trusted-best/Food-Panda.webp"
import GAWest from "../src/images/trusted-best/GA-West.webp"
import Haldiram from "../src/images/trusted-best/Haldiram.webp"
import Himalaya from "../src/images/trusted-best/Himalaya.webp"
import IBM from "../src/images/trusted-best/IBM.webp"
import Mphasis from "../src/images/trusted-best/Mphasis.webp"
import Nestle from "../src/images/trusted-best/Nestle.webp"
import Ola from "../src/images/trusted-best/Ola.webp"
import TCS from "../src/images/trusted-best/TCS.webp"
import Wipro from "../src/images/trusted-best/Wipro.webp"

export const trustedData = {
  images: [
    Amazon,
    Apple,
    Accenture,
    AtosLogo,
    AU,
    Bayer,
    DHL,
    Faurecia,
    FoodPanda,
    eKart,
    GAWest,
    Haldiram,
    Himalaya,
    IBM,
    Nestle,
    Mphasis,
    Ola,
    TCS,
    Wipro,
  ],
}
