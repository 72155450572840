
export const securityData = {
  security: {
    description:
      `<p class="text-bold">Multiple fraud attempts using the Adecco name and trademark have been reported in several countries. Please be aware that Adecco or Adecco India Private Limited representative will NEVER request payment of any kind from a candidate or an associate during a job search or a selection process.</p>
      <p>We have been informed that the ADECCO name and trademark are being used in several countries by persons who are in no way related to the Adecco Group. These persons place job adverts on general market websites using the name ‘Adecco’ without our permission. We also have reason to believe that e-mails, letters, telephone calls and other actions taken by these persons are nothing but illegitimate attempts to obtain money and access to job seekers’ and associates’ personal and confidential information.</p>
      `,
  },
  protectYourself: {
    description:
      `<p>Before releasing any personal information, ID or bank account details during a job search process, make sure your contact is an authorised Adecco representative. You can confirm this by getting in touch with an Adecco office in your country. You can find the list of the countries in which we operate on our website at<br/><a href='https://www.adeccogroup.com/worldwide-location' style='color:#73C2FB'>www.adeccogroup.com/worldwide-location</a> or <a href='https://www.adecco.com' style='color:#73C2FB'>www.adecco.com</a></p>
      <p>Beware of any unexpected e-mail, instant message, voicemail or fax that claims to be from Adecco. Criminals can attempt to send malware or obtain access to your information or accounts using emails, phone or text. If you receive such a message, get in touch with an Adecco office in your country and verify that the message is legitimate.</p>
      <p>For more information, please see the National Cyber Crime Reporting Portal <a href='https://cybercrime.gov.in/' style='color:#73C2FB'>https://cybercrime.gov.in/.</a> If you have received an email which you are not quite sure about, please forward it to us using the contact details in the section below or <a href='wecare@adecco.com' style='color:#73C2FB'>wecare@adecco.com.</a></p>
      <p>If you believe that you have been victim of such scams, we strongly recommend that you report the incident to local authorities as soon as possible.</p>
      `,
  },
  contactUs: {
    description:
      `<p>If you would like to report an abuse of the Adecco name and trademark, please contact the Adecco office in the country through  <a href='https://wecare@adecco.com' style='color:#73C2FB'>wecare@adecco.com</a>, where the incident occurred. If Adecco does not operate in that country, please send an email to: <a href='mailto:compliance@adecco.com' style='color:#73C2FB'>compliance@adecco.com </a> or visit <a href='www.aceconduct.com' style='color:#73C2FB'>www.aceconduct.com</a>.</p>
      <p>If you are looking for a job in your country, then please reach out to us. A list of the countries in which we operate can be found on our website at <a href='https://www.adeccogroup.com/worldwide-locations' style='color:#73C2FB'>www.adeccogroup.com/worldwide-locations<a/>.</p>
      `,
  },
}
