import React from "react"
import { Card, Col, Tabs, Row, Icon } from "antd"
import PageContent from "../../components/PageContent/PageContent"
import Image1 from "../../images/OurTeam/VidyaSagar.jpg"
import linkedin from "../../images/icons/linkedin.webp"
import aboutUsTeam from "../../../services/aboutUsData"
import tempImg from "../../images/OurTeam/Vidya-300x200.png"
import OurTeamNewComp from "./ourTeamNewComp"

const { Meta } = Card
const { TabPane } = Tabs

function NewTeamCard({ key, img, name, category, linkedin_link }) {
  return (
    <div className="leadership-content" key={key}>
      <div className="leadership-image-container">
        <div className="member-content">
          <span className="img image">
            <span>
              <img loading="lazy" src={img} alt="Images" />
            </span>
          </span>
          <div className="member-content-caption">
            <div className="member-detail-container">
              <h1 className="member-name">{name}</h1>
              <a href={linkedin_link} target="_blank" className="iconImg pl-3">
                <img loading="lazy" src={linkedin} alt="linkedin icon" />
              </a>
            </div>
            <span
              className="member-designation"
              dangerouslySetInnerHTML={{ __html: category }}
            ></span>
          </div>
        </div>
      </div>
    </div>
  )
}

const OurTeam = props => {
  function callback(key) {}

  return (
    <>
    {/* <section className="leadershipSection px-3 px-md-6">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h2>Our Leadership</h2>
        </Col>
      </Row>
      <Row>
        <Tabs defaultActiveKey="1" onChange={callback} className={"custom-tab"}>
          <TabPane tab="Country Manager" key="1" className="p-2">
            <div className="leadership-container">
              {aboutUsTeam
                ?.filter(
                  data => data.category === "Country Manager"
                )
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.profession}
                    linkedin_link={data.linkedin}
                  />
                ))}
            </div>
          </TabPane>
          <TabPane tab="Country Finance Officer" key="2" className="p-2">
            <div className="leadership-container">
              {aboutUsTeam
                ?.filter(
                  data => data.category === "Country Finance Officer"
                )
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.profession}
                    linkedin_link={data.linkedin}
                  />
                ))}
            </div>
          </TabPane>
          <TabPane tab={<span>Business Leaders </span>} key="3">
            <div className="leadership-container">
              {aboutUsTeam
                ?.filter(
                  data =>
                    data.category === "Business Leaders" &&
                    data.subCategory === "Client Services"
                )
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.profession}
                    linkedin_link={data.linkedin}
                  />
                ))}
            </div>
          </TabPane>

          <TabPane tab="Marketing and Communications" key="4">
            <div className="leadership-container">
              {aboutUsTeam
                ?.filter(
                  data => data.category === "Marketing and Communications"
                )
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.category}
                    linkedin_link={data.linkedin}
                  />
                ))}
            </div>
          </TabPane>
          <TabPane tab="Legal & Compliance" key="5">
            <div className="leadership-container">
              {aboutUsTeam
                ?.filter(data => data.category === "Legal & Compliance")
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.category}
                    linkedin_link={data.linkedin}
                  />
                ))}
            </div>
          </TabPane>
          //comment
          <TabPane tab="Finance" key="5">
            <div className="leadership-container">
              {aboutUsTeam
                ?.filter(data => data.category === "Finance")
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.category}
                    linkedin_link={data.linkedin}
                  />
                ))}
            </div>
          </TabPane>
          <TabPane tab="Human Resources" key="6">
            <div className="leadership-container">
              {aboutUsTeam
                ?.filter(data => data.category === "Human Resources")
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.category}
                    linkedin_link={data.linkedin}
                  />
                ))}
            </div>
          </TabPane>
          <TabPane tab="Information Technology" key="7">
            <div className="leadership-container">
              {aboutUsTeam
                ?.filter(data => data.category === "Information Technology")
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.category}
                    linkedin_link={data.linkedin}
                  />
                ))}
            </div>
          </TabPane>
          <TabPane tab="Business Transformation" key="8">
            <div className="leadership-container">
              {aboutUsTeam
                ?.filter(data => data.category === "Business Transformation")
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.category}
                    linkedin_link={data.linkedin}
                  />
                ))}
            </div>
          </TabPane>
          <TabPane tab="Shared Services Centre" key="9">
            <div className="leadership-container">
              {aboutUsTeam
                ?.filter(data => data.category === "Shared Services Centre")
                ?.map(data => (
                  <NewTeamCard
                    key={data.email}
                    name={data.name}
                    img={
                      data.img ??
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                    }
                    category={data.category}
                    linkedin_link={data.linkedin}
                  />
                ))}
            </div>
          </TabPane>
        </Tabs>
      </Row>
    </section> */}
      <OurTeamNewComp/>
    </>
  )
}

export default OurTeam
