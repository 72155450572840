import React, { Fragment } from "react";
import { Collapse } from "antd";
import "./Collapsible.less";
const { Panel } = Collapse;

interface CollapsibleData {
  question: string
  desc: string
}

interface ComponentProps {
  faqData: Array<CollapsibleData>
}

export default function Collapsible(props: ComponentProps) {
  return (
    <div>
      <Collapse expandIconPosition="right" accordion>
        {props.faqData.length &&
          props.faqData.map(collapsibleData => (
            <Panel
              header={collapsibleData.question}
              key={collapsibleData.question}
              className="collapse-style font-medium ls-1"
            >
              <div className="font-normal" style={{ opacity: "0.8" }}>
                {collapsibleData.desc.split("\n").map(item => {
                  return (
                    <Fragment key={item}>
                      <div
                        dangerouslySetInnerHTML={{ __html: item }}
                        className="p-3"
                      ></div>
                      <br />
                    </Fragment>
                  )
                })}
              </div>
            </Panel>
          ))}
      </Collapse>
    </div>
  )
}
