import { Card, Col, Row } from "antd"
import React from "react"
import { socialResponsibilty } from "../../../services/SocialResponsibiltyData"
const { Meta } = Card

const CrsCard = (responsibilityData: any) => {
  return responsibilityData?.map((data: any, index: any) => (
    <Col
      sm={24}
      md={12}
      lg={12}
      xl={12}
      key={index}
      style={{ padding: "35px" }}
    >
      <Card
        className="h-100"
        hoverable
        cover={<img loading="lazy"  alt="example" src={data.img} height={"250px"} />}
      >
        <Meta
          title={
            <>
              <h1 className="m-0 p-0 taxt-danger card-title">
                {data.subTitle}
              </h1>{" "}
              {data.description && (
                <p className="m-0 p-0" style={{ fontSize: "1.2em" }}>
                  {data.description}
                </p>
              )}
            </>
          }
          description={<p>{data.content}</p>}
        />
      </Card>
    </Col>
  ))
}
export const CRS = () => {
  return (
    <section className="csrDataSection">
      <Row className="d-flex flex-column mx-lx-8 mx-lg-8 mx-md-4 mx-3  text-align-center">
        <h1 className="m-0 p-0 taxt-danger main-title">
          {socialResponsibilty.title}
        </h1>
        <div className="main-desc">
          <p>{socialResponsibilty.desc}</p>
        </div>
      </Row>
      <Row className="d-flex flex-column mx-lx-8 mx-lg-8 mx-md-4 mx-3   text-align-center">
        <h1 className="m-0 p-0 taxt-danger sub-title">
          {socialResponsibilty.dataTitle}
        </h1>
      </Row>
      {socialResponsibilty?.responsibilityData && (
        <Row
          gutter={[12, 12]}
          className="mx-lx-8 mx-lg-8 mx-md-4 mx-3  custom-ant-row my-3"
        >
          {CrsCard(socialResponsibilty.responsibilityData)}
        </Row>
      )}
    </section>
  )
}
