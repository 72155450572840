import React, { useState } from "react"
import {
  Form,
  Button,
  Checkbox,
  Input,
  Divider,
  message,
  Select,
  Row,
  Col,
  Icon,
} from "antd"
import uuid from "react-uuid"
import Axios from "axios"
import TextArea from "antd/lib/input/TextArea"
import FormItem from "antd/lib/form/FormItem"
import ModalComponent from "../ModalComponent/Modal"
import { TermsConditionData } from "../../../services/termsAndConditionData"
import {
  characterValidation,
  validationRegex,
  jobTitleValidation,
  emailValidation,
  validateFileType,
  validateFileSize,
} from "./FormUtils"
import CommonInput from "./commonInput"
import CommonSelect from "./commonSelect"
import "./formStyle.less"

const { Option } = Select

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

export function ReferAFriendForm({ form }) {
  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
  } = form
  const [modalVisibility, setModalVisibility] = useState()
  const [filename, setFilename] = useState("")
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [agreement, setAgreement] = useState(true)
  const [login, setLoading] = useState(true)

  React.useEffect(() => {
    form.validateFields()
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setLoading(true)
    form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err) {
        Axios.post(
          `${process.env.GATSBY_MIDDLEWARE_API}api/EmailTrigger`,
          JSON.stringify({
            employeeFirstName: values.employeeFirstName,
            employeeLastName: values.employeeLastName,
            employeeEmail: values.employeeEmail,
            currentEmployee: values.currentEmployee,
            referralFirstName: values.referralFirstName,
            referralLastName: values.referralLastName,
            referralEmail: values.referralEmail,
            jobTitle: values.jobTitle,
            location: values.location,
            comments: values.comments,
            agreement: values.agreement.toString(),
            attachment: filename,
            type: "refer_a_friend",
          })
        )
          .then(res => {
            message.success("The form has been successfully submitted.")
            form.resetFields()
          })
          .catch(err => {
            if (err?.data?.errors) {
              Object.keys(err.data.errors).forEach(key => {
                err.data.errors[key].forEach(error => {
                  message.error(error)
                })
              })
            }

            message.error("We encountered an error while submitting the form.")
          })
          .finally(() => setLoading(false))
        // fetch(`${process.env.GATSBY_MIDDLEWARE_API}api/EmailTrigger`, {
        //   method: "POST",
        //   body: JSON.stringify({
        //     employeeFirstName: values.employeeFirstName,
        //     employeeLastName: values.employeeLastName,
        //     employeeEmail: values.employeeEmail,
        //     currentEmployee: values.currentEmployee,
        //     referralFirstName: values.referralFirstName,
        //     referralLastName: values.referralLastName,
        //     referralEmail: values.referralEmail,
        //     jobTitle: values.jobTitle,
        //     location: values.location,
        //     comments: values.comments,
        //     agreement: values.agreement.toString(),
        //     attachment: filename,
        //     type: "refer_a_friend",
        //   }),
        // })
      } else {
        message.error("We encountered an error while submitting the form.")
      }
    })
  }

  const handleModalView = () => {
    setModalVisibility(true)
  }

  const changeModalView = (value: boolean) => {
    setModalVisibility(value)
  }

  const checkAgreement = (rule, value, callback) => {
    if (!value) {
      setAgreement(false)
      callback("Please agree the terms and conditions!")
    } else {
      setAgreement(true)
      callback()
    }
  }

  const getBase64 = file => {
    let reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file)
      reader.onload = function() {
        let result: any = reader.result
        result = result.split("base64")[1]
        resolve(result)
      }
    })
  }

  const onFileChange = async (data: any) => {
    setIsFileUploading(true)
    let files = data.target.files
    let filenameSplit = files[0].name.split(".")
    if (!validateFileType(filenameSplit[filenameSplit.length - 1])) {
      data.target.value = null
      setIsFileUploading(false)
      return
    }
    if (!validateFileSize(files[0])) {
      data.target.value = null
      setIsFileUploading(false)
      return
    }
    setIsFileUploading(true)
    let filename = `${uuid()}_resume.${filenameSplit[filenameSplit.length - 1]}`
    const base64Format = await getBase64(files[0])
    Axios.post(
      `${process.env.GATSBY_MIDDLEWARE_API}api/uploadFile`,
      JSON.stringify({
        file_base_64: base64Format,
        filename: filename,
      })
    )
      .then(data => {
        setIsFileUploading(false)
        setFilename(filename)
      })
      .catch(err => {
        setIsFileUploading(false)
        message.error("We encountered an error while File Uploading the form.")
      })
    // fetch(`${process.env.GATSBY_MIDDLEWARE_API}api/uploadFile`, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     file_base_64: base64Format,
    //     filename: filename,
    //   }),
    // })
    //   .then(data => {
    //     setIsFileUploading(false)
    //     setFilename(filename)
    //   })
    //   .catch(err => setIsFileUploading(false))
  }

  const employeeFirstNameError =
    isFieldTouched("employeeFirstName") && getFieldError("employeeFirstName")
  const employeeLastNameError =
    isFieldTouched("employeeLastName") && getFieldError("employeeLastName")
  const employeeEmailError =
    isFieldTouched("employeeEmail") && getFieldError("employeeEmail")
  const currentEmployeeError =
    isFieldTouched("currentEmployee") && getFieldError("currentEmployee")
  const referralFirstNameError =
    isFieldTouched("referralFirstName") && getFieldError("referralFirstName")
  const referralLastNameError =
    isFieldTouched("referralLastName") && getFieldError("referralLastName")
  const referralEmailError =
    isFieldTouched("referralEmail") && getFieldError("referralEmail")
  const jobTitleError = isFieldTouched("jobTitle") && getFieldError("jobTitle")
  const locationError = isFieldTouched("location") && getFieldError("location")
  // const resumeError = isFieldTouched("resume") && getFieldError("resume")
  const commentsError = isFieldTouched("comments") && getFieldError("comments")

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <p className="heading">Your information</p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="formRow">
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={employeeFirstNameError ? "error" : ""}
              help={employeeFirstNameError || ""}
              label={"First Name"}
              name={"employeeFirstName"}
              rules={[
                {
                  required: true,
                  message: "Please enter your firstname",
                },
                { max: 50, message: "Character limit exceeded" },
                {
                  pattern: characterValidation,
                  message: "Please enter valid characters",
                },
              ]}
              placeholder={""}
              inputIcon={null}
            />
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={employeeLastNameError ? "error" : ""}
              help={employeeLastNameError || ""}
              label={"Last Name"}
              name={"employeeLastName"}
              rules={[
                {
                  required: true,
                  message: "Please enter your lastname!",
                },
                { max: 50, message: "Character limit exceeded" },
                {
                  pattern: characterValidation,
                  message: "Please enter valid characters",
                },
              ]}
              placeholder={""}
              inputIcon={null}
            />
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={employeeEmailError ? "error" : ""}
              help={employeeEmailError || ""}
              label={"Email"}
              name={"employeeEmail"}
              rules={[
                {
                  pattern: emailValidation,
                  message: "Please enter a valid email!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
              placeholder={""}
              inputIcon={null}
            />
            <CommonSelect
              getFieldDecorator={getFieldDecorator}
              label={"Current Employee at Adecco"}
              validateStatus={currentEmployeeError ? "error" : ""}
              help={currentEmployeeError || ""}
              rules={[
                {
                  required: true,
                  message: "Please enter your details!",
                },
                { max: 50, message: "Character limit exceeded" },
                {
                  pattern: characterValidation,
                  message: "Please enter valid characters",
                },
              ]}
              initialValue={null}
              name={"currentEmployee"}
              onchange={() => {}}
              loading={false}
              placeholder={""}
              options={["Yes", "No"].map(d => (
                <Option key={d} value={d}>
                  {d}
                </Option>
              ))}
              other={null}
            />
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Divider />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <p className="heading">Your referral information</p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="formRow">
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={referralFirstNameError ? "error" : ""}
              help={referralFirstNameError || ""}
              label={"First Name"}
              name={"referralFirstName"}
              rules={[
                {
                  required: true,
                  message: "Please enter referral firstname!",
                },
                { max: 50, message: "Character limit exceeded" },
                {
                  pattern: characterValidation,
                  message: "Please enter valid characters",
                },
              ]}
              placeholder={""}
              inputIcon={null}
            />
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={referralLastNameError ? "error" : ""}
              help={referralLastNameError || ""}
              label={"Last Name"}
              name={"referralLastName"}
              rules={[
                {
                  required: true,
                  message: "Please enter referral lastname!",
                },
                { max: 50, message: "Character limit exceeded" },
                {
                  pattern: characterValidation,
                  message: "Please enter valid characters",
                },
              ]}
              placeholder={""}
              inputIcon={null}
            />
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={referralEmailError ? "error" : ""}
              help={referralEmailError || ""}
              label={"Email"}
              name={"referralEmail"}
              rules={[
                {
                  pattern: emailValidation,
                  message: "Please enter a valid email!",
                },
                {
                  required: true,
                  message: "Please input referral E-mail!",
                },
              ]}
              placeholder={""}
              inputIcon={null}
            />
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={jobTitleError ? "error" : ""}
              help={jobTitleError || ""}
              label={"Job Title"}
              name={"jobTitle"}
              rules={[
                {
                  required: true,
                  message: "Please input referral job title!",
                },
                { max: 50, message: "Character limit exceeded" },
                {
                  pattern: jobTitleValidation,
                  message: "Please enter valid characters",
                },
              ]}
              placeholder={""}
              inputIcon={null}
            />
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={locationError ? "error" : ""}
              help={locationError || ""}
              label={"Location"}
              name={"location"}
              rules={[
                {
                  required: true,
                  message: "Please enater referral Location!",
                },
                { max: 250, message: "Character limit exceeded" },
              ]}
              placeholder={""}
              inputIcon={null}
            />
            {/* <Form.Item
              label="Submit your cover letter or resume"
              validateStatus={resumeError ? "error" : ""}
              help={resumeError || ""}
            >
              {getFieldDecorator("resume", {
                rules: [
                  {
                    required: false,
                    message: "Please upload resume!",
                  },
                ],
              })(
                <Input
                  type="file"
                  onChange={onFileChange}
                  accept=".pdf,.doc,.docx"
                  size="large"
                />
              )}
            </Form.Item> */}
          </div>
        </Col>
        {/* <Col span={24} lg={12} style={{ marginTop: "15px" }}>
          <CommonInput
            getFieldDecorator={getFieldDecorator}
            validateStatus={""}
            help={""}
            label={"Linkedin Profile"}
            name={"linkdin_profile"}
            rules={[
              {
                required: false,
              },
              { max: 250, message: "Character limit exceeded" },
            ]}
            placeholder="paste link here (optional)"
            inputIcon={
              <Icon
                type="linkedin"
                theme="filled"
                style={{ color: "#0e76a8" }}
              />
            }
          />

          <Row>
            <small className="c-secondary">
              Note: paste your referral linkedin profile link here
            </small>
          </Row>
        </Col> */}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{ marginTop: "15px" }}
        >
          <Form.Item
            label="Comments"
            validateStatus={commentsError ? "error" : ""}
            help={commentsError || ""}
          >
            {getFieldDecorator("comments", {
              validateTrigger: ["onBlur","onChange"],
              rules: [
                {
                  required: true,
                  message: "Please input your comments",
                },
                {
                  pattern: validationRegex,
                  message: "Please enter valid string",
                },
                { min: 10, message: "Minimum 10 characters are required" },
                { max: 250, message: "Character limit exceeded" },
              ],
            })(
              <TextArea autosize={false} rows={4} style={{ resize: "none" }} />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="formRow mt-5">
            <FormItem>
              {getFieldDecorator("agreement", {
                valuePropName: "checked",
                initialValue: "true",
                rules: [{ validator: checkAgreement }],
              })(
                <div>
                  <Checkbox defaultChecked={true}>I agree to the </Checkbox>
                  <a>
                    <small className="c-info" onClick={handleModalView}>
                      Terms and conditions
                    </small>
                  </a>
                  {modalVisibility && (
                    <ModalComponent
                      title="Terms and condition"
                      isVisible={modalVisibility as boolean}
                      getValueModal={changeModalView}
                      modalData={TermsConditionData()}
                    />
                  )}
                </div>
              )}
            </FormItem>
            <Form.Item className="ml-auto">
              <Button
                size="large"
                type="danger"
                htmlType="submit"
                className="mb-3 w-100 pl-5 pr-5"
                // disabled={disableSubmit}
                disabled={hasErrors(getFieldsError())}
                loading={isFileUploading}
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export const WrappedReferAFriendForm = Form.create({ name: "refer-a-friend" })(
  ReferAFriendForm
)
