import React from "react"
import {
  PageContentInnerSection,
  PageContentSubmitCvScreen,
} from "../components/PageContent/PageContent"
import { ProtectYourself } from "../components/Security/ProtectYourself"
import { SecurityScreen } from "../components/Security/SecurityScreen"
import { ContactUs } from "../components/Security/ContactUs"
import { securityData } from "../../services/SecurityData"
import { Banner } from "../components/Security/banner"
import CustomeBreadcrumb from "../components/common/breadcrumb"

function Security(props) {
  return (
    <PageContentSubmitCvScreen location={props.location}>
      <Banner title={'Security & Phishing'} />
      <div className="bg-white">
        <CustomeBreadcrumb home_path={"/"} home={"Home"} current={"Security"} />
        <PageContentInnerSection class="bg-white  py- px-sm-6 px-3 w-100">
          <SecurityScreen pageData={securityData.security} />
        </PageContentInnerSection>

        <PageContentInnerSection class="bg-secondary py-8 px-sm-6 px-3">
          <ProtectYourself pageData={securityData.protectYourself} />
        </PageContentInnerSection>

        <PageContentInnerSection class="bg-white pt-8 pb-9 px-sm-6 px-3">
          <ContactUs pageData={securityData.contactUs} />
        </PageContentInnerSection>
      </div>
    </PageContentSubmitCvScreen>
  )
}

export default Security
