import React from "react";
import { Col, Row } from "antd";
import { Link } from "gatsby";
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent";
import { NewBanner } from "../../../components/Solutions/newBanner";
import BreadcrumbSec from "./Breadcrumb";
import Sidebar from "./sidebar";
import DedicatedImg from "../../../images/solutions/InternationalEngagement/Dedicated-Centers.webp";
import InfrastructureImg from "../../../images/solutions/InternationalEngagement/Infrastructure-Services.webp";
import FinanceImg from "../../../images/solutions/InternationalEngagement/Finance-Outsourcing.webp";
import OffshoreImg from "../../../images/solutions/InternationalEngagement/Offshore-Resources.webp";
import banImg from "../../../images/solutions/banner/International-Engagement.webp";
import Stories from "../../../components/employerHomePage/stories";

const InternationalEngagement = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <NewBanner BannerImg={banImg} title={"International Engagement"} />
      <BreadcrumbSec current={"International Engagement"} />

      <section className="mainSolutionSection px-3 px-md-6">
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="float-right">
            <div className="box">
              <div className="heading">
                <h1>International Engagement</h1>
                <span className="separator"></span>
              </div>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">
                  Comprehensive services portfolio for international clients{" "}
                </h2>
              </div>
              <p className={""}>
              At Adecco, we believe talent knows no boundaries. We offer international outsourcing services, including the establishment of offshore delivery centers and recruitment services. Understanding the complexities of mobilizing a global expatriate workforce, we collaborate closely with our clients to provide tailored business solutions that meet their unique needs. Adecco can be your trusted Employer of Record (EOR) partner.
              </p>
              <p>
              We provide end-to-end flexible engagement models for global clients without a presence in India. Our services include administrative and infrastructure setup, finance and taxation, and talent supply across the entire value chain. We navigate formal obligations and ensure compliance with legal and regulatory requirements. Our International Engagement model helps global organizations establish a presence in India, supporting their workforce needs and enabling strategic growth and scalability. During this transition, we focus on key areas such as employee mobility, repatriation, sourcing, infrastructure, payroll and compliance, and office search.
              </p>
              <p className={"text-danger text-bold"}>
              Our extensive global expertise, local knowledge, and connected approach offer international engagement to companies offering convenience, simplicity, and efficiency of working with a single trusted workforce solutions partner. We act as a one-stop shop for international clients looking to expand into the Indian market.
              </p>
              <p>
              With our on-demand tracking and reporting, we provide meaningful data analysis to drive smart business decisions. Professional organizations can rely on us to navigate organizational changes, optimize operations, and reduce costs. We offer flexibility to scale up or down based on business requirements, ensuring confidence, ease, and superior results. Our dedicated team of industry experts delivers innovative, customized solutions that ensure a high return on investment and a seamless experience at every stage of engagement. We take a strategic lead and build stronger relationships with our clients through our international outsourcing services. Our unrivalled national coverage and global presence keep us ahead of the competition.
              </p>
              <div className="d-flex align-items-center">
                <h2 className="p-0 m-0">Global SMEs | Local Operations</h2>
              </div>
              <div className="d-flex align-items-center">
                <img loading="lazy"  src={OffshoreImg} alt="OffshoreImg" width={48} className="mr-2" />
                <h2 className="p-0 m-0">Offshore Resources: </h2>
              </div>
              <ul className={"pl-7"}>
                <li>Employer of Records (payrolling, compliance, finding the right fit)</li>
                <li>Expatriate mobilization</li>
                <li>VISA process/FRRO/Travel/Insurance</li>
                <li>Local talent employment and deployment</li>
                <li>HRMS – Recruitment, Onboarding, Attendance, Payroll, Compliance & Taxation services</li>
                <li>Payroll outsourcing & statutory compliance</li>
                <li>Corporate wellness program</li>
              </ul>

              <div className="d-flex align-items-center">
                <img loading="lazy"  src={InfrastructureImg} alt="InfrastructureImg" width={48} className="mr-2" />
                <h2 className="p-0 m-0">Infrastructure Services:</h2>
              </div>
              <ul className={"pl-7"}>
                <li>IT Assets - Procurement/Rental</li>
                <li>Office seating facility/New office search & leasing</li>
                <li>Admin services – Housekeeping, Security & Maintenance</li>
                <li>Vehicle leasing</li>
              </ul>

              <div className="d-flex align-items-center">
                <img loading="lazy"  src={FinanceImg} alt="FinanceImg" width={48} className="mr-2" />
                <h2 className="p-0 m-0">Finance Outsourcing: </h2>
              </div>
              <ul className={"pl-7"}>
                <li>Book-keeping</li>
                <li>Taxation & filing</li>
                <li>Audit & Company secretarial services</li>
              </ul>

              <div className="d-flex align-items-center">
                <img loading="lazy"  src={DedicatedImg} alt="DedicatedImg" width={48} className="mr-2" />
                <h2 className="p-0 m-0">Dedicated Centres: </h2>
              </div>
              <ul className={"pl-7"}>
                <li>Global Capability Centre (GCC) </li>
                <li>Development Centre</li>
                <li>Shared Services Centre</li>
                <li>Turnkey Offshore Centre</li>
              </ul>

              <div className="text-center">
                <Link
                  to="/employer/business-enquiry/"
                  state={{ interested: "International Engagement" }}
                >
                  Business Enquiry
                </Link>
              </div>
            </div>
          </Col>
          <Sidebar />
        </Row>
        <Stories heading="Case Study" isReadMore={false} />
      </section>
    </PageContentEmployerScreen>
  )
}

export default InternationalEngagement
