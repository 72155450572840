import React from "react";
import { Card, Col, Row } from "antd";
import { hardWorkData } from "../../../services/submitCvData";
import { WrappedSubmitYourCvF } from "../Forms/submit-your-cv-from";
import CustomeBreadcrumb from "../common/breadcrumb";

const HardWork = () => {
  return (
    <section className="hardWorkSection">
      <CustomeBreadcrumb
        home_path={"/"}
        home={"Home"}
        current={"Submit Your CV"}
      />
      <Row
        gutter={[24, 24]}
        className="px-sm-3 px-md-6 m-0 mt-5 mb-5 d-md-flex align-items-center"
      >
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          <div className="contact-text">
            {hardWorkData?.desc?.map(data => (
              <p key={data}>{data}</p>
            ))}
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={14}
          lg={14}
          xl={14}
          className="ml-auto mr-auto"
        >
          <Card
            title={"Submit Your CV"}
            className="submit-cv"
            headStyle={{
              backgroundColor: "red",
              color: "#ffff",
              fontSize: "1.75rem",
              textAlign: "center",
            }}
          >
            <WrappedSubmitYourCvF jobApply={{}} />
          </Card>
        </Col>
      </Row>
    </section>
  )
}

export default HardWork
