import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "antd";
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent";
import { NewBanner } from "../../../components/Solutions/newBanner";
import BreadcrumbSec from "./Breadcrumb";
import Sidebar from "./sidebar";
import LogisticsImg from "../../../images/solutions/Outsourcing/Logistics-&-Manufacturing.webp";
import merchandisingImg from "../../../images/solutions/Outsourcing/merchandising.webp";
import Sales_forceImg from "../../../images/solutions/Outsourcing/Sales_force.webp";
import auditImg from "../../../images/solutions/Outsourcing/audit.webp";
import promotionsImg from "../../../images/solutions/Outsourcing/promotions.webp";
import banImg from "../../../images/solutions/banner/Outsourcing-Services.webp";
import Stories from "../../../components/employerHomePage/stories";
import { blogVideoData } from "../../../../services/MediaData";
import VideosCardSolution from "../../../components/videos/videosCardSolution";

import "./common.less";

const OutsourcingServices = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <NewBanner BannerImg={banImg} title={"Outsourcing Services"} />
      <BreadcrumbSec current={"Outsourcing Services"} />

      <section className="mainSolutionSection px-3 px-md-6">
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="float-right">
            <div className="box">
              <div className="heading">
                <h1>Outsourcing Services</h1>
                <span className="separator"></span>
              </div>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">
                  Building agile & adaptable workforce solutions{" "}
                </h2>
              </div>
              <p className={""}>
                While the traditional concept of outsourcing can be clearly
                defined as a business practice in which non-critical services or
                job functions are farmed out to a third party near shore or
                offshore. In the recent past, the outsourcing industry has
                transitioned from non-core processes to more complex processes
                influencing business delivery models and diving deep into the
                core business processes with partner-owned solutions and
                outcome-driven approaches. Customer expectations have also
                changed over this period and the traditional recruitment
                outsourcing models have been overthrown by disruptions leading
                to a new age of disruptive outsourcing.
              </p>
              <p className={"text-danger text-bold"}>
                Outsourcing Services can provide significant value to an
                organization not just in terms of ‘direct to consumer route’ but
                also in terms of cost optimization, speed to market, and service
                improvement. The true differentiator is driven by evolving
                technology, a high level of innovation, and aggressive
                co-creation.{" "}
              </p>
              <p>
                Outsourcing through an expert will enable you to target new and
                underserved markets, help you leapfrog technological
                constraints, break silos, and scale up or down the size of the
                engagement as per your business cycle. We provide data backed
                business insights to increase your revenue and profits, greater
                accountability, and dependable performance management.{" "}
              </p>

              <div className="solution-icons">
                <h2 className="title">Our Services </h2>
                <div className="icon-sec">
                  <div className="icon">
                    <img loading="lazy"  src={merchandisingImg} alt="merchandisingImg" />
                    <h3>Merchandising </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={Sales_forceImg} alt="Sales_forceImg" />
                    <h3>Sales Force</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={auditImg} alt="auditImg" />
                    <h3>Audits</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={promotionsImg} alt="promotionsImg" />
                    <h3>Promotions</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={LogisticsImg} alt="LogisticsImgIMG" />
                    <h3>
                      Logistics &<br /> E-commerce
                    </h3>
                  </div>
                </div>
              </div>
              <p>
                As the best outsourcing company in India, we work with marquee
                global and Indian clients for whom we drive rural market
                development activities through widespread presence, daily
                monitoring of field operations, and capturing data on field
                activities. For another large multinational company, we provide
                the eyes and ears for merchandising management, branding and
                activation, and in-store retail marketing.{" "}
              </p>
              <div className="text-center">
                <Link
                  to="/employer/business-enquiry/"
                  state={{ interested: "General Staffing" }}
                >
                  Business Enquiry
                </Link>
              </div>
            </div>
          </Col>
          <Sidebar title={'Outsourcing Services'} />
        </Row>
        {/* <Row type="flex" align="end">
        <Col xs={24} sm={24} md={16} lg={16} xl={16} >
          <Row type="flex" align="center">
            <Col xs={24} sm={24} md={16} lg={10} xl={10}>
            <VideosCardSolution data={blogVideoData[0]} isvc={true} />
            </Col>
          </Row>
        </Col>
        </Row> */}
        <Stories heading="Case Study" isReadMore={false} />
      </section>
    </PageContentEmployerScreen>
  )
}

export default OutsourcingServices
