import React from "react";
import { PageContentSubmitCvScreen } from "../components/PageContent/PageContent";
import { Banner } from "../components/ReferAFriend/banner";
import ReferForm from "../components/ReferAFriend/referForm";
import WeCare from "../components/ReferAFriend/weCare";

function referAFriend(props) {
  return (
    <PageContentSubmitCvScreen location={props.location}>
      <Banner />
      <ReferForm />
      <WeCare />
    </PageContentSubmitCvScreen>
  )
}

export default referAFriend
