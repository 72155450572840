import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "antd";
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent";
import { NewBanner } from "../../../components/Solutions/newBanner";
import BreadcrumbSec from "./Breadcrumb";
import Sidebar from "./sidebar";
import PermSTaffingPSMSImg from "../../../images/solutions/Enterprisesolution/Perm-STaffing-PS-MS.webp";
import banImg from "../../../images/solutions/banner/Enterprise-Solutions.webp";
import Stories from "../../../components/employerHomePage/stories";

const EnterpriseSolutions = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <NewBanner BannerImg={banImg} title={"Enterprise Solutions"} />
      <BreadcrumbSec current={"Enterprise Solutions"} />

      <section className="mainSolutionSection px-3 px-md-6">
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="float-right">
            <div className="box">
              <div className="heading">
                <h1>Enterprise Solutions </h1>
                <span className="separator"></span>
              </div>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">
                  Creating value with a comprehensive suite of solutions
                </h2>
              </div>
              <p className={""}>
                Our global presence and comprehensive service portfolio enables
                us to engage with our clients as a trusted business partner. By
                prioritizing engagement, innovation, and transformation, we
                provide managed services to optimize business operations and
                drive growth. Our diverse range of services ensures consistent,
                high-quality delivery across global markets.{" "}
              </p>
              <p className={"text-danger text-bold"}>
                As a business partner with extensive experience across multiple
                industry verticals, we enable business transformation by
                providing consulting services and digital solutions based on our
                expertise in various industry verticals and emerging
                technologies.
              </p>

              <div className="d-flex align-items-center pb-2">
                <h2 className="p-0 m-0">Engagement Maturity Index: </h2>
              </div>
              <div>
                <img loading="lazy"  className="w-100" src={PermSTaffingPSMSImg} alt="Image" />
              </div>
              <div className="d-flex align-items-center pt-2">
                <h2 className="p-0 m-0">Service Offerings:</h2>
              </div>

              <ul className={"pl-7"}>
                <li>
                  <h3>Business Transformation Services</h3>
                  <p className="mb-1">
                    We bundle our digital portfolio into 3 core services to
                    succeed in the creation of new digital products, services,
                    and businesses – supporting full lifecycle business
                    transformation.
                  </p>
                  <ul className="pb-3">
                    <li>Digital Business Consulting</li>
                    <li>Technology Consulting </li>
                    <li>Business Domain SMEs </li>
                    <li>Global Solutions </li>
                  </ul>
                </li>
                <li>
                  <h3>Professional Services </h3>
                  <ul className="pb-3">
                    <li>Tech COEs </li>
                    <li>Technical Assessments & Skilling </li>
                    <li>Managed FTEs/Teams & BOTT </li>
                    <li className="pb-1">Project Oriented Services </li>
                  </ul>
                </li>

                <li>
                  <h3>Managed Services </h3>
                  <ul className="pb-3">
                    <li>Consulting & Advisory </li>
                    <li>SLA Based Programs</li>
                    <li>Managed IT Services </li>
                    <li className="pb-1">Business Process Management </li>
                  </ul>
                </li>
                <li>
                  <h3>Digital Solutions </h3>
                  <ul className="pb-3">
                    <li>Digital Engineering </li>
                    <li>Cloud & Infrastructure</li>
                    <li>Data & Analytics </li>
                    <li>Business Process Automation </li>
                    <li className="pb-1">Quality Assurance</li>
                  </ul>
                </li>
              </ul>
              <div className="text-center">
                <Link
                  to="/employer/business-enquiry/"
                  state={{ interested: "Enterprise Solutions" }}
                >
                  Business Enquiry
                </Link>
              </div>
            </div>
          </Col>
          <Sidebar />
        </Row>
        <Stories heading="Case Study" isReadMore={false} />
      </section>
    </PageContentEmployerScreen>
  )
}

export default EnterpriseSolutions
