import { Breadcrumb, Col, Row } from 'antd'
import { Link } from 'gatsby'
import React from 'react'
import RenderMenu from '../Menu/RenderMenu'

export const BredcrumbEmployer = ({ home_path, home, perent,current,data}) => {
  return (
    <Row className="custome-breadcrumb mt-2 p-3">
        <Col>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={home_path}>{home}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item overlay={<RenderMenu data={data} />}>
              {perent}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="">{current}</a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
  )
}
