import React from "react";
import { Col, Row } from "antd";

const DownloadOurBrochure = () => {
  return (
    <section className="DownloadOurBrochure">
      <Row gutter={[24, 24]} className="my-3 px-7 ">
        {/* <div className="text-center">
          <img loading="lazy" 
            width={"100%"}
            src="https://www.adeccousa.com/~/media/adeccogroup/brands/adecco-global-2016/usa/page-assets/about-adecco-staffing/adecco-programs-partnerships/veterans-and-military-spouses/soldier-with-family.jpg"
            alt="adecco-global-image"
          />
        </div> */}
        <div className="mb-4">
          <h1 className="text-danger text-center py-3">
            What can you expect from Adecco?
          </h1>
          <p>
            Underemployment in post-service careers is a barrier to a meaningful
            career. There are many things’ organizations can do to attract and
            benefit from the veteran community. Our aim is to build more synergy
            with progressive employers who think holistically about their hiring
            goals and foster a work culture where veterans and spouses are
            understood and valued.
          </p>
        </div>
        <Col xs={24} lg={24} xl={24} className=" cous-border1">
          <div>
            <p className="text-bold">
              Steps to ensure we see the most success in these efforts:
            </p>
            <ul className="pl-5">
              <li>
                Change the perception that experiences in the military are less
                relevant in corporate
              </li>
              <li>
                Remove barriers to limited understanding of military terminology
                and work skills
              </li>
              <li>Focus on skills and not competencies in hiring</li>
              <li>
                Translating the military experience to resumes that are
                shortlisted and outlining specific career paths
              </li>
              <li>
                Providing avenues for them to develop their skills and continue
                growing in their civilian career
              </li>
              <li>
                Creating opportunities to teach and mentor others, which are key
                to ensuring their success.
              </li>
            </ul>
            <div className="text-center  mt-5">
            </div>
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default DownloadOurBrochure
