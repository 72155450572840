import React, { Fragment } from "react";
import { Row, Col, Breadcrumb, Card, Divider } from "antd";
import { Link } from "gatsby";
import { PressReleaseData } from "../../../../services/PressReleaseData";
import  {
  PageContentEmployerScreen,
} from "../../../components/PageContent/PageContent";
import banner_img1 from "../../../images/PressRelease/banner/DIRECTORATE-GENERAL-RESETTLEMENT.webp";
import backIcon from "../../../images/icons/icons8-back-24.webp";
import fbIcon from "../../../images/icons/facebook.webp";
import ldIcon from "../../../images/icons/linkedin.webp";
import ttIcon from "../../../images/icons/twitter.webp";
import "./common.less";

export default function PressRelease(props) {
  const data={
    press_id:"PRESS0011",
    id:
      "directorate-general-resettlement",
    banner_img: banner_img1,
    category: "General News",
    date: "Delhi, 21st June 2023",
    title:
      "Directorate General resettlement and Adecco India Private Limited signs MoU to foster employment opportunities for ex-servicemen",
    description:
      "A Memorandum of Understanding (MoU) was signed between Directorate General Resettlement under the Department of Ex-Servicemen Welfare, Ministry of Defence, and Adecco India Private Limited today at the office of DGR, West Block IV, RK Puram, New Delhi on 21 June 23.",
    content: `
    <h1>Directorate General resettlement and Adecco India Private Limited signs MoU to foster employment opportunities for ex-servicemen</h1>
<p> <b><u>Delhi, 21st June 2023: </u></b> A Memorandum of Understanding (MoU) was signed between Directorate General Resettlement under the Department of Ex-Servicemen Welfare, Ministry of Defence, and Adecco India Private Limited today at the office of DGR, West Block IV, RK Puram, New Delhi on 21 June 23.</p>
<p> The signing of the MoU between DGR and Corporates endeavors to bring the corporate companies and Ex-Servicemen on a common platform to generate employment opportunities for the esteemed Ex-Servicemen of the Defence Services.</p>
<p> Adecco India is one of the leading staffing and recruitment solutions providers in India, offering a broad range of tailored solutions to employers and flexible working opportunities for job seekers. With a strong presence and deep understanding of the Indian job market, they specialize in temporary staffing, permanent placement, outsourcing, and consulting services.</p>
<p><b>Maj Gen Sharad Kapur, YSM, SM Director General (Resettlement) remarked that</b> “this partnership would bring in more visibility of our Ex-servicemen to the industry and corporates and help in achieving the objectives of providing skilled manpower and giving a dignified second career to our Ex-servicemen”.</p>
<p><b>Speaking about the partnership, Ajay Sethi, CEO of Adecco India</b>, said "We are delighted to announce the collaboration between Adecco India and the Directorate General Resettlement, Department of Ex-Servicemen Welfare, Ministry of Defence. This partnership exemplifies our dedication to supporting and empowering military veterans and their families to create meaningful opportunities in various sectors across IT, E-Commerce, Logistics, Telecom, Aerospace & Defence, Facility Management, Security, Digital Assessment Services, Power & Energy, Manufacturing, Chemical & Agro. etc. Through this collaboration, we will ensure a smooth transition for our veterans into the civilian workforce, providing them opportunities to leverage their unique skills and experiences for a brighter future."</p>
<h4><b>About Adecco India</b></h4>
<p>Adecco India is one of the leading staffing and recruitment solutions providers in India, offering a broad range of tailored solutions to employers and flexible working opportunities
for job seekers. With a strong presence and deep understanding of the Indian job market,
we specialize in temporary staffing, permanent placement, outsourcing, and consulting
services. As a trusted partner for businesses and individuals alike, we believe in skills focus
to connect talents with rewarding opportunities, while helping organizations build and
optimize their workforce for long-term success. When it comes to recruiting talent, diversity,
and inclusion are binding principles for us. We see inclusion not only as a business model
that addresses talent scarcity and brings long-term economic benefits but also as a
responsibility toward society. We currently have over 900 employees working for us in
various Adecco offices throughout the country, and over 35.000 associates placed with
business clients in India.
</p>


<h4><b>For further information please contact:</b></h4>
<p>Cauvery Uthappa | <a href="mailto:cauvery.uthappa@adecco.com" class="taxt-dark">cauvery.uthappa@adecco.com</a> | <a href="tel:+919845172958" class="taxt-dark">+919845172958</a></p>
    `,
    link:
      "/employer/press-release/press-release-11",
  }

  

  const listData: any = []
  for (let i = 0; i < 6; i++) {
    listData.push({
      href: "https://ant.design",
      title: `ant design part ${i}`,
      avatar: "https://joeschmoe.io/api/v1/random",
      description:
        "Ant Design, a design language for background applications, is refined by Ant UED Team.",
      content:
        "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
    })
  }
  return (
    <>
      {data?.banner_img && (
        <section className="PressSection">
          <Row>
            <div className="hero-section" style={{backgroundImage: `url('${data.banner_img}')`}}>
              <div className="overlay"></div>
              <div className="content-section"></div>
            </div>
          </Row>
        </section>
      )}

      <PageContentEmployerScreen
        location={props.location}
      >
        <Row className="custome-breadcrumb mt-5">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/employer/press-release"}>
                  <img loading="lazy"  src={backIcon} /> Back to Press Release
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="float-right">
            <div className="d-flex">
              <span>Share|</span>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={fbIcon} alt="Facebook" />
              </a>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={ldIcon} alt="linkedin" />
              </a>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={ttIcon} alt="twitter" />
              </a>
            </div>
          </Col>
        </Row>
        <Row type="flex" className="mobile-flex">
          <Col lg={9} md={24} className="px-3 related-press-release-card">
            <Card title="Related Press Release" size="small" className="">
              {PressReleaseData.map(
                (data, i) =>
                  i > 4 && (
                    <Fragment key={data.id}>
                      <div className="d-flex">
                        <img loading="lazy" 
                          src={data.releted_img}
                          alt="img"
                          className="rounded"
                          width={100}
                        />
                        <Link
                          to={data.link}
                          className="ml-2 fw-600 press-title"
                        >
                          {data.title}
                        </Link>
                      </div>
                      <Divider />
                    </Fragment>
                  )
              )}
            </Card>
          </Col>
          <Col lg={14} md={24} className="press-release-section">
            {data && (
              <Row
                className="fw-500 font-normal"
                dangerouslySetInnerHTML={{ __html: data?.content }}
              ></Row>
            )}
          </Col>
        </Row>
      </PageContentEmployerScreen>
    </>
  )
}
