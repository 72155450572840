import React from "react";
import { Link, navigate } from "gatsby";
import { Card, Col, Row } from "antd";

export default function PressReleaseCard(props: any) {
  let styles: any = {
    height: "225px",
  }
  return (
    <Col xs={24} xl={8} lg={12} md={24} sm={24} key={props.key}>
        <div
          className="card-ribbon pressReleaseBox pressRibbon"
          data-label={props.data.category}
        >
          <Card
            className="card-hover"
            bodyStyle={{
              paddingTop: "10px",
            }}
            hoverable
            cover={<img loading="lazy"  alt="example" src={props.data.card_img} />}
          >
            <Row style={styles} >
              <Row type="flex" className="text-align-center">
                <span className="font-medium fw-600 pb-1 press-title" onClick={()=>navigate(props.data.link)}>
                  {props.data.title}
                </span>
                <span className="font-xxsmall pb-1 w-100 c-secondary text-align-center ">
                  {props.data.date}
                </span>
                <span className="font-small pb-1 press-desc" >
                  {props.data.description}
                </span>
              </Row>
            </Row>
            <Row type="flex" justify="end">
              <Link to={props.data.link}>read more</Link>
            </Row>
          </Card>
        </div>
    </Col>
  )
}
