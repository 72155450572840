import React, { Fragment } from "react";
import { Row, Col, Breadcrumb, Card, Divider } from "antd";
import { Link } from "gatsby";
import { PressReleaseData } from "../../../../services/PressReleaseData";
import  {
  PageContentEmployerScreen,
} from "../../../components/PageContent/PageContent";
import banner_img2 from "../../../images/PressRelease/banner/PlanInternational.webp";
import backIcon from "../../../images/icons/icons8-back-24.webp";
import fbIcon from "../../../images/icons/facebook.webp";
import ldIcon from "../../../images/icons/linkedin.webp";
import ttIcon from "../../../images/icons/twitter.webp";
import "./common.less";

export default function PressRelease(props) {
  const data={
    press_id:"PRESS003",
    id:
      "adecco-supports-plan-internationals-saksham-in-its-endeavour-to-upskill-youth-especially-girls-from-urban-and-rural-disadvantaged-areas",
    banner_img: banner_img2,
    category: "General News",
    date: "Mumbai, November 29 2021",
    title:
      "Adecco supports Plan International's 'Saksham' in its endeavour to upskill youth, especially girls, from urban and rural disadvantaged areas",
    description:
      "Adecco, a leading global workforce provider, is joining hands with Plan India, through their Saksham initiative which undertakes an intensive upskilling program for underprivileged youth",
    content: `
    <h1>Adecco supports Plan International's 'Saksham' in its endeavour to upskill youth, especially girls, from urban and rural disadvantaged areas</h1>
<p><b><u>Mumbai, November 29 2021: </u></b> Adecco, a leading global workforce provider, is joining hands with Plan India, through their Saksham initiative which undertakes an intensive upskilling program for underprivileged youth. The initiative aims at upskilling over 200 enrolled women & men between 18-24 years of age from the disadvantaged communities of Bengaluru and help them improve their technical, vocational, and life skill knowledge and support at least 60% of them secure decent employment. In addition, the program will provide skill building and job-oriented career counselling to over 300 youth.</p>
<p>The Adecco Group commits towards ethical social projects to provide education and job training opportunities across the globe. As a part of a successful collaboration with Plan International globally for their flagship engagement Program Win4Youth, the Adecco Group has made an annual contribution of US$500,000 to Plan International to support youth employment and youth economic empowerment programs across various countries.</p>
<p>Plan India’s Saksham initiative supports youth, especially girls, from urban and rural disadvantaged areas with free, market-oriented vocational skills, as well as job and entrepreneurial training. The programme also goes several steps further and includes basic IT skills, presentation, etiquette and basic business conversational skills in the training. Saksham has a strong focus on training that challenges gender stereotypes and widespread discrimination. Saksham engages parents, communities, and employers on the subjects of gender equality, equal opportunities, and economic empowerment of girls and women.</p>
<p>So far the programme has successfully skilled and placed more than 5,500 young people, including 3,400 young women, in new economy jobs across hospitality, retail and IT-enabled services.</p>
<p>Commenting on the partnership, Mr Mohammed Asif, Executive Director, Plan India said,” Plan India’s Saksham program seeks to usher market oriented vocational skills among young women and men so that they can contribute to India’s economic development story. As skilled workforce, the youth trainees of the Saksham program are more confident, economically secure and build a sustainable future for themselves and their families. Also the program teaches life skills, financial literacy and inclusion which enables the trainees, especially young women to acquire skills to make decisions for themselves and walk the path of success. The Plan India – Adecco partnership forwards this vision by encouraging young girls and women to build their employability skills and contribute to nation building”.</p>
<p><b>About the Adecco Group</b></p>
<p>The Adecco Group is the world’s leading talent advisory and solutions company. We believe in making the future work for everyone, and every day enable more than 3.5 million careers. We skill, develop and hire talent in 57 countries, enabling organisations to embrace the future of work. As a Fortune Global 500 company, we lead by example, creating shared value that fuels economies, and builds better societies. Our culture of inclusivity, fairness and teamwork empowers our employees.
    The Adecco Group is headquartered in Zurich, Switzerland (ISIN: CH0012138605) and listed on the SIX Swiss Exchange (ADEN).
</p>
<p><b>About Plan India</b></p>
<p>Plan India is a nationally registered not for profit organisation striving to advance children’s rights and equality for girls, thus creating a lasting impact in the lives of vulnerable and excluded children and their communities.</p>
<p>Since 1979, Plan India and its partners have improved the lives of millions of children and young people by enabling them access to protection, quality education and healthcare services, a healthy environment, livelihood opportunities and participation in decisions which affect their lives. Plan India is a member of the Plan International Federation, an independent development and humanitarian organisation that advances children’s rights and equality for girls. Plan International is active in more than 70 countries.</p>
<h4><b>For further information please contact:</b></h4>
  <p>Cauvery Uthappa | <a href="mailto:cauvery.uthappa@adecco.com" class="taxt-dark">cauvery.uthappa@adecco.com</a> | <a href="tel:+919845172958" class="taxt-dark">+919845172958</a></p>
    `,
    link:
      "/employer/press-release/press-release-3",
  }

  

  const listData: any = []
  for (let i = 0; i < 6; i++) {
    listData.push({
      href: "https://ant.design",
      title: `ant design part ${i}`,
      avatar: "https://joeschmoe.io/api/v1/random",
      description:
        "Ant Design, a design language for background applications, is refined by Ant UED Team.",
      content:
        "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
    })
  }
  return (
    <>
      {data?.banner_img && (
        <section className="PressSection">
          <Row>
            <div className="hero-section" style={{backgroundImage: `url('${data.banner_img}')`}}>
              <div className="overlay"></div>
              <div className="content-section"></div>
            </div>
          </Row>
        </section>
      )}

      <PageContentEmployerScreen
        location={props.location}
      >
        <Row className="custome-breadcrumb mt-5">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/employer/press-release"}>
                  <img loading="lazy"  src={backIcon} /> Back to Press Release
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="float-right">
            <div className="d-flex">
              <span>Share|</span>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={fbIcon} alt="Facebook" />
              </a>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={ldIcon} alt="linkedin" />
              </a>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={ttIcon} alt="twitter" />
              </a>
            </div>
          </Col>
        </Row>
        <Row type="flex" className="mobile-flex">
          <Col lg={9} md={24} className="px-3 related-press-release-card">
            <Card title="Related Press Release" size="small" className="">
              {PressReleaseData.map(
                (data, i) =>
                  i > 4 && (
                    <Fragment key={data.id}>
                      <div className="d-flex">
                        <img loading="lazy" 
                          src={data.releted_img}
                          alt="img"
                          className="rounded"
                          width={100}
                        />
                        <Link
                          to={data.link}
                          className="ml-2 fw-600 press-title"
                        >
                          {data.title}
                        </Link>
                      </div>
                      <Divider />
                    </Fragment>
                  )
              )}
            </Card>
          </Col>
          <Col lg={14} md={24} className="press-release-section">
            {data && (
              <Row
                className="fw-500 font-normal"
                dangerouslySetInnerHTML={{ __html: data?.content }}
              ></Row>
            )}
          </Col>
        </Row>
      </PageContentEmployerScreen>
    </>
  )
}
