import React from "react";
import { Col, Row } from "antd";
import { Link} from "gatsby";
import { socialResponsibilty } from "../../../services/SocialResponsibiltyData";
import "./common.less";

const ProgramsAndPartnership = () => {
  return (
    <>
    
      <section className="program-new px-lx-7 px-lg-7 px-md-4 px-5 py-6">
        <Row>
          <div className="prog-main-head">
            <h3>Programs & Partnerships</h3>
            <p>
              We build strong partnerships of mutual trust bringing many
              benefits, particularly in the areas of employability and access to
              work, and thus livelihoods. As a trusted advisor we work with job
              seekers – freshers, early career aspirants, seasoned
              professionals, military veterans, and their spouses advising them
              on their careers, and coaching them to develop their talent with
              training, upskilling and lifelong learning.
            </p>
            <p>
              We invest in our programs to create real value for our candidates,
              associates, employees, clients, suppliers, and communities at
              large and endeavor to create an environment that enables and
              empowers everyone to achieve their goals. Our mission is to
              support all our stakeholders by being even more resilient,
              innovative, and responsive.
            </p>
          </div>
        </Row>
        <Row gutter={[24, 24]} className=" custom-ant-row my-3">
          {socialResponsibilty.responsibilityData?.map(m => (
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ padding: "20px" }}
            >
              <div className="box">
                <div className="images">
                  <img loading="lazy"  src={m.img}  alt={m.subTitle}/>
                </div>
                <div className="details">
                  <Link
                    className="link-title"
                    to={"/employer/corporate-social-responsibility/"}
                  >
                    <h4>{m.subTitle}</h4>
                  </Link>
                  <p>{m.content}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </section>
    </>
  )
}

export default ProgramsAndPartnership
