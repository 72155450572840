import React from "react"
import { Button, Card, Col, Icon, Row } from "antd"
import LogoSlider from "react-slick"
import { linkResourceData } from "../../../services/linkResourceData"
import Arrow from "../../images/sliderArrow.webp"
import LocationIcon from "../../images/location.svg"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./vetern-hiring.less"
import { Link } from "gatsby"

function NextArrow(props) {
  const { onClick } = props
  return (
    <div className="nextArrow" onClick={onClick}>
      <img loading="lazy" src={Arrow} alt={"nextArrow"} />
    </div>
  )
}

function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className="prevArrow" onClick={onClick}>
      <img loading="lazy" src={Arrow} alt="prevArrow" />
    </div>
  )
}

const storiesData = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const LinkAndResources = () => {
  return (
    <Row className="storiesSection link-and-resources-section">
      <Row className="heading">
        <h3>Hot Jobs</h3>
      </Row>
      <Row className="card-section">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="StoriesSlider"
            >
              <LogoSlider {...storiesData}>
                {linkResourceData?.map((item, i) => (
                  <div className="items link-card" key={i}>
                    {/* <img className="head-img" loading="lazy" src={item.image} alt={"items" + "" + i} /> */}
                    <h3 className="itm-title">{item.title}</h3>
                    <div className="desc">
                      <img loading="lazy" src={LocationIcon} alt="Location" />
                      <p className="location-desc">{item.jobLocation}</p>
                    </div>
                    <div className="text-center">
                      <Button
                        type="danger"
                        className="bg-primary fw-700"
                        size="large"
                      >
                        <Link to={item?.link} target="_blank">
                          QUICK APPLY
                        </Link>
                      </Button>
                    </div>
                  </div>
                ))}
              </LogoSlider>
            </Col>
          </Row>
        </Col>
      </Row>
    </Row>
  )
}

export default LinkAndResources
