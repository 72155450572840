import React, { Fragment } from "react"
import { Row, Col, Breadcrumb, Card, Divider } from "antd"
import { Link } from "gatsby"
import { PressReleaseData } from "../../../../services/PressReleaseData"
import  {
  PageContentEmployerScreen,
} from "../../../components/PageContent/PageContent"
import banner_img1 from "../../../images/PressRelease/banner/GLOBALTALENTCOMPETITIVENESS.webp"
import backIcon from "../../../images/icons/icons8-back-24.webp"
import fbIcon from "../../../images/icons/facebook.webp"
import ldIcon from "../../../images/icons/linkedin.webp"
import ttIcon from "../../../images/icons/twitter.webp"
import "./common.less"

export default function PressRelease(props) {
  const data={
    press_id:"PRESS009",
    id: "2020-global-talent-competitiveness-index-released",
    banner_img: banner_img1,
    category: "General News",
    date: "Davos, Switzerland, January 22, 2020",
    title: "2020 Global Talent Competitiveness Index Released",
    description:
      "The Adecco Group is committing to upskill and reskill five million people around the world by 2030. New approaches are being tried and tested to find the optimum balance, where people and technology can successfully work side by side and thrive in the workplace of the future.",
    content: `
    <h1>2020 GLOBAL TALENT COMPETITIVENESS INDEX RELEASED</h1>
<h4><b>Digital skills gap intensifies widening divide between high-income nations &amp; rest of world The Adecco Group commits to upskill / reskill five million people by 2030</b></h4>
<ul>
    <li>Report finds that gap between high income, talent-rich nations and the rest of the world is widening; more than half of the population in the developing world lack basic digital skills</li>
    <li>Switzerland tops this year’s ranking, followed by the United States, its highest place yet, and Singapore in third place</li>
    <li>AI talent is particularly scarce and unequally distributed across industries, sectors and nations</li>
    <li>Broad-based re-skilling is urgently needed to develop ‘fusion skills’ that enable humans and machines to effectively and efficiently interact in hybrid activities</li>
    <li>The Adecco Group commits to reskill and upskill five million people around the world by 2030, led by General Assembly, the Group’s skilling arm and global leader in future-skills education</li>
</ul>
<p><b>Davos, Switzerland, 22 nd January 2020,</b> A lack of digital skills is widening the gap between high-income nations and the rest of the world, according to research from the Adecco Group, the world’s leading HR solutions company, in partnership with INSEAD and Google.</p>
<p>The Global Talent Competitiveness Index (GTCI) 2020, released at the World Economic Forum in Davos, reveals that Switzerland continues to lead the world in talent competitiveness, having held the number one spot since the Index was launched in 2013, and the US moves from third place to second, pushing Singapore down one place compared to 2019.</p>
<p>Overall, high income countries dominate the top 25 and the index shows that these top ‘talent champions’ are accelerating further away from the rest of the world. This divide is being intensified by the rise of Artificial Intelligence (AI) and the associated digital skills gap that has emerged between industries, sectors and nations.</p>
<p>Acknowledging this skills mismatch and the importance of investing in human capital, the Adecco Group is committing to upskill and reskill 5 million people by 2030. The reskilling push will be led by the Group’s training and development arm, General Assembly, which specializes in equipping individuals and teams with today’s most in-demand digital skills including data science, coding and machine learning capabilities.</p>
<p>Commenting on the 2020 Index, <b>the Adecco Group’s Chief Executive Officer, Alain Dehaze said:</b></p>
<p>“As machines and algorithms continue to affect a multiplicity of tasks and responsibilities and almost every job gets reinvented, having the right talent has never been more critical.</p>
<p>Today, robots and algorithms have travelled beyond the factory floor and are functioning at front of house, the back office and company headquarters. At all levels, workers need training to hone quintessential “human skills” - adaptability, social intelligence, communication, problem solving and leadership - that will complement technology.</p>
<p>This decade will be characterized by a re-skilling revolution with a focus on ‘fusion skills’ - enabling humans and machines to work in harmony in a hybrid model. With this in mind, the Adecco Group is committing to upskill and reskill five million people around the world by 2030 – equipping individuals with future skills that will enable them to thrive in the AI age.”</p>
<p>The theme of this year’s GTCI report focuses on global talent in the age of AI.  Notably, the report finds that more than half of the population in the developing world lack basic digital skills, and that the digital skills gap is only widening, with a few countries progressing quickly while most of the developing world lags behind.</p>
<p>Marco Valsecchi, Country Manager and Managing Director, The Adecco Group India said, “India is constantly investing in skill development and robust digitalization. It is as imperative for the corporates to build comprehensive digital business strategies as it is for the talent to be adaptable to rapid transformations and relevant for the new requirements. [Add a line on India data once the report is accessible]. Through our reskilling and up skilling initiatives, we aim to build an agile workforce that keeps up with the technological advancements.”</p>
<p>New approaches are being tried and tested to find the optimum balance, where people and technology can successfully work side by side and thrive in the workplace of the future. As these new collaborations continue to be developed, global talent competitiveness is being redefined, with nations striving to position themselves as leaders of the AI revolution. While the digital skills gap is significant and continuing to expand, the report’s analysis found that AI could provide significant opportunities for emerging markets to ‘leapfrog’.</p>
<p>For example, the longitudinal analyses of talent competitiveness reveal that some developing countries such as China, Costa Rica and Malaysia possess the potential to become ‘talent champions’ in their respective regions. Meanwhile, other countries like Ghana and India have improved their capacity to enable, attract, grow and retain talent in recent years, earning them status as ‘talent movers’.</p>
<p>Looking at cities, New York tops the ranking this year, followed by London, Singapore, San Francisco and Boston. New York’s leading position can be attributed to its strong performance across four of the five pillars measured in the research, specifically in the “Enable”, “Attract”, “Grow” and “Global Knowledge Skills” categories.</p>
<p>Generally, cities with a proven ability for “future readiness” ranked highly, with activities in fields including AI, fintech and medtech, favouring the talent performance of the top five. Many cities are increasingly becoming testbeds for new AI based tools such as facial recognition, tele-surveillance and autonomous vehicles. The success of these vary across cities, but those that do well will emerge as AI hubs that have the talent pools to sustainably deploy global solutions.</p>
<p>
<h4><b>About the 2020 Global Talent Competitiveness Index (GTCI)</b></h4>In its 7th edition, the Global Talent Competitiveness Index (GTCI) is an annual benchmarking tool ranking countries and major cities on their ability to develop, attract and retain talent. Developed in 2013 by INSEAD in partnership with the Adecco Group, the report provides a tool for governments, cities, businesses and not-for-profit organisations to help design their talent strategies, overcome talent mismatches and be competitive in the global marketplace. GTCI covers national and organisational parameters and generates insights to inspire action. This year’s index includes 70 variables and covers 132 countries and 155 cities, across all groups of income and levels of development. The GTCI is a composite index, relying on a robust, action-focused Input-Output model, for policymakers and business leaders to learn from and respond to.</p>
<p>The 2020 edition addresses the theme of Global Talent in the Age of Artificial Intelligence. The report aims to explore how the development of Artificial Intelligence (AI) is not only changing the nature of work but also forcing a re-evaluation of workplace practices, corporate structures and innovation ecosystems. As machines and algorithms continue to affect a multiplicity of tasks and responsibilities and almost every job gets reinvented, the right talent is required not only to carry out new responsibilities and ways to work, but also to capture value from this transformative technology. This topic stands at the heart of the debate in this era of the Fourth Industrial Revolution as AI has become a game-changer in every industry and sector. Current education and skills acquisition will be transformed as well, implying that formal and informal learning structures will evolve to meet the needs created by this very same AI-driven world.</p><br />
<p>For more information:</p>
<ul>
    <li>Download the full GTCI 2020 report</li>
    <li>Download the Highlights report</li>
    <li>Browse the GTCI study website</li>
    <li>Follow us on Twitter #GTCI for updates</li>
</ul>
<h4><b>About the Adecco Group</b></h4>
<p>The Adecco Group is the world’s leading HR solutions company. We believe in making the future work for everyone, and every day enable more than 3.5 million careers. We skill, develop and hire talent in 60 countries, enabling organizations everywhere to embrace the future of work. As a Fortune Global 500 company, we lead by example, creating shared value that strengthens economies and builds better societies. Our culture of inclusivity, entrepreneurship and teamwork empowers our 34,000 employees, who voted us number 11 on the Great Place to Work® - World’s Best Workplaces 2019 list. The Adecco Group AG is headquartered in Zurich, Switzerland (ISIN: CH0012138605) and listed on the SIX Swiss Exchange (ADEN) and powered by ten global lead brands: Adecco, Adia, Badenoch &amp; Clark, General Assembly, Lee Hecht Harrison, Modis, Pontoon, Spring Professional, Vettery and YOSS.</p>
<h4><b>For further information please contact:</b></h4>
  <p>Cauvery Uthappa | <a href="mailto:cauvery.uthappa@adecco.com" class="taxt-dark">cauvery.uthappa@adecco.com</a> | <a href="tel:+919845172958" class="taxt-dark">+919845172958</a></p>
    `,
    link:
      "/employer/press-release/press-release-9",
  }

  

  const listData: any = []
  for (let i = 0; i < 6; i++) {
    listData.push({
      href: "https://ant.design",
      title: `ant design part ${i}`,
      avatar: "https://joeschmoe.io/api/v1/random",
      description:
        "Ant Design, a design language for background applications, is refined by Ant UED Team.",
      content:
        "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
    })
  }
  return (
    <>
      {data?.banner_img && (
        <section className="PressSection">
          <Row>
            <div className="hero-section" style={{backgroundImage: `url('${data.banner_img}')`}}>
              <div className="overlay"></div>
              <div className="content-section"></div>
            </div>
          </Row>
        </section>
      )}

      <PageContentEmployerScreen
        location={props.location}
      >
        <Row className="custome-breadcrumb mt-5">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/employer/press-release"}>
                  <img loading="lazy"  src={backIcon} /> Back to Press Release
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="float-right">
            <div className="d-flex">
              <span>Share|</span>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={fbIcon} alt="Facebook" />
              </a>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={ldIcon} alt="linkedin" />
              </a>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={ttIcon} alt="twitter" />
              </a>
            </div>
          </Col>
        </Row>
        <Row type="flex" className="mobile-flex">
          <Col lg={9} md={24} className="px-3 related-press-release-card">
            <Card title="Related Press Release" size="small" className="">
              {PressReleaseData.map(
                (data, i) =>
                  i > 4 && (
                    <Fragment key={data.id}>
                      <div className="d-flex">
                        <img loading="lazy" 
                          src={data.releted_img}
                          alt="img"
                          className="rounded"
                          width={100}
                        />
                        <Link
                          to={data.link}
                          className="ml-2 fw-600 press-title"
                        >
                          {data.title}
                        </Link>
                      </div>
                      <Divider />
                    </Fragment>
                  )
              )}
            </Card>
          </Col>
          <Col lg={14} md={24} className="press-release-section">
            {data && (
              <Row
                className="fw-500 font-normal"
                dangerouslySetInnerHTML={{ __html: data?.content }}
              ></Row>
            )}
          </Col>
        </Row>
      </PageContentEmployerScreen>
    </>
  )
}
