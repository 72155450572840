import Julie_Rosy_Img from "../src/images/ADA/Julie_Rosy.webp"
import Ajit_Kumar_Img from "../src/images/ADA/Ajit_Kumar.webp"
import Avijoy_Saha_Img from "../src/images/ADA/Avijoy_Saha.webp"
import Dayalamurthy_Iyyappan_Img from "../src/images/ADA/Dayalamurthy_Iyyappan.webp"
import Anup_Pandey_Img from "../src/images/ADA/Anup_Pandey.webp"
import SanjeevSugur from '../src/images/ADA/48138.webp';

export const testimonialsData = [
  {
    img: SanjeevSugur,
    title: "",
    name: "Sanjeev Sugur",
    sub_name: "Head - HRBP",
    desc: `"Adecco has reinforced my sense of purpose, as we wholeheartedly embrace the mission of creating abundant work opportunities for job seekers and thereby making life better for many, a reality. The core values instilled and experience gained during my stint in the Indian Army are highly valued and respected within Adecco. The inclusive and supportive work environment at Adecco inspires me to consistently bring in my utmost potential, fostering a culture of excellence every single day."`,
  },
  {
    img: Julie_Rosy_Img,
    title: "Wing Commander Julie Rosy (Veteran) joined Adecco on 17 Jan 22",
    name: "Julie Rosy",
    sub_name: "Senior Manager Process Excellence",
    desc: `“After serving in the IAF for 22 years I joined Adecco in Jan 2022. I am mighty impressed by the culture and diversity of Adecco. Here women not just make more than 50% of workforce but are holding top positions at the management level as well. Adecco offers an ecosystem which is not just motivating and inclusive but also very happy and friendly. It is like a family where each member is respected and taken care of.”`,
  },
  {
    img: Ajit_Kumar_Img,
    title: "Group Captain Ajit Kumar (Veteran) joined Adecco on 19 May 22",
    name: "Ajith Kumar",
    sub_name: "National Project Manager, Outsourcing",
    desc: `“I served in the aeronautical engineering branch of the IAF for 22 years and joined Adecco in May 2022, where colleagues are always available to support you during your transition. Adecco provides me with the relevant platform to “Making the future work for everyone” and endless opportunities to excel every day.”`,
  },
  {
    img: Avijoy_Saha_Img,
    title: "Wing Commander Avijoy Saha (Veteran) Joined Adecco on 21 Jul 22",
    sub_name: "Sr Project Manager General Staffing",
    name: "Avijoy Saha ",
    desc: `“I joined Adecco after serving for 20 years in the Indian Air Force and after a short stint in an Indian start-up company. I am captivated by the culture, values, creative opportunities, openness to new ideas and ability to build innovative solutions to complex problems. Adecco provides a joyful work environment while you are aligned to the purpose of creating equal opportunities for candidates, associates, clients and customers.”`,
  },
  {
    img: Dayalamurthy_Iyyappan_Img,
    title:
      "Master Chief Petty Officer Dayalamurthy Iyyappan (Veteran) joined Adecco on 12 Sep 22",
    name: "Dayalamurthy Iyyappan",
    sub_name: "Sr Practice Lead, General Staffing - Diversified Services",
    desc: `“The discipline I learned in the Indian Navy has served me well in my post-Naval career. Leadership, a strong work ethic, teamwork, a sense of urgency, attention to detail, and a commitment to excellence are all traits that I have transferred from my military service to my current role at Adecco. I have always wanted to work with the corporate world post-naval service. With Adecco, I am provided with ample opportunities to learn, grow and excel. I was fortunate to work with good leaders and colleagues who helped me get a soft landing into the corporate world.”`,
  },
  {
    img: Anup_Pandey_Img,
    title: "Sergeant Anup Kumar Pandey (Veteran) joined Adecco on 18 Apr 22 ",
    name: `Anup Kumar Pandey`,
    sub_name: "Senior Account Manager, GS Perm – Permanent Placement",
    desc: `"I joined Adecco after a remarkable journey of serving for 20 years in the Indian Air Force. The next stepping stone of my career journey started with Adecco, a global fortune 500 company with a footprint across the world. I am amazed by the immense growth and career opportunity that Adecco provides - apart from the motivating work culture and leadership, it has given me a platform to learn and engage with people from each business. People support people and grow together, living up to the vision of "Making the future work for everyone.”`,
  },
]
