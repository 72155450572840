import award from "../src/images/awardImage.webp";
import epfo from "../src/images/Awards/EPFO.webp";
import Welfare from "../src/images/Awards/Welfare.webp";
import divhersity from "../src/images/Awards/divhersity.webp";







const AwardRecognitionData = [
  {
    img: Welfare,
    title: "Welfare of veterans is the collective responsibility of the entire nation.",
    content:
      "Adecco India is honoured to be recognized by the Directorate General Resettlement (DGR) for our unwavering commitment to the resettlement of ex-servicemen. Defence Minister Rajnath Singh felicitated the winners at the Armed Forces Flag Day Fund CSR Conclave.",
  },
  {
    img: epfo,
    title: "EPFO award for achieving the highest e-nomination filed by female employees",
    content:
      `Recognized by the Employee's Provident Fund Organization (EPFO) for leading the way with the highest e-nominations filed by female employees and for championing diversity and inclusion. We collaborate with employers on programs and pathways that unlock opportunities for diverse talent, promote equal access to career advancement, and foster a more inclusive work environment. These collaborations, along with other strategic partnerships, are a testament to our unwavering commitment to building a workforce that reflects the power of India's human capital.`,
  },
  {
    img: divhersity,
    title: "2022 Winners of the DivHERsity Awards",
    content:
      "Awarded for commitment and excellence of Indian companies in increasing female participation in the workforce and ability to challenge the impossible to help achieve diversity goals.",
  },

]

export default AwardRecognitionData
