import React from "react"
import { PageContentSubmitCvScreen } from "../components/PageContent/PageContent"
import { Banner } from "../components/submitYourCv/banner"
import HardWork from "../components/submitYourCv/hardWork"
import Location from "../components/submitYourCv/location"
import Service from "../components/submitYourCv/service"

const SubmitYourCv = props => {
  return (
    <PageContentSubmitCvScreen location={props.location}>
      <Banner title={'Submit Your CV'}/>
      <HardWork />
      <Location />
      <Service />
    </PageContentSubmitCvScreen>
  )
}

export default SubmitYourCv
