import React from "react";
import { Col, Row } from "antd";
import { weCareData } from "../../../services/referAFriend";
import "./common.less";

const WeCare = () => {
  return (
    <section className="weCareSection px-3 px-md-6">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="careData">
            {weCareData.map(m => (
              <div className="data" key={m.heading}>
                <img loading="lazy"  src={m.img} alt={m.heading} />
                <h4>{m.heading}</h4>
                <p>{m.desc}</p>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default WeCare
