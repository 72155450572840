import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "antd";
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent";
import { NewBanner } from "../../../components/Solutions/newBanner";
import BreadcrumbSec from "./Breadcrumb";
import Sidebar from "./sidebar";
import FasterImg from "../../../images/solutions/HTD/Faster-resource-Identification.webp";
import AvailabilityImg from "../../../images/solutions/HTD/Availability-of-project-ready.webp";
import ReducedImg from "../../../images/solutions/HTD/Reduced-Hiring-Expenses.webp";
import costImg from "../../../images/solutions/HTD/cost-effective-skilled-talent.webp";
import LowImg from "../../../images/solutions/HTD/Low-Liability.webp";
import HigherImg from "../../../images/solutions/HTD/Higher-Employee-Utilization.webp";
import LongImg from "../../../images/solutions/HTD/Long-term-sustainability.webp";
import banImg from "../../../images/solutions/banner/Hire-Train-Deploy.webp";
import "./common.less";
import Stories from "../../../components/employerHomePage/stories";

const HireTrainDeploy = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <NewBanner BannerImg={banImg} title={"Hire Train Deploy"} />
      <BreadcrumbSec current={"Hire Train Deploy"} />

      <section className="mainSolutionSection px-3 px-md-6">
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="float-right">
            <div className="box">
              <div className="heading">
                <h1>Hire Train Deploy</h1>
                <span className="separator"></span>
              </div>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">
                  A workforce solution that is tailored to your needs
                </h2>
              </div>
              <p className={""}>
                Globally unemployment is a huge problem and yet employers
                complain they cannot find the necessary skills for their
                business needs. Lack of experience is one of the main barriers,
                and India is no exception. Employers are under pressure to keep
                up with the growing talent demand — but all too often, the
                available talent lacks the technical training or soft skills
                employers need now.{" "}
              </p>
              <p className={"text-danger text-bold"}>
                Our Hire Train Deploy program is a high-touch model that
                addresses skill shortages for our clients and helps people from
                all walks of life scale up and position themselves for success.
              </p>
              <p>
                We develop tailor-made solutions to hire, train and deploy
                individuals – tackling youth unemployment and skills shortages,
                and empowering young people with the soft skills and technical
                know-how necessary to gain entry with leading businesses. Our
                Hire Train Deploy model aims to provide value to job aspirants,
                empowering young people with the skills and knowledge necessary
                to meet current demand. In the process, we empower our clients
                to calibrate their talent needs and organizational models to
                achieve their workforce management goals.
              </p>
              <div className="solution-icons">
                <div className="icon-sec justify-space-around">
                  <div className="icon">
                    <img loading="lazy"  src={FasterImg} alt="FasterImg" />
                    <h3>Faster resource identification and deployment </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={AvailabilityImg} alt="AvailabilityImg" />
                    <h3>Availability of project-ready talent</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={ReducedImg} alt="ReducedImg" />
                    <h3>Reduced hiring expenses </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={costImg} alt="costImg" />
                    <h3>Cost-effective skilled talent </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={LowImg} alt="LowImg" />
                    <h3>Low liability over nonperforming associates </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={HigherImg} alt="HigherImg" />
                    <h3>Higher employee utilization </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={LongImg} alt="LongImg" />
                    <h3>Long-term sustainability </h3>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">Program Models</h2>
              </div>
              <p>
                <b>Graduate Hire Train Deploy solution:</b> Skill set training
                for fresh graduates out of college
              </p>
              <ul className={"pl-7"}>
                <li>Generic skills in high volume </li>
                <li>Identify the right resource pool </li>
                <li>Theoretical training with practical experience</li>
              </ul>
              <p>
                <b>Regular Hire Train Deploy solution:</b> General skill
                training for vanilla skills across the industry to improve
                employability{" "}
              </p>
              <ul className={"pl-7"}>
                <li>Repetitive Skills </li>
                <li>Recurring in training in batches</li>
                <li>On the Job Training with practical assignments</li>
              </ul>
              <p>
                <b>Custom Hire Train Deploy solution:</b> Specific skill set
                training for small batches as per customer requirement{" "}
              </p>
              <ul className={"pl-7"}>
                <li>Niche & super niche skills </li>
                <li>Headhunting </li>
                <li>Training content analysis & certifications </li>
              </ul>
              <p>
                We work hand in hand with businesses to provide tailored hire,
                train and deploy solutions, built on a deep understanding of
                their needs and challenges and a shared commitment to
                responsible business conduct. Our solutions have a proven track
                record of success in supporting client organizations and their
                employees through changes that require bias-free and fully
                inclusive hiring, talent management and development, and
                deployment. We continue to invest time and resources in the best
                technology to create the environments that deliver this
                seamlessly.
              </p>
              <div className="text-center">
                <Link
                  to="/employer/business-enquiry/"
                  state={{ interested: "Hire Train Deploy" }}
                >
                  Business Enquiry
                </Link>
              </div>
            </div>
          </Col>
          <Sidebar />
        </Row>
        <Stories heading="Case Study" isReadMore={false} />
      </section>
    </PageContentEmployerScreen>
  )
}

export default HireTrainDeploy
