import React from "react";
import { Button, Col, Row } from "antd";
import bannerImg from "../../images/jobSeekerHome/slider/banner1.webp";
import bannerImg1 from "../../images/jobSeekerHome/slider/banner2.webp";
import bannerImg3 from "../../images/jobSeekerHome/slider/banner3.webp";
import bannerImg4 from "../../images/jobSeekerHome/slider/banner4.webp";
import Arrow from "../../images/sliderArrow.webp";
import "./common.less";

import SliderTestimonial from "react-slick"
import { Link } from "gatsby"

const Banner = () => {
  function NextArrow(props) {
    const { onClick } = props

    return (
      <div
        className="nextArrow"
        onClick={() => onClick()}
      >
        <img loading="lazy"  src={Arrow}  alt={'nextArrow'}/>
      </div>
    )
  }

  function PrevArrow(props) {
    const { onClick } = props
    return (
      <div className="prevArrow" onClick={onClick}>
        <img loading="lazy"  src={Arrow}  alt='prevArrow'/>
      </div>
    )
  }
  const SliderItems=({className,title,desc,btn1_title,btn1_Link,btn2_title,btn2_Link,img})=>{
    return(
      <div className="items">
      <Row className={`employerBannerSection  ${className} px-3 px-md-6`}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className="bannerData">
            <h2 dangerouslySetInnerHTML={{__html :title}}>
            </h2>
            <p dangerouslySetInnerHTML={{__html :desc}}></p>
            <div className="bannerButton">
              <Button type="danger" size="large">
                <Link to={btn1_Link}>{btn1_title}</Link>
              </Button>
              <Button type="danger" size="large">
                <Link to={btn2_Link}>
                  {btn2_title}
                </Link>
              </Button>
            </div>
          </div>
        </Col>
        <div
          className="bannerImg"
          style={{ backgroundImage: `url(${img})` }}
        ></div>
      </Row>
    </div>
    )
  }
  const testimonialData = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  return (
    <SliderTestimonial
      {...testimonialData}
      className="emp-slider"
      adaptiveHeight={true}
    >
      <SliderItems
       className={'data-3'}
       title={`Tackle tomorrow's workforce<span>challenges today</span>`}
       desc={`Are you looking for permanent or temporary staff?`}
       btn1_Link={'/employer/business-enquiry/'}
       btn1_title={'Business Enquiry'}
       btn2_Link={'/employer/solutions/hr-advisory-and-consulting-service/'}
       btn2_title={'Solutions'}
       img={bannerImg3}
       />
       <SliderItems
       className={'data-1'}
       title={`Delivering the right workforce <span>solutions, right on time</span>`}
       desc={`Tap into the power of our people.`}
       btn1_Link={'/employer/business-enquiry/'}
       btn1_title={'Business Enquiry'}
       btn2_Link={'/employer/solutions/hr-advisory-and-consulting-service/'}
       btn2_title={'Solutions'}
       img={bannerImg}
       />
       <SliderItems
       className={'data-2'}
       title={`Market leader in technology talent recruitment`}
       desc={` Our passion for technology and talent helps in securing the right skills needed for your success.`}
       btn1_Link={'/employer/business-enquiry/'}
       btn1_title={'Business Enquiry'}
       btn2_Link={'/employer/solutions/hr-advisory-and-consulting-service/'}
       btn2_title={'Solutions'}
       img={bannerImg4}
       />
        <SliderItems
       className={'data-2'}
       title={`Speak to an expert`}
       desc={`Connect with our team to find out how we can help you.`}
       btn1_Link={'/employer/business-enquiry/'}
       btn1_title={'Business Enquiry'}
       btn2_Link={'/employer/solutions/hr-advisory-and-consulting-service/'}
       btn2_title={'Solutions'}
       img={bannerImg1}
       />
    </SliderTestimonial>
  )
}

export default Banner
