import React from "react"
import { Card, Col, Row } from "antd"
import { Link } from "gatsby"
import moment from "moment"

const MediaCardLayout = (props: any) => {
  let styles: any = {
    height: "100%",
  }

  return (
    <Col xs={24} xl={8} lg={8} md={12} sm={12} key={props.key}>
      <Link to={props.data.link} target={"_blank"}>
        <Card
          className="card-hover pressReleaseBox media-coveage-card cardPadding"
          key={props.data.link}
          hoverable
          // cover={
          //   <img loading="lazy" 
          //     alt="example"
          //     src={props.data.img}
          //   />
          // }
        >
          <Row style={styles}>
            <Row type="flex" className="text-align-center">
              <h2 className="font-large fw-600 pb-1 press-title text-left">
                {props.data.title}
              </h2>
              <span className="font-xxsmall pb-1 w-100 c-secondary text-align-center">
                {moment(props.data.date, "yyy-MM-DD").format("LL")}
              </span>
              <p className="font-small  card-desc">{props.data.description}</p>
            </Row>
          </Row>
          <Row type="flex" justify="end">
            <Link to={props.data.link} target={"_blank"}>
              read more
            </Link>
          </Row>
        </Card>
        {/* </Col> */}
      </Link>
    </Col>
  )
}

export default MediaCardLayout
