import img1 from "../src/images/ReferAFriend/Our-strong-purpose.webp"
import img2 from "../src/images/ReferAFriend/Employer-of-choice.webp"
import img3 from "../src/images/ReferAFriend/Trusted-partner-to-clients.webp"

export const weCareData = [
  {
    img: img1,
    heading: "Our strong purpose",
    desc: `As one of the world’s leading HR services providers, our vision is of a world in which everyone has the opportunity to access and remain in employment – regardless of where they started, what has happened to them, or where they are right now. We’re thinking about the future at work and our unrelenting focus is on how we can make the future work for everyone. Thus enabling sustainability and lifelong employability of individuals with innovation, agility, and flexibility advancing the employability and employment of the individuals we serve.`,
  },
  {
    img: img2,
    heading: "Employer of choice",
    desc: `We offer a positive, collaborative work culture that allows for flexibility and a healthy work-life balance. We place associates with organizations on a temporary basis managing the entire recruitment process from candidate search and screening, through onboarding and training, to payroll and administration. On the other hand, we also source candidates, screen CVs, conduct interviews and assessments, and advise hiring managers for permanent placement.`,
  },
  {
    img: img3,
    heading: "Trusted partner to clients",
    desc: `We aim to be our client's trusted partner, building on a deep understanding of their talent requirements and offering highly customized services. We support them in their journey to building more diverse and inclusive talent pools by providing them with the skills and expertise to help them transform their talent ecosystem towards more progressive, sustainable models. Our shared commitment to conducting business responsibly ensures be we are truly a strategic partner to them.`,
  },
]

export const formSectionData = {
  bannerDesc: `At Adecco, we care. And we put people first.
    It’s what helps us drive our ongoing success as a leading provider of HR solutions in India.`,
  desc: [
    `Do you know someone who would belong in an engaging, people-oriented and empowering workplace? Introduce them to us, for we love to meet passionate, bright and dynamic people!`,
    `There couldn’t be a better time to introduce your friends and family who are looking for a job to us. And right now, we have several positions open for talented individuals to join our teams across India. Lets work together.`,
  ],
}
