import React from "react";
import { Col, Row } from "antd";
import { Link } from "gatsby";
import Sidebar from "./sidebar";
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent";
import { NewBanner } from "../../../components/Solutions/newBanner";
import BreadcrumbSec from "./Breadcrumb";
import EngagementImg from "../../../images/solutions/TrainingAndSkilling/Engagement.webp";
import FutureLeadersImg from "../../../images/solutions/TrainingAndSkilling/Future-Leaders.webp";
import ProductivityImg from "../../../images/solutions/TrainingAndSkilling/Productivity.webp";
import RetentionImg from "../../../images/solutions/TrainingAndSkilling/Retention.webp";
import banImg from "../../../images/solutions/banner/Training-And-Skilling.webp";
import Stories from "../../../components/employerHomePage/stories";

const TrainingAndSkilling = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <NewBanner BannerImg={banImg} title={"Training And Skilling"} />
      <BreadcrumbSec current={"Training And Skilling"} />

      <section className="mainSolutionSection px-3 px-md-6">
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="float-right">
            <div className="box">
              <div className="heading">
                <h1>Training And Skilling </h1>
                <span className="separator"></span>
              </div>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">
                  Reinvent your workforce and increase the employability and
                  effectiveness of your talent
                </h2>
              </div>
              <p className={""}>
                The growth of your business and the performance of your company
                are closely linked to the way you train and recruit your
                employees. Our global reach and expertise give us unparalleled
                insight into the trends shaping the future of work and how
                companies are rethinking rebuilding and optimizing their skill
                development programs. With an ever-widening skill gap and
                intense battle for the right talent, redundancies and severance
                are becoming a huge challenge for employers to battle. We
                support our clients to make human capital their first
                competitive advantage. We work alongside you to increase the
                employability quotient, competency, and attractiveness of your
                talent ecosystem. Our ambition is to be the leading integrator
                of training and skilling to cater to your specific
                organizational requirements.{" "}
              </p>
              <p className={"text-danger text-bold"}>
                To stay relevant in a labour market that is constantly evolving
                and will continue to be disrupted by technology, workers must
                continuously develop their capabilities. Our value proposition
                for candidates, associates and clients goes beyond providing
                employment opportunities or consecutive assignments.
              </p>
              <p>
                We provide training and career coaching and help solve skills
                shortages with our upskilling and re-skilling solutions which
                improve access to diverse candidates, including some of the most
                in-demand fields such as digital and IT skills. Our high
                specialization approach allows us to support our stakeholders
                through the entire delivery phases of training and skilling.
              </p>
              <p>
                Investment in internal training can help tackle these issues,
                but companies often do not prioritize such initiatives owing to
                cost, time, unclear return on investment, and the risk that
                employees will leave. Employee training and skilling are easier
                said than done, but it should be done—for both new and old
                employees alike.{" "}
              </p>

              <div className="d-flex align-items-center pl-6">
                <h2 className="p-0 m-0">
                  Here are some key benefits to your business, leading to
                  better:
                </h2>
              </div>
              <div className="solution-icons">
                <div className="icon-sec justify-space-evenly">
                  <div className="icon">
                    <img loading="lazy"  src={EngagementImg} alt="EngagementImg IMG" />
                    <h3>Engagement</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={RetentionImg} alt="RetentionImgIMG" />
                    <h3>Retention</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={FutureLeadersImg} alt="FutureLeadersImg IMG" />
                    <h3>Future Leaders</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={ProductivityImg} alt="ProductivityImg" />
                    <h3>Productivity</h3>
                  </div>
                </div>
              </div>

              <div className="heading">
                <h2>Our key differentiators </h2>
              </div>
              <p>
                LEARNER CENTRIC: We focus on the learner’s experience, which
                must be personalized, inclusive, and employability-focused. {" "}
              </p>
              <p>
                INDUSTRY-SPECIFIC EXPERTISE: We leverage our talent market data
                (global/national/local) to identify and build the skills in
                demand.{" "}
              </p>
              <p>
                EMPLOYABILITY: We focus on raising employability by providing
                the future-proof skills needed by the talent market. For us,
                training is a key enabler to reaching sustainable growth.{" "}
              </p>
              <p>
                CO-CONSTRUCTION: We act as a trusted partner and co-design
                training solutions with clients and other stakeholders to ensure
                the best adequation with their specific needs and culture.
              </p>
              <p>
                END-TO-END SOLUTIONS: We provide a « 1-stop solution », from
                needs assessment, assessment batteries for individuals, training
                design & delivery, to assessing the impact on business.{" "}
              </p>
              <Row gutter={[12, 12]}>
                <Col md={12}>
                  <div className="bg-red">
                    <p>
                      Build in-demand skills to reinforce employability and
                      enlarge career opportunities
                    </p>
                  </div>
                  <div className="btn">
                    <p>Enabled Adecco Training</p>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="bg-red">
                    <p>
                      Support organizations in building the skills they need to
                      sustain their economic and social performance
                    </p>
                  </div>
                  <div className="btn">
                    <p>Enabled Adecco Alliance</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="btn-long ">
                    <p className="p-0 m-0">SOCIETY</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="btn-dark mb-5">
                    <p className="p-0 m-0">
                      Contribute to reduce the skills gap and talent shortage,{" "}
                      <br />
                      lower the unemployment rate, enable career shift for
                      satisfaction & growth...{" "}
                    </p>
                  </div>
                </Col>
              </Row>

              <div className="text-center">
                <Link
                  to="/employer/business-enquiry/"
                  state={{ interested: "Training And Skilling" }}
                >
                  Business Enquiry
                </Link>
              </div>
            </div>
          </Col>
          <Sidebar />
        </Row>
        <Stories heading="Case Study" isReadMore={false} />
      </section>
    </PageContentEmployerScreen>
  )
}

export default TrainingAndSkilling
