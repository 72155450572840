import React from "react";
import { navigate } from "gatsby";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  Spin,
  ConfigProvider,
  message,
  Row,
  Col,
  AutoComplete,
  Radio,
} from "antd";
import Axios from "axios";
import { solutions } from "../../../services/EmployerHeaderData";
import {
  characterValidation,
  countryCodeData,
  emailValidation,
  phoneNumberValidation,
  validationRegex,
} from "./FormUtils";
import { request } from "../../helper/dimensionHelper"
import { text } from "../../constants"
import ModalComponent from "../ModalComponent/Modal"
import { TermsConditionData } from "../../../services/termsAndConditionData"
import { APIContext } from "../../helper/APIContex"
import CommonInput from "./commonInput"
import CommonSelect from "./commonSelect";
import gpSIcon from "../../images/gps.svg"

const { Option } = Select

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

function BusinessEnquiryF(props: {
  getInterestedArea: (value: string) => void
  form
  showInterestedInValue: string
  pageSource: string
}) {
  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
  } = props.form

  const { data, actions } = React.useContext(APIContext)
  const { jobsbyCityData, locationData } = data

  const [fromData, setFormData] = React.useState({})
  const [agreement, setAgreement] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [country, setCountry] = React.useState("India:+91")
  const [modalVisibility, setModalVisibility] = React.useState()
  const [othersCountryCode, setOthersCountryCode] = React.useState(false)
  const [industryData, setIndustryData] = React.useState([])
  const [interestedArea, setInterestedArea] = React.useState(
    props.showInterestedInValue !== "" ? props.showInterestedInValue : undefined
  )
  const [othersIndustry, setOthersIndustry] = React.useState(false)

  React.useEffect(() => {
    props.form.validateFields()

    getLocationsAndIndustries()
  }, [])

  const getLocationsAndIndustries = async () => {
    setLoading(true)
    await request("GET", "JobSite/IndustryType")
      .then(res => {
        if (res.data) {
          const indysData = res.data.map(data => ({
            industryCode: data.industryCode,
            industryDescription: data.industryDescription,
          }))
          setIndustryData(indysData)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  const changeInterestedArea = value => {
    setInterestedArea(value)
    props.getInterestedArea(value)
  }

  const handleCountry = value => {
    if (value === "others_country") {
      setOthersCountryCode(true)
    } else {
      setOthersCountryCode(false)
    }
    setCountry(value)
  }
  const handleIndustry = value => {
    if (value === "others_industry") {
      setOthersIndustry(true)
    } else {
      setOthersIndustry(false)
    }
  }

  const onChange = (e: any) => {
    let tempData = { [e.target.name]: e.target.value }
    setFormData(prevState => ({
      ...prevState,
      ...tempData,
    }))
  }
  const onFinish = (e: any) => {
    e.preventDefault()

    props.form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err) {
        setLoading(true)
        const countryData = values.countryCode.split(":")
        const enquiryType =
          props.pageSource === text.DIGITAL_BUSINESS ? text.DIGITAL : ""
        const apiEndpoint =
          props.pageSource === text.DIGITAL_BUSINESS
            ? "api/SaveBusinessEnquiry"
            : "api/FtpLeadIntegration"

        Axios.post(
          `${process.env.GATSBY_MIDDLEWARE_API}/${apiEndpoint}`,
          JSON.stringify({
            employeeName: values.employeeName,
            organisation: values.organisation,
            designation: values.designation,
            employeeEmail: values.employeeEmail,
            location: values.location,
            interestedArea: values.interestedArea,
            industry: values.industry,
            summary: values.summary,
            social: values.social,
            agreement: values.agreement.toString(),
            phoneNumber: countryData[1] + values.phoneNumber,
            country: countryData[0],
            businessEnquiryType: enquiryType,
          })
        )
          .then(res => {
            message.success("The form has been successfully submitted.")
            props.form.resetFields()
            navigate("/employer/thank-you")
          })
          .catch(err => {
            message.error("We encountered an error while submitting the form.")
          })
          .finally(() => setLoading(false))
      } else {
        message.error("We encountered an error while submitting the form.")
      }
    })
  }

  const handleModalView = () => {
    setModalVisibility(true)
  }

  const changeModalView = (value: boolean) => {
    setModalVisibility(value)
  }

  const checkCheckBox = (rule, value, callback) => {
    if (!value) {
      setAgreement(false)
      callback("Please agree the terms and conditions!")
    } else {
      setAgreement(true)
      callback()
    }
  }

  const employeeNameError =
    isFieldTouched("employeeName") && getFieldError("employeeName")
  const organisationError =
    isFieldTouched("organisation") && getFieldError("organisation")
  const designationError =
    isFieldTouched("designation") && getFieldError("designation")
  const emailError =
    isFieldTouched("employeeEmail") && getFieldError("employeeEmail")
  const countryError =
    isFieldTouched("countryCode") && getFieldError("countryCode")
  const phoneNumberError =
    isFieldTouched("phoneNumber") && getFieldError("phoneNumber")
  const interestedAreaError =
    isFieldTouched("interestedArea") && getFieldError("interestedArea")
  const industryError = isFieldTouched("industry") && getFieldError("industry")
  const locationError = isFieldTouched("location") && getFieldError("location")
  const summaryError = isFieldTouched("summary") && getFieldError("summary")

  const geData = jobsbyCityData.find(
    data => data.locationDescription === "Other"
  )
  const lData = jobsbyCityData
    ?.sort((a: any, b: any) => {
      if (a.locationDescription < b.locationDescription) return -1
      if (a.locationDescription > b.locationDescription) return 1
      return 0
    })
    .filter(data => data.locationDescription !== "Other")
  lData.push(geData)
  return (
    <>
      <Form name="basic" onSubmit={onFinish} autoComplete="off">
        <div className="form-content overflow-auto pt-2 h-auto h-lg-10">
          <CommonInput 
          getFieldDecorator={getFieldDecorator}
            validateStatus={employeeNameError ? "error" : ""}
            help={employeeNameError || ""}
            label={null}
            name={'employeeName'}
            rules={[
              {
                required: true,
                message: "Please enter your name",
              },
              {
                pattern: characterValidation,
                message: "Please enter valid characters!",
              },
            ]}
            placeholder ={'Name *'}
            inputIcon={null}/>
            <CommonInput 
              getFieldDecorator={getFieldDecorator}
              validateStatus={organisationError ? "error" : ""}
              help={organisationError || ""}
              label={null}
              name={'organisation'}
              rules={[
                {
                  required: true,
                  message: "Please enter your company name",
                },
                {
                  pattern: validationRegex,
                  message: "Please enter valid characters!",
                },
              ]}
              placeholder ={'Company / organisation *'}
              inputIcon={null}/>
            <CommonInput 
              getFieldDecorator={getFieldDecorator}
              validateStatus={designationError ? "error" : ""}
              help={designationError || ""}
              label={null}
              name={'designation'}
              rules={[
                {
                  required: true,
                  message: "Please enter your designation",
                },
                {
                  pattern: characterValidation,
                  message: "Please enter valid characters!",
                },
              ]}
              placeholder ={'Designation *'}
              inputIcon={null}/>
            <CommonInput 
              getFieldDecorator={getFieldDecorator}
              validateStatus={emailError ? "error" : ""}
              help={emailError || ""}
              label={null}
              name={'employeeEmail'}
              rules={[
                {
                  pattern: emailValidation,
                  message: "Please enter a valid Email!",
                },
                {
                  required: true,
                  message: "Please enter your Email!",
                },
              ]}
              placeholder ={'Email *'}
              inputIcon={null}/>

          <Row type="flex">
            <Col span={20} lg={10}>
            <CommonSelect
              getFieldDecorator={getFieldDecorator}
              validateStatus={countryError ? "error" : ""}
              help={countryError || ""}
              rules={[
                {
                  required: true,
                  message: "Please select your country code",
                },
              ]}
              name="countryCode"
              placeholder={"Select Country*"}
              onchange={value => handleCountry(value)}
              loading={loading}
              initialValue={"India:+91"}
              options={countryCodeData
                .sort((a: any, b: any) => {
                  if (a.country < b.country) return -1
                  if (a.country > b.country) return 1
                  return 0
                })
                ?.map((data, index) => {
                  return (
                    <Option
                      key={index}
                      value={data.country + ":" + data.countryCode}
                    >
                      {data.country + " : " + data.countryCode}
                    </Option>
                  )
                })}
              label={null}
              other={<Option value={"others_country"}>Others</Option>}
            />
            </Col>
            <Col span={20} lg={14}>
            <CommonInput
              getFieldDecorator={getFieldDecorator}
              validateStatus={phoneNumberError ? "error" : ""}
              help={phoneNumberError || ""}
              name={"phoneNumber"}
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number",
                },
                {
                  pattern: phoneNumberValidation,
                  message: "Please enter valid digits!",
                },
                { min: 10, message: "Please enter valid digits!" },
                { max: 10, message: "Please enter valid digits!" },
              ]}
              placeholder={"Phone Number *"}
              inputIcon={null}
              label={null}
            />
            </Col>
          </Row>
          {othersCountryCode && (
            <CommonInput
            getFieldDecorator={getFieldDecorator}
            validateStatus={""}
            help={""}
            name={"others_country"}
            onChange={e => onChange(e)}
            rules={[
              { required: true, message: "Please input Other Country Code" }
            ]}
            placeholder={"Other Country Code *"}
            inputIcon={null}
            label={null}
          />
          )}
          <Form.Item
            className="px-1"
            name="location"
            validateStatus={locationError ? "error" : ""}
            help={locationError || ""}
          >
            <ConfigProvider>
              {getFieldDecorator("location", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    required: true,
                    message: "Please select your country Location",
                  },
                ],
              })(
                <AutoComplete
                  notFoundContent={loading ? <Spin /> : "No matches"}
                  dataSource={
                    locationData
                      ? locationData?.map(data => data.locationDescription)
                      : []
                  }
                  size="large"
                  placeholder="Location *"
                  filterOption={(inputValue: any, option: any) =>
                    option?.props?.children
                      ?.toUpperCase()
                      ?.indexOf(inputValue?.toUpperCase()) !== -1
                  }
                >
                  <Input size="large"
                    suffix={<img loading="lazy"  src={gpSIcon} style={{ width: "70%" }} alt={'icon'}/>}
                  />
                </AutoComplete>
              )}
            </ConfigProvider>
          </Form.Item>
            <CommonSelect
              getFieldDecorator={getFieldDecorator}
              validateStatus={interestedAreaError ? "error" : ""}
              help={interestedAreaError || ""}
              rules={[
                {
                  required: true,
                  message: "Please enter your interested area",
                },
              ]}
              name="interestedArea"
              placeholder={"Interested in *"}
              onchange={()=>{}}
              loading={loading}
              initialValue={null}
              options={solutions?.map(data =>
                data.sub_menu ? (
                  data.sub_menu.map(sub => (
                    <Option key={sub.data} value={sub.data}>
                      {sub.data}
                    </Option>
                  ))
                ) : (
                  <Option key={data.data} value={data.data}>
                    {data.data}
                  </Option>
                )
              )}
              label={null}
              other={null}
            />
            <CommonSelect
              getFieldDecorator={getFieldDecorator}
              validateStatus={industryError ? "error" : ""}
              help={industryError || ""}
              rules={[
                {
                    required: true,
                    message: "Please enter your industry",
                  },
              ]}
              name="industry"
              placeholder={"Industry *"}
              onchange={(value: string) => handleIndustry(value)}
              loading={loading}
              initialValue={null}
              options={industryData
                        .sort((a: any, b: any) => {
                          if (a.industryDescription < b.industryDescription)
                            return -1
                          if (a.industryDescription > b.industryDescription)
                            return 1
                          return 0
                        })
                        ?.map(data => (
                          <Option
                            key={data.industryCode}
                            value={data.industryDescription}
                          >
                            {data.industryDescription}
                          </Option>
                        ))}
              label={null}
              other={!loading && industryData?.length > 0 && (
                    <Option value={"others_industry"}>Others</Option>
                  )}
            />
          {othersIndustry && (
            <Form.Item className="mb-2 mr-0 mr-md-2">
              {getFieldDecorator("others_industry", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    required: true,
                    message: "Please enter Others Industry",
                  },
                ],
              })(
                <Input
                  size="large"
                  placeholder=" Others Industry *"
                  suffix={<img loading="lazy"  src={gpSIcon} style={{ width: "70%" }} alt={'icon'} />}
                />
              )}
            </Form.Item>
          )}

          <Form.Item
            className="px-1"
            onChange={e => onChange(e)}
            validateStatus={summaryError ? "error" : ""}
            help={summaryError || ""}
          >
            {getFieldDecorator("summary", {
              validateTrigger: "onBlur",
              rules: [
                {
                  required: true,
                  message: "Please enter the summary",
                },
                { min: 10, message: "Minimum 10 characters are required" },
                { max: 200, message: "Character limit exceeded" },
              ],
            })(
              <Input.TextArea
                name="summary"
                rows={4}
                placeholder="Summary of requirement (200 characters max) *"
              />
            )}
          </Form.Item>
          <div className="c-secondary lead">How did you find us? *</div>
          <Form.Item className="px-1">
            {getFieldDecorator("social", {
              rules: [
                {
                  required: true,
                  message: "Please enter where you found us",
                },
              ],
            })(
              <Radio.Group className="w-100 ">
                <Row type="flex" justify="space-between">
                  <Radio value="Facebook" className="c-secondary font-normal">
                    Facebook
                  </Radio>
                  <Radio value="Twitter" className="c-secondary font-normal ">
                    Twitter
                  </Radio>
                  <Radio value="LinkedIn" className="c-secondary font-normal">
                    LinkedIn
                  </Radio>
                  <Radio value="Others" className="c-secondary font-normal">
                    Others
                  </Radio>
                </Row>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item
            className=""
            name="remember"
            valuePropName="checked"
            wrapperCol={{ span: 20 }}
          >
            {getFieldDecorator("agreement", {
              valuePropName: "checked",
              initialValue: "true",
              rules: [{ validator: checkCheckBox }],
            })(
              <div>
                <Checkbox className="c-secondary pr-2" defaultChecked={true}>
                  I agree to the{" "}
                </Checkbox>
                <a>
                  <small className="c-info" onClick={handleModalView}>
                    Terms and conditions
                  </small>
                </a>
                {modalVisibility && (
                  <ModalComponent
                    title="Terms and condition"
                    isVisible={modalVisibility as boolean}
                    getValueModal={changeModalView}
                    modalData={TermsConditionData()}
                  />
                )}
              </div>
            )}
          </Form.Item>
        </div>
        <Form.Item className="mediaFormButton" wrapperCol={{ span: 16 }}>
          {loading ? (
            <Button className="text-center" loading={loading} disabled={true}>
              Submit
            </Button>
          ) : (
            <Button
              className="text-center"
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  )
}

export const WrappedBusinessEnquiryF = Form.create({
  name: "business-inquiry",
})(BusinessEnquiryF)
