import React from "react";
import { Link } from "gatsby";
import { Col } from "antd";
import { solutions } from "../../../../services/EmployerHeaderData";
import "./common.less";
import { SolutionsVideo } from "../../../../services/MediaData";
import VideosCardSolution from "../../../components/videos/videosCardSolution";


const Sidebar = ({title}) => {
  return (
    <Col xs={24} sm={24} md={8} lg={8} xl={8} className="float-right">
      <div className="sidebar">
        <div className={`mt-5`}>
          <ul>
            {solutions?.map(data =>
              data?.sub_menu ? (
                data?.sub_menu?.map(sub => (
                  <li key={sub.data}>
                    {sub.key === "hr_advisory_consulting_services" ? (
                      <a onClick={() => (window.location.pathname = data.url)}>
                        {sub.data}
                      </a>
                    ) : (
                      <Link to={sub.url}>{sub.data}</Link>
                    )}
                  </li>
                ))
              ) : (
                <li key={data.data}>
                  {data.key === "hr_advisory_consulting_services" ? (
                    <a onClick={() => (window.location.pathname = data.url)}>
                      {data.data}
                    </a>
                  ) : (
                    <Link to={data.url}>{data.data}</Link>
                  )}
                </li>
              )
            )}
          </ul>
        </div>
      </div>
      <div className="sidebar" style={{background:'transparent',padding:'0',marginTop:"15px"}}>
     {title ?<VideosCardSolution data={SolutionsVideo.find(i=>i.title === title)??[]} />:''} 
      </div>
    </Col>
  )
}
export default Sidebar
