import React from "react"
import AdviceDetails from "../components/CareerAdvice/adviceDetails"
import Advice from "../components/JobSeekerHomePage/advice"
import { PageContentSubmitCvScreen } from "../components/PageContent/PageContent"
import "../components/HomePage/HomePageStyle.less"
import "../styles/app.less"

export default function CareerAdvice(props) {
  const { ID } = props.params
  const { slug } = props.pageContext

  return (
    <div className="overflowX-hidden">
    <PageContentSubmitCvScreen location={props.location}>
      <AdviceDetails id={slug} />
      <Advice title={""} slidesToShow={3} />
    </PageContentSubmitCvScreen>
    </div>
  )
}
