import React from "react";
import { Col, Row } from "antd";
import { Link } from "gatsby";
import longRight from "../../images/icons/long-right.webp";
import newsroomImg from "../../images/News-Room.webp";

function NewsRooms() {
  return (
    <>
      <div className="news-rooms-section">
        <Row className="registerSection">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="float-md-left pl-3 pr-3 pl-md-6 pr-md-0 pl-lg-6"
            >
              <div className="data">
                <h3>Newsroom</h3>
                <p>
                  Stay updated with the latest top stories in the world of work
                  – market trends, hiring activity, sectoral analysis,
                  wellbeing, and much more. Learn more about exciting new
                  technology trends that are revolutionizing the HR Services
                  industry and how we are turning them into opportunities for
                  you. To be the eyes and ears in the marketplace and help in
                  making the future truly work for you.
                </p>
                <div className="view">
                  <Link to={"/employer/media-coverage"}>
                    <p>
                      View media coverage{" "}
                      <img loading="lazy"  width={20} className="ml-2" src={longRight} alt="View media coverage" />
                    </p>
                  </Link>
                </div>
              </div>
            </Col>
            <img loading="lazy"  className="ragisterImg image" src={newsroomImg} alt={'ragisterImg'}/>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default NewsRooms
