import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "antd";
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent";
import { NewBanner } from "../../../components/Solutions/newBanner";
import BreadcrumbSec from "./Breadcrumb";
import Sidebar from "./sidebar";
import AttractSourceImg from "../../../images/solutions/RPO/Attract-&-Source.webp";
import ConveredImg from "../../../images/solutions/RPO/Convered.webp";
import EngageImg from "../../../images/solutions/RPO/Engage.webp";
import TrackImg from "../../../images/solutions/RPO/Track.webp";
import ITImg from "../../../images/solutions/RPO/IT.webp";
import RnDImg from "../../../images/solutions/RPO/RnD.webp";
import ManufacturingImg from "../../../images/solutions/RPO/Manufacturing.webp";
import BFSIImg from "../../../images/solutions/RPO/BFSI.webp";
import PharmaceuticalImg from "../../../images/solutions/RPO/Pharmaceutical.webp";
import EngineeringImg from "../../../images/solutions/RPO/Engineering.webp";
import banImg from "../../../images/solutions/banner/RPO-Lite.webp";
import "./common.less";
import Stories from "../../../components/employerHomePage/stories";

const RpoServices = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <NewBanner BannerImg={banImg} title={"RPO Lite"} />
      <BreadcrumbSec current={"RPO Lite"} />
      <section className="mainSolutionSection px-3 px-md-6">
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="float-right">
            <div className="box">
              <div className="heading">
                <h1>RPO Lite</h1>
                <span className="separator"></span>
              </div>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">
                  Strong and differentiated offerings with best-in-class
                  delivery and continuous optimization{" "}
                </h2>
              </div>
              <p className={""}>
                We are on the precipice of change like never before, and
                employers are battling historic technological, demographic, and
                geopolitical shifts. Finding the right talent while grappling
                with these new realities is a big challenge for talent managers.
                Inconsistent recruitment partners can create several challenges
                for organizations not just creating difficulties in finding the
                right candidates, but also leading to disparate talent pools,
                inability to proactively address your needs, insufficient
                transparency of cost as well as poor candidate experience.
              </p>
              <p>
                At Adecco, our tailored solutions will ensure you are able to
                hire people with the right skills at the right time to build the
                teams you need from the start. We are a market leader when it
                comes to nurturing promising talent. Recruitment Process
                Outsourcing solutions is emerging as a strategic response to the
                biggest talent management issues companies face. The need to
                hire specialized individuals with hard-to-find skills, improve
                recruitment efficiency, and increase workforce scalability and
                flexibility, are now some of the main reasons why organizations
                are likely to consider an RPO service, besides many others.{" "}
              </p>
              <p className={"text-danger text-bold"}>
                Your organization can benefit from our end-to-end process
                knowledge deriving value to increase speed and quality while
                maintaining or reducing cost. We understand the ground realities
                of talent pool availability and use innovative ways to tap into
                both local and global talent pools.{" "}
              </p>

              <div className="solution-icons">
                <div className="icon-sec justify-space-around">
                  <div className="icon">
                    <img loading="lazy"  src={AttractSourceImg} alt="AttractSourceImg" />
                    <h3>Attract & Source </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={ConveredImg} width={50} alt="ConveredImg" />
                    <h3>Convert </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={EngageImg} width={50} alt="EngageImg" />
                    <h3>Engage </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={TrackImg} width={50} alt="TrackImg" />
                    <h3>Track </h3>
                  </div>
                </div>
              </div>
              <p>
                RPO Lite is a specialized offering that allows organizations to
                outsource some parts or the entire recruitment process or value
                chain. We operate as the client’s internal recruitment function
                and strategic partner to improve efficiencies of quality of
                hire, speed of delivery, and cost containment. RPO Lite's
                offering includes sourcing, screening, assessment
                administration, interview coordination, selection,
                pre-employment checks & verification, offer administration,
                onboarding, new hire care, and exit interviews.{" "}
              </p>
              <p>
                We engage with the client to work on their recruitment needs,
                using agreed tools & systems. We offer an extensive database of
                available talent pools with a wide range of skills. Our in-house
                technology and industry-specific subject matter experts provide
                data-driven insights. A strong competitive edge is our
                technology-backed approach to talent search, professional
                networking & passive candidate profiling.
              </p>
              <p>
                Depending on the extent of clients’ outsourcing appetite,
                broadly the types of RPO Lite are the following:{" "}
              </p>
              <p>
                <b>Project RPO Lite: </b>Selected projects outsourced to deal
                with seasonal/one-time spikes in volume supported by
                result-oriented, flexible, and scalable methodologies.{" "}
              </p>
              <p>
                <b>Component-Based RPO Lite: </b>The client retains part of the
                recruitment function but outsources select activities to us.{" "}
              </p>
              <p>
                <b>Full RPO Lite: </b>Complete outsourcing of end-to-end
                recruitment processes and functions{" "}
              </p>
              <p>
                <b>High-end RPO Lite: </b>Skilled fulfillment across niche to
                super niche technologies particularly for IT, R&D,
                Manufacturing, BSFI, Pharma and Engineering sector.{" "}
              </p>
              <div className="solution-icons">
                <div className="icon-sec justify-space-evenly">
                  <div className="icon">
                    <img loading="lazy"  src={ITImg} alt="ITImg" />
                    <h3>IT </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={RnDImg} alt="RnDImg" />
                    <h3>R&D </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={ManufacturingImg} alt="ManufacturingImg" />
                    <h3>Manufacturing </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={BFSIImg} alt="BFSIImg" />
                    <h3>BFSI </h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={PharmaceuticalImg} alt="PharmaceuticalImg" />
                    <h3>Pharma</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={EngineeringImg} alt="EngineeringImg" />
                    <h3>Engineering</h3>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <Link
                  to="/employer/business-enquiry/"
                  state={{ interested: "Permanent Recruitment" }}
                >
                  Business Enquiry
                </Link>
              </div>
            </div>
          </Col>
          <Sidebar />
        </Row>
        <Stories heading="Case Study" isReadMore={false} />
      </section>
    </PageContentEmployerScreen>
  )
}
export default RpoServices
