import React from "react";
import {Breadcrumb, Col, Row } from "antd";
import { Link } from "gatsby";
import { PageContentEmployerScreen } from "../../components/PageContent/PageContent";
import BannerImg1 from "../../images/case-study/CaseStudy-Banner.webp";
import { searchFJobData } from "../../../services/HeaderData";
import RenderMenu from "../../components/Menu/RenderMenu";

function ThankYou(props) {
  return (
    <PageContentEmployerScreen location={props.location}>
      <section className="caseStudyBannerSection">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div
              className="hero-section"
              style={{ backgroundImage: `url(${BannerImg1})` }}
            >
              <div className="overlay"></div>
              <div className="content-section">
                <h1>{" Thank You For Contacting Us"}</h1>
              </div>
            </div>
          </Col>
        </Row>
        {/* <BreadcrumbSec current={"Thank You!"} /> */}
        <Row className="custome-breadcrumb m-5">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item overlay={<RenderMenu data={searchFJobData} />}>
                Search for job
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href="">Thank You!</a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className="p-lg-8 p-xl-8 p-md-8 p-6">
          <Col md={18} lg={16} xl={16}>
            <p style={{ fontSize: "22px" }}>
              {" "}
              We have received your message and would like to thank you for
              writing to us. If your inquiry is urgent please get in touch with
              one of our branches <Link to="/employer/locations/">here </Link> to talk to one of our staff members, or
              for general enquiries, please write to{" "}
              <a href="mailto:in.marcom@adecco.com" target={"_blank"}>
                in.marcom@adecco.com.
              </a>
            </p>
            <p style={{ fontSize: "22px" }}>
              We are glad that you have taken the first step to connect and
              build a relationship with Adecco. Looking forward to working with
              you and your organization.
            </p>
            <h2 className="text-danger pt-4" style={{ fontWeight: 600 }}>
              Adecco
            </h2>
          </Col>
        </Row>
      </section>
    </PageContentEmployerScreen>
  )
}

export default ThankYou
