import React, { useState } from "react";
import { Link } from "gatsby";
import {
  Row,
  Col,
  Breadcrumb,
  Pagination,
  DatePicker,
  Modal,
  Button,
} from "antd";
import { latestMediaCoverageData } from "../../../services/MediaCoverageData";
import MediaCardLayout from "../../components/NewMediaCardLayout/MediaCardLayout";
import VideoModal from "../../components/VideoModal/VideoModal";
import{
  PageContentEmployerScreen,
} from "../../components/PageContent/PageContent";
import RenderMenu from "../../components/Menu/RenderMenu";
import { media } from "../../../services/HeaderData";
import { WrappedMediaEnquiryForm } from "../../components/Forms/MediaInquiryFrom";
import { ScrollToTop } from "../../helper/dimensionHelper";

const { RangePicker } = DatePicker
const cardSize = 9
const MediaCoverage = props => {
  const [showVideoModal, changeVisibilityModal] = useState(false)
  const [clearFilter, setClearFilter] = useState(false)
  const [selectedItem, changeselectedItem]: any = useState({})
  const [dateRange, setDateRange] = React.useState([])
  const [current, setCurrent] = React.useState(1)
  const [minIndex, setMinIndex]: any = React.useState(0)
  const [maxIndex, setMaxIndex]: any = React.useState(cardSize)
  const [counter, setCounter] = React.useState(0)
  const [visible, setVisible] = React.useState(false)
  const [data, setData] = React.useState(latestMediaCoverageData)

  
  React.useEffect(() => {
    counter < 11 && setTimeout(() => setCounter(counter + 1), 1000)
    if (counter === 10) {
      setVisible(true)
    }
  }, [counter])


  const filterDateWisw = (filterVals, data) => {
    let min = new Date(filterVals[0].format("YYYY-MM-DD"))
    let max = new Date(filterVals[1].format("YYYY-MM-DD"))

    const filteredData = data.filter(row => {
      let datejs = new Date(row.date)
      return min && datejs >= min && max && datejs <= max
    })
    setData(filteredData)
    setClearFilter(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }


  const handleChangePagination = page => {
    setCurrent(page)
    setMinIndex((page - 1) * cardSize)
    setMaxIndex(page * cardSize)
    ScrollToTop();
  }

  function handleClearFilter() {
    setData(latestMediaCoverageData)
    setClearFilter(false)
    setDateRange([])
  }
  return (
    <React.Fragment>
      {showVideoModal && (
        <VideoModal
          onCloseModal={() => changeVisibilityModal(false)}
          data={selectedItem}
        />
      )}
      <Modal
        title="Media Enquiry"
        visible={visible}
        footer={null}
        className="media-modal"
        onCancel={handleCancel}
      >
        <WrappedMediaEnquiryForm span1={5} span2={19} />
      </Modal>

      <PageContentEmployerScreen location={props.location}>
        <Row>
          <div className="hero-section">
            <div className="overlay"></div>
            <div className="content-section">
              <h1>Media Coverage</h1>
            </div>
          </div>
        </Row>
        <div className="d-md-flex justify-content-between align-items-center mt-9">
          <Row className="customer-breadcrumb mt-0 mb-0 m-4">
            <Col>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={"/employer"}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item overlay={<RenderMenu data={media} />}>
                  News
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="">Media Coverage</a>
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          <Row className="mt-sm-0 mt-2 mb-0 m-4 ">
            <RangePicker
              format="MMM DD, YYYY"
              className="media-date"
              defaultValue={dateRange}
              value={dateRange}
              separator={"-"}
              onChange={(x: any) => {
                filterDateWisw(x, data)
                setDateRange(x)
              }}
              allowClear={false}
            />
            {clearFilter && (
              <Button icon="close" className="ml-2" onClick={handleClearFilter}>
                Clear Filter
              </Button>
            )}
          </Row>
        </div>
        <Col className="mt-5">
          <Row gutter={[16, 24]} className="custom-ant-row mx-4">
            {data.map(
              (data: any, index: any) =>
                parseInt(index) >= parseInt(minIndex) &&
                parseInt(index) < parseInt(maxIndex) && (
                  <MediaCardLayout data={data} key={index} />
                )
            )}
          </Row>
        </Col>
        <Row type="flex" justify="center" className="my-3">
          <Pagination
            pageSize={cardSize}
            current={current}
            total={data?.length}
            onChange={e => handleChangePagination(e)}
          />
        </Row>
      </PageContentEmployerScreen>
    </React.Fragment>
  )
}

export default MediaCoverage
