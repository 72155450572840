import React, { useState, useEffect } from "react"
import {
  Form,
  Button,
  Checkbox,
  Input,
  Select,
  Radio,
  message,
  Row,
  Col,
} from "antd"
import "./formStyle.less"
import FormItem from "antd/lib/form/FormItem"
import gpSIcon from "../../images/gps.svg"
import TextArea from "antd/lib/input/TextArea"
import { TermsConditionData } from "../../../services/termsAndConditionData"
import ModalComponent from "../ModalComponent/Modal"
import {
  checkError,
  disableSubmitCheck,
  phoneNumberValidation,
  characterValidation,
  validationRegex,
  onlyNumValue,
  emailValidation,
  countryCodeData,
  checkMaxDigitLimit,
  industryData,
} from "./FormUtils"
import { text } from "../../constants"
import Axios from "axios"
const formFields = [
  "employeeName",
  "countryCode",
  "phoneNumber",
  "social",
  "summary",
  "industry",
  "location",
  "employeeEmail",
  "designation",
  "organisation",
]

export function StaffingEvolution(props: { form; pageSource: string }) {
  const [agreement, setAgreement] = useState(true)
  const [disableSubmit, setDisableSubmit] = useState(true)
  const [emptyForm, setEmptyForm] = useState(true)
  const [country, setCountry] = useState("India:+91")
  const [modalVisibility, setModalVisibility] = useState(false)

  const handleSubmit = (e: any) => {
    e.preventDefault()

    props.form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err) {
        const countryData = values.countryCode.split(":")
        const enquiryType = text.STAFFING_ON_DEMAND
        const apiEndpoint = "api/SaveBusinessEnquiry"

        // fetch(`${process.env.GATSBY_MIDDLEWARE_API}/${apiEndpoint}`, {
        //   method: "POST",
        //   body: JSON.stringify({
        //     employeeName: values.employeeName,
        //     organisation: values.organisation,
        //     designation: values.designation,
        //     employeeEmail: values.employeeEmail,
        //     location: values.location,
        //     industry: values.industry,
        //     summary: values.summary,
        //     social: values.social,
        //     agreement: values.agreement.toString(),
        //     phoneNumber: countryData[1] + values.phoneNumber,
        //     country: countryData[0],
        //     businessEnquiryType: enquiryType,
        //   }),
        // })
        Axios.post(
          `${process.env.GATSBY_MIDDLEWARE_API}/${apiEndpoint}`,
          JSON.stringify({
            employeeName: values.employeeName,
            organisation: values.organisation,
            designation: values.designation,
            employeeEmail: values.employeeEmail,
            location: values.location,
            industry: values.industry,
            summary: values.summary,
            social: values.social,
            agreement: values.agreement.toString(),
            phoneNumber: countryData[1] + values.phoneNumber,
            country: countryData[0],
            businessEnquiryType: enquiryType,
          })
        )
        message.success("The form has been successfully submitted.")
        setEmptyForm(true)
        props.form.resetFields()
      } else {
        message.error("We encountered an error while submitting the form.")
      }
    })
  }

  const { Option } = Select
  const { getFieldDecorator } = props.form

  const handleModalView = () => {
    setModalVisibility(true)
  }

  const changeModalView = (value: boolean) => {
    setModalVisibility(value)
  }

  const checkCheckBox = (rule, value, callback) => {
    if (!value) {
      setAgreement(false)
      callback("Please agree the terms and conditions!")
    } else {
      setAgreement(true)
      callback()
    }
  }

  useEffect(() => {
    var form = props.form
    var errorData = checkError({ formFields, form })
    var { errorInData, fieldCount } = errorData

    if (fieldCount === formFields.length) {
      setEmptyForm(false)
    }
    setDisableSubmit(disableSubmitCheck(errorInData, emptyForm, agreement))
  })

  return (
    <Form onSubmit={handleSubmit}>
      <div className="form-content overflow-auto pt-2 h-auto">
        <Form.Item className="mb-2 mr-0 mr-md-2">
          {getFieldDecorator("employeeName", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your name",
              },
              {
                pattern: characterValidation,
                message: "Please enter valid characters!",
              },
            ],
          })(<Input size="large" placeholder="Name *" />)}
        </Form.Item>
        <Form.Item className="mb-2 mr-0 mr-md-2">
          {getFieldDecorator("organisation", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your company name",
              },
              {
                pattern: validationRegex,
                message: "Please enter valid string",
              },
            ],
          })(<Input size="large" placeholder="Company / organisation *" />)}
        </Form.Item>
        <Form.Item className="mb-2 mr-0 mr-md-2">
          {getFieldDecorator("designation", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your designation",
              },
              {
                pattern: characterValidation,
                message: "Please enter valid characters!",
              },
            ],
          })(<Input size="large" placeholder="Designation *" />)}
        </Form.Item>
        <Form.Item className="mb-2 mr-0 mr-md-2">
          {getFieldDecorator("employeeEmail", {
            validateTrigger: "onBlur",
            rules: [
              {
                pattern: emailValidation,
                message: "Please enter a valid email!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ],
          })(<Input size="large" placeholder="Email *" />)}
        </Form.Item>
        <Row type="flex">
          <Col span={24} lg={10}>
            <Form.Item className="mb-2 mr-0 mr-md-2 custom-dropdown">
              {getFieldDecorator("countryCode", {
                validateTrigger: "onBlur",
                initialValue: "India:+91",
                rules: [
                  {
                    required: true,
                    message: "Please select your country code",
                  },
                ],
              })(
                <Select
                  size="large"
                  onChange={(value: string) => setCountry(value)}
                  getPopupContainer={t => t.parentNode as HTMLElement}
                >
                  {countryCodeData.map((data, index) => {
                    return (
                      <Option
                        key={index}
                        value={data.country + ":" + data.countryCode}
                      >
                        {data.country + " : " + data.countryCode}
                      </Option>
                    )
                  })}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={14}>
            <Form.Item className="mb-2 mr-0 mr-md-2">
              {getFieldDecorator("phoneNumber", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    required: true,
                    message: "Please enter your phone number",
                  },
                  {
                    pattern: phoneNumberValidation,
                    message: "Please enter valid digits!",
                  },
                  {
                    validator: (rule, value, callback) =>
                      checkMaxDigitLimit(rule, value, callback, country),
                  },
                ],
              })(
                <Input
                  size="large"
                  placeholder="Phone Number *"
                  onKeyPress={e => {
                    onlyNumValue(e)
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className="mb-2 mr-0 mr-md-2">
          {getFieldDecorator("location", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your location",
              },
              { max: 250, message: "Character limit exceeded" },
            ],
          })(
            <Input
              size="large"
              placeholder="Location *"
              suffix={<img loading="lazy"  src={gpSIcon} style={{ width: "70%" }} alt={'incon'}/>}
            />
          )}
        </Form.Item>
        <Form.Item className="mb-2 mr-0 mr-md-2 custom-dropdown">
          {getFieldDecorator("industry", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter your industry",
              },
            ],
          })(
            <Select
              size="large"
              placeholder="Industry *"
              getPopupContainer={t => t.parentNode as HTMLElement}
            >
              {industryData.map((data, index) => {
                return (
                  <Option key={index} value={data}>
                    {data}
                  </Option>
                )
              })}
            </Select>
          )}
        </Form.Item>
        <Form.Item className="mb-2 mr-0 mr-md-2">
          {getFieldDecorator("summary", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter the summary",
              },
              { min: 10, message: "Minimum 10 characters are required" },
              { max: 200, message: "Character limit exceeded" },
            ],
          })(
            <TextArea
              autosize={false}
              rows={4}
              placeholder="Summary of requirement (200 characters max) *"
              style={{ resize: "none" }}
              maxLength={200}
            />
          )}
        </Form.Item>
        <div className="c-secondary lead">How did you find us? *</div>
        <Form.Item>
          {getFieldDecorator("social", {
            validateTrigger: "onBlur",
            rules: [
              {
                required: true,
                message: "Please enter where you found us",
              },
            ],
          })(
            <Radio.Group className="w-100 ">
              <Row type="flex" justify="space-between">
                <Radio value="Facebook" className="c-secondary font-normal">
                  Facebook
                </Radio>
                <Radio value="Twitter" className="c-secondary font-normal ">
                  Twitter
                </Radio>
                <Radio value="LinkedIn" className="c-secondary font-normal">
                  LinkedIn
                </Radio>
                <Radio value="Others" className="c-secondary font-normal">
                  Others
                </Radio>
              </Row>
            </Radio.Group>
          )}
        </Form.Item>
      </div>
      <FormItem className="mb-3">
        {getFieldDecorator("agreement", {
          valuePropName: "checked",
          initialValue: "true",
          rules: [{ validator: checkCheckBox }],
        })(
          <div>
            <Checkbox className="c-secondary pr-2" defaultChecked={true}>
              I agree to the{" "}
            </Checkbox>
            <a>
              <small className="c-info" onClick={handleModalView}>
                Terms and conditions
              </small>
            </a>
            {modalVisibility && (
              <ModalComponent
                title="Terms and condition"
                isVisible={modalVisibility as boolean}
                getValueModal={changeModalView}
                modalData={TermsConditionData()}
              />
            )}
          </div>
        )}
      </FormItem>
      <Form.Item>
        <Button
          size="large"
          type="danger"
          htmlType="submit"
          className="mb-3 w-50"
          disabled={disableSubmit}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}

export const WrappedStaffingEvolution = Form.create({
  name: "staffing-on-demand",
})(StaffingEvolution)
