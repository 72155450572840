import HelpCenterImg from "../src/images/pf-help-center/PF_help_centre_image.webp"
import pfPresentation from "../src/pdf/new/PF Self Help Online Portal.pdf"
import EscalationMatrix from "../src/images/pf-help-center/PF-Excalation-Matrix.webp"
import presentationPreviewImg from "../src/images/pf-help-center/PF_Self_Help_Preview-min.webp"

export const HelpCenterData = {
  PFHelpCenter: {
    img: HelpCenterImg,
    author: "",
    title: "Welcome to the PF help centre",
    description: `<p>As an Adecco associate, you can now use the <b>PF Self Help Portal</b> for a wide range of features including the transfer- out & withdrawal process. It is secure and based on authentication information provided by Adecco for service access and can be viewed only by the associate.<p>`,
    contents: [
      {
        subTitle: "Keep These Documents Handy While Applying",
        contentList: [
          "Copy of any payslip which contains the employee and the associate's PF number (Preferably the latest payslip copy).",
          "Copy of the Relieving Letter.",
          "One set of self-signed cancelled cheque leaf of your bank account which has been updated in the UAN in the EPFO database (with your name printed on the cheque leaf, if your name has not been printed in the cancelled cheque leaf, please enclose your bank statement as well).",
          "One set of self-attested PAN card and Aadhar card copy.",
          "Employee's contact details: Mobile Number and Email ID.",
        ],
        footerContent:
          "Once the PF team receives the forms, Form-19 will be validated and processed by the PF Trust. The amount will be credited to the associate's account within 30 working days.",
      },
      {
        subTitle: "Project Escalation Matrix",
        image: EscalationMatrix,
      },
    ],
    attachment: pfPresentation,
    attachmentPreviewImage: presentationPreviewImg,
  },
}
