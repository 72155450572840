import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "antd";
import { jobByData } from "../../../services/jobSekeerHomePageData";
import longRight from "../../images/icons/long-right.webp";

const Location = () => {
  return (
    <section className="jobLocationSection">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="subHead">
            <p>Where would you like to send your CV?</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="px-3 px-md-6">
          <ul>
            {jobByData.state
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map(m => (
                <li key={m.name}>
                  <Link to={m.link}>
                    {m.name} <img loading="lazy"  src={longRight} alt={m.name}/>
                  </Link>
                </li>
              ))}
          </ul>
        </Col>
      </Row>
    </section>
  )
}

export default Location
