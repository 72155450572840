import React from "react";
import { Col, Row } from "antd";
import BannerImg from "../../images/aboutBanner.webp";
import "./common.less";

const Banner = ({ heading, desc }) => {
  return (
    <section
      className="aboutBannerSection px-3 px-md-6"
      style={{ backgroundImage: `url(${BannerImg})` }}
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <h1>{heading}</h1>
          <p>{desc}</p>
        </Col>
      </Row>
    </section>
  )
}

export default Banner
