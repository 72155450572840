import award from "../src/images/awardImage.webp"

const AwardRecognitionData = [
  {
    img: award,
    title: "11th most attractive company in the world to work for",
    content:
      "Adecco has been named No.11 company on the 2019 list of the World’s Best Workplaces by Great Place to Work. To qualify for consideration in this year’s ranking, companies must appear on at least five of Great Place to Work’s national Best Workplaces lists, which are published with media partners in 58 countries and territories, and also must employ more than 5,000 workers globally. More than 8,000 organizations participated in the survey, representing the voices of more than 12 million workers worldwide.",
  },

]

export default AwardRecognitionData
