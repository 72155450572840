import React from "react";
import { Row } from "antd";

export default function AtAGlance({ data }) {
  return (
    <section className="AtAGlance-section">
      <Row>
        <h2 className="heading">At a Glance</h2>
      </Row>
      <Row className="mobile-card">
        {data &&
          data?.map(item => (
            <div className="AtAGlance-card" key={item.id}>
              <img loading="lazy"  src={item.img} alt="Icons" />
              <h2>{item.title}</h2>
              {item?.desc.length > 0 &&
                item?.desc?.map(desc => <p key={desc}>{desc}</p>)}
            </div>
          ))}
      </Row>
    </section>
  )
}
