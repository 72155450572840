import React, {  useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import moment from "moment"
import { Row, Col, Collapse, Icon, Breadcrumb, Skeleton, Empty, List } from "antd"
import { Select, Card, Checkbox, DatePicker, Input, Pagination } from "antd"

import PageContent from "../components/PageContent/PageContent"
import RenderMenu from "../components/Menu/RenderMenu"
import { searchFJobData } from "../../services/HeaderData"
import { request, ScrollToTop } from "../helper/dimensionHelper"
import { APIContext } from "../helper/APIContex";
import {ArcodianJobsData} from "../../services/jobSeekerData";
import LocationIcon from "../images/location.svg"
import Baner from "../images/Job-Seekers.webp"

const { RangePicker } = DatePicker
const { Option } = Select
const { Panel } = Collapse;

const cardSize = 6
const JobSeeker = props => {
  const {slug}= props.pageContext;
  const { data, actions } = React.useContext(APIContext)
  const { jobspageLocationData, jobsbyIndustryData, jobsbyTypeData } = data;
  function getQueryVariable() {
    const isBrowser = typeof window !== "undefined"
    const query = isBrowser?window?.location?.search?.substring(1):null
    const vars =query? query?.split("&")?.map(q => q.split("=").pop()):[]
    return vars
  }

  const dateFormat = "YYYY/MM/DD"
  const [value, setValue] = React.useState(1)
  const [current, setCurrent] = React.useState(1)
  const [minIndex, setMinIndex] = React.useState(0)
  const [maxIndex, setMaxIndex] = React.useState(cardSize)
  const [search, setSearch] = React.useState("")
  const [jobsData, setJobsData] = React.useState([])
  const [loading, setLoading] = React.useState(data.loading)
  const [industryFilter, setIndustryFilter] = useState([])
  const [locationFilter, setLocationFilter] = useState([])
  const [locationLable, setLocationLable] = useState(null)
  const [typeFilter, setTypeFilter] = useState([])
  const [prams, setPrams] = useState(getQueryVariable() ??null)



  useEffect(() => {
    setLoading(data.loading)
  }, [data.loading])

  useEffect(() => {
    if(prams) callAPi(prams)
  }, [prams])
  const callAPi = async (prams) => {
    console.log("11111111",prams,{prams:window.location.pathname.split('/').pop()?.replace(new RegExp("-",'g')," ")})
    await getJobsData(prams)
  }
  const getJobsData = async prams => {
    if (prams && prams[0] && prams[1]) {
      console.log({prams})
      setLoading(true)
      // await request("GET", `JobSite/${prams[0]}/${prams[1]}`)
      let URL:any='';
      if(prams[0] === 'JobByLocation'){
        URL= `JobSite/Jobs/NULL/PER/NULL/${prams[1]}`;
      }else if(prams[0] === 'JobByIndustry'){
        URL= `JobSite/Jobs/NULL/PER/${prams[1]}/NULL`;

      } 
      await request("GET", URL)

      // JobSite/Jobs/NULL/PER/5/NULL/
        .then(res => {
          if (res?.data) setJobsData(res?.data)
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
    } else {
      setLoading(true)
      // await request("GET", "JobSite/JobByjobtype/PER")
      await request("GET", "JobSite/Jobs/NULL/PER/NULL/NULL")
        .then(res => {
          if (res?.data) setJobsData(res?.data)
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
    }
  }

  const onChange = (type, value,label) => {
    switch (type) {
      case "JobByIndustry":
        setIndustryFilter(value)
        break
      case "JobByLocation":
        setLocationFilter(value);
        setLocationLable(label)
        break
      case "JobByType":
        setTypeFilter(value)
        break

      default:
        null
    }
    setValue(value)
  }

  const { Panel } = Collapse
  const { Search } = Input;
  const handleChangePagination = page => {
    setCurrent(page)
    setMinIndex((page - 1) * cardSize)
    setMaxIndex(page * cardSize);
    ScrollToTop();

  }
  const searchFilterData = jobsData.filter(data =>
    search && search.length > 0
      ? data?.jobTitle?.toLowerCase()?.includes(search?.toLocaleLowerCase())
      : data
  )
  const industryFilterData = searchFilterData.filter(data =>
    industryFilter && industryFilter.length > 0
      ? industryFilter.includes(data.industryType)
      : data
  )
  const locationFilterData = industryFilterData.filter(data =>
    locationFilter && locationFilter.length > 0
      ? locationFilter.includes(data.jobLocation)
      : data
  )
  const jobTypeFilterData = !typeFilter.includes("ALL")
    ? locationFilterData.filter(data =>
        typeFilter && typeFilter.length > 0
          ? typeFilter.includes(data.jobType)
          : data
      )
    : locationFilterData

  const filterData = jobTypeFilterData;
  const geData = jobspageLocationData.find(data => data.label === "Other")
  const lData = jobspageLocationData
    ?.sort((a: any, b: any) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return 1
      return 0
    })
    .filter(data => data.label !== "Other")
    
  lData.push(geData)
  console.log({lData})
const titleTax= slug?.locationDescription?"Jobs In "+slug?.locationDescription:slug?.industryDescription?"Jobs In "+slug?.industryDescription:'Job Seeker'
  return (
    <>
      <Row>
        <div className="hero-section" style={{ background: `url(${Baner})` }}>
          <div className="overlay"></div>
          <div className="content-section">
            <h1 style={{textTransform: 'capitalize'}}>{titleTax??''}</h1>
          </div>
        </div>
      </Row>

      <PageContent location={props.location} pageSize={24}>
        <Row className="custome-breadcrumb m-5">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item overlay={<RenderMenu data={searchFJobData} />}>
                Search for job
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href="">View All Job</a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row type="flex" justify="space-between" className=" m-5 job-seeker">
          <Col xs={24} xl={6} lg={6} md={10} sm={10} className="category-card">
            <Card title="Browse by Category">
              <Collapse >
                <Panel header=" Jobs by Industry" key="1">
                  <div className="job-category1">
                    {jobsbyIndustryData ? (
                      loading ? (
                        <Skeleton
                          paragraph={{
                            rows: 15,
                          }}
                        />
                      ) : (
                        <List
                        itemLayout="horizontal"
                        dataSource={jobsbyIndustryData}
                        renderItem={item => (
                          <List.Item>
                            <List.Item.Meta
                              title={<Link className={slug?.industryDescription === item.label?`active-job-link`:''}  to={`/job-seeker/jobs-in-${item.url}?type=JobByIndustry&ID=${item.industryCode}`}>{item.label}</Link>}
                            />
                          </List.Item>
                        )}
                      />
                        // <Checkbox.Group
                        //   style={{ display: "contents" }}
                        //   options={jobsbyIndustryData?.sort(
                        //     (a: any, b: any) => {
                        //       if (a.label < b.label) return -1
                        //       if (a.label > b.label) return 1
                        //       return 0
                        //     }
                        //   )}
                        //   onChange={val => navigate(`/`)}
                        // />
                      )
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                </Panel>
                <Panel header="Jobs by City" key="2">
                  <div className="job-category2">
                    {lData ? (
                      loading ? (
                        <Skeleton
                          paragraph={{
                            rows: 15,
                          }}
                        />
                      ) : (
                        <List
                        itemLayout="horizontal"
                        dataSource={lData}
                        renderItem={item => (
                          <List.Item>
                            <List.Item.Meta
                              title={<Link className={slug?.locationDescription === item.label?`active-job-link`:''}  to={`/job-seeker/jobs-in-${item.label.toLowerCase()}?type=JobByLocation&ID=${item.value}`}>{item.label}</Link>}
                            />
                          </List.Item>
                        )}
                      />
                        // <Checkbox.Group
                        //   style={{ display: "contents" }}
                        //   options={lData}
                        //   onChange={val => onChange("JobByLocation", val,lData.find(d=>d.value === val[0]))}
                          
                        // />
                        // link={`/job-seeker/jobs-in-${data.url}?type=JobByLocation&ID=${data.loctionCode}`}
                      )
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                </Panel>
                {/* <Panel header="Work Type" key="3">
                  <div className="job-category3">
                    {jobsbyIndustryData ? (
                      loading ? (
                        <Skeleton
                          paragraph={{
                            rows: 15,
                          }}
                        />
                      ) : (
                        <Checkbox.Group
                          style={{ display: "contents" }}
                          options={jobsbyTypeData?.sort((a: any, b: any) => {
                            if (a.label < b.label) return -1
                            if (a.label > b.label) return 1
                            return 0
                          })}
                          onChange={val => onChange("JobByType", val)}
                        />
                      )
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                </Panel> */}
              </Collapse>
            </Card>
          </Col>
          <Col xs={24} xl={17} lg={17} md={13} sm={13}>
            <Row className="mb-3">
              <Col xs={24} xl={12} lg={12} md={14} sm={24}>
                <Search
                  placeholder="search by job"
                  onChange={e => setSearch(e.target.value)}
                  onSearch={value => console.log(value)}
                />
              </Col>
            </Row>
            <Row gutter={[16, 24]} className="job-section">
              {filterData && filterData.length > 0 ? (
                loading ? (
                  <>
                    <Col xs={24} xl={12} lg={12} md={24} sm={24}>
                      <Card>
                        <Skeleton
                          paragraph={{
                            rows: 5,
                          }}
                        />
                      </Card>
                    </Col>
                    <Col xs={24} xl={12} lg={12} md={24} sm={24}>
                      <Card>
                        <Skeleton
                          paragraph={{
                            rows: 5,
                          }}
                        />
                      </Card>
                    </Col>
                  </>
                ) : (
                  filterData?.map(
                    (data, index) =>
                      parseInt(index) >= parseInt(minIndex) &&
                      parseInt(index) < parseInt(maxIndex) && (
                        <Col
                          xs={24}
                          xl={12}
                          lg={12}
                          md={24}
                          sm={24}
                          key={data.jobCode}
                        >
                          <div
                            className="job-card-ribbon"
                            data-label={
                              jobsbyTypeData?.find(
                                d => d.value === data.jobType
                              )?.label
                            }
                          >
                            <Card
                              bordered={false}
                              style={{ height: "100%", width: "100%" }}
                              actions={[
                                <p>
                                  <Icon type="calendar" key="calendar" />{" "}
                                  {moment(new Date(data.targetDate)).format(
                                    "MMM DD YYYY"
                                  )}
                                </p>,
                                <Link
                                  to={`/job-seeker/details/${data.jobCode}`}
                                >
                                  See job description{" "}
                                  <Icon type="arrow-right" key="arrow-right" />{" "}
                                </Link>,
                              ]}
                            >
                              <h3
                                className="job-title"
                                style={{ height: "50px" }}
                              ><Link
                              to={`/job-seeker/details/${data.jobCode}`}
                            >
                                {data.jobTitle}
                              </Link></h3>
                              <p
                                style={{ height: "83px" }}
                                dangerouslySetInnerHTML={{
                                  __html: data.jobShortDesc,
                                }}
                              ></p>
                              <div className="job-location">
                                <img loading="lazy"  src={LocationIcon} alt="Location" />
                                <p>
                                  {
                                    jobspageLocationData?.find(
                                      d => d.value === data.jobLocation
                                    )?.label
                                  }
                                </p>
                              </div>
                            </Card>
                          </div>
                        </Col>
                      )
                  )
                )
              ) :(locationLable?.label || slug?.locationDescription) ? null:(
                <div style={{ padding: "100px 0px" }}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </Row>

            {( locationLable?.label || slug?.locationDescription)&&
            <Row className="mt-3 mb-3">
              {ArcodianJobsData.map(element => {
                let name=locationLable?.label || slug?.locationDescription
                if(element.name=== name){
                  return(
                    <>
                    <Card title={<h3 className="m-0 p-0">Jobs in {element.name}</h3>}>
                    {element.desc.map((item,i)=>
                        <React.Fragment key={'00'+i}>
                       {item.title &&<h3>{item.title}</h3>}
                       {item.text.map((te,i)=><p key={`TES_${i}`}>{te}</p>)}
                       </React.Fragment>
                      )}
                    </Card>
                    {/* <Collapse  key={element.name}>
                    <Panel header={`Jobs in ${element.name}`} key="1">
                      {element.desc.map((item,i)=>
                        <React.Fragment key={'00'+i}>
                       {item.title &&<h2>{item.title}</h2>}
                       {item.text.map((te,i)=><p key={`TES_${i}`}>{te}</p>)}
                       </React.Fragment>
                      )}
                    </Panel>
                    
                  </Collapse> */}
                  </>
                  )
                }
                
              })}
             
            </Row>}
          </Col>
        </Row>
        
        
        <Row type="flex" justify="center" className="mt-3">
          <Pagination
            pageSize={cardSize}
            current={current}
            total={filterData.length}
            onChange={e => handleChangePagination(e)}
          />
        </Row>
      </PageContent>
    </>
  )
}

export default JobSeeker
