import React from 'react'
import { Card, Col, Row } from 'antd'
import { PageContentSubmitCvScreen } from '../components/PageContent/PageContent'
import { WrappedVeteranSubmissionForm } from '../components/Forms/VeteranSubmissionForm'
import { Banner } from '../components/submitYourCv/banner'
import CustomeBreadcrumb from '../components/common/breadcrumb'
import ApplyHear from '../components/AboutUs/apply-hear'

const veteranSubmissionForm = (props) => {
  return (
    <PageContentSubmitCvScreen location={props.location}>
      <Banner title={"Veteran Submission Form"}/>
      <CustomeBreadcrumb
        home_path={"/"}
        home={"Home"}
        current={"Veteran Submission Form"}
      />
        <Row
        gutter={[24, 24]}
        className="px-sm-3 px-md-6 m-0 mt-5 mb-5 d-md-flex align-items-center"
      >
        {/* <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <div className="contact-text">
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic,
              sit. Numquam saepe commodi mollitia nemo vel ab, ad unde. Corporis
              dolorum neque modi, nemo molestiae doloremque consequuntur minus
              itaque laudantium nesciunt in sapiente ullam recusandae quas vel,
              ex sunt sint quis? Dolore labore fuga expedita maiores, ut
              accusamus magnam quae magni in illo quod explicabo. Distinctio
              quae cupiditate ipsam saepe veritatis aut. Culpa natus, eaque
              officiis sunt quas eius atque repellendus inventore enim animi
              voluptatum, porro esse ab magni iure eum amet tenetur at velit.
              Voluptas eveniet error tempora ut rerum praesentium sit, deleniti
              incidunt quaerat officia et impedit odio?
            </p>
          </div>
        </Col> */}
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={16}
          className="ml-auto mr-auto"
        >
          <Card
            title={"Veteran Submission Form"}
            className="submit-cv"
            headStyle={{
              backgroundColor: "red",
              color: "#ffff",
              fontSize: "1.75rem",
              textAlign: "center",
            }}
          >
            <WrappedVeteranSubmissionForm jobApply={{}} />
          </Card>
        </Col>
      </Row>
      <ApplyHear/>
      
</PageContentSubmitCvScreen>
  )
}

export default veteranSubmissionForm