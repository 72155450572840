import React from "react";
import {Col, Row } from "antd";
import LogoSlider from "react-slick";
import { trustedData } from "../../../services/trustedData";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const slider = {
  dots: false,
  arrows: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 2000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const Trusted = () => {
  return (
    <section className="trustedSection px-md-6">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="heading">
            <h4>Trusted by the best</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="logoSlider">
          <LogoSlider {...slider}>
            {trustedData?.images?.map((data, i) => (
              <div className="items" key={i}>
                <img loading="lazy"  src={data}  alt={"items"+""+i}/>
              </div>
            ))}
          </LogoSlider>
        </Col>
      </Row>
    </section>
  )
}

export default Trusted
