export const text = {
  DIGITAL_BUSINESS: "DigitalBusiness",
  DIGITAL_BUSINESS_SOLUTIONS: "Digital Business Solutions",
  AI: "AI",
  DATA_ANALYTICS: "Data & Analytics",
  DIGITAL_ENGG: "Digital Engineering",
  IOT_SERVICES: "IoT Services",
  CUSTOMER_EXPERIENCE: "Customer Experience",
  APPLICATION_DEV: "Application Development & Maintenance",
  CLOUD_CORE_MODERNIZATION: "Cloud: Core Modernization",
  INFRASTRUCTURE_SERVICES: "Infrastructure Services",
  ENTERPRISE_APPLICATION: "Enterprise Application Services",
  TESTING_SERVICES: "Testing Services",
  CONTACT_US: "Contact us for more details!",
  DOWNLOAD_FLYER: "DOWNLOAD THE FLYER",
  PREVIEW: "Preview",
  DIGITAL: "digital",
  STAFFING_ON_DEMAND: "StaffingOnDemand",
  SIGN_UP_FOR_FREE: "Sign-up for free",
  DOWNLOAD_BEST_PRACTICE: "Download the Best Practices Handbook",
  DOWNLOAD_POSTERS: "Download the posters",
  CLICK_TO_DOWNLOAD: "Click to download",
  CLICK_TO_DOWNLOAD_PF_ATTACHMENT:
    "Click Here To Download The PF Self Help Online Process User Manual",
  REGISTER: "Register",
  APP_LAUNCH: "appLaunchForm",
  SCAN_TO_DOWNLOAD: "Scan to Download",
  DEVICE: "Device",
  ASSOCIATE_FEATURES: "Associate Features",
  CLIENT_FEATURES: "Client Features",
  INTERESTED_IN_OUR_SERVICE: "Interested In Our Service",
  HELP_CENTER: "Help Center",
  OR: "Or",
}

export interface IDefaultPageProps {
  location?: any
}
