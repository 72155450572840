import Customers from "../src/images/OurValues/5-icon.webp"
import Collaboration from "../src/images/OurValues/2-icon.webp"
import Passion from "../src/images/OurValues/1-icon.webp"
import Inclusion from "../src/images/OurValues/3-icon.webp"
import Courage from "../src/images/OurValues/4-icon.webp"

const ourValueData = [
  {
    img: Passion,
    title: "Passion",
    content: "We bring energy to our mission, care about what we do and take pride in our work every day.",
  },
  {
    img: Collaboration,
    title: "Collaboration",
    content:
      "We are stronger when we harness our collective strengths to solve challenges together.",
  },
  {
    img: Inclusion,
    title: "Inclusion",
    content:
      "We embrace different ways of thinking and value everyone’s differences to get the best of all.",
  },
  {
    img: Courage,
    title: "Courage",
    content: "We work together as one team.",
  },
  {
    img: Customers,
    title: "Customers",
    sub:'at the heart',
    content:
      "We put our clients and candidates at the heart of all we do because we win when they succeed.",
  },
]

export default ourValueData
