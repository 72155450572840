import React from "react";
import { PageContentEmployerScreen } from "../../components/PageContent/PageContent";
import Banner from "../../components/employerHomePage/banner";
import Disclaimer from "../../components/Disclaimer/disclaimer";
import Solution from "../../components/employerHomePage/solution";
import Service from "../../components/employerHomePage/service";
import Stories from "../../components/employerHomePage/stories";
import Trusted from "../../components/employerHomePage/trusted";
import { IDefaultPageProps } from "../../constants";
import { DisclaimerData } from "../../../services/disclaimerData";
import "../../styles/app.less";
import "../../components/HomePage/HomePageStyle.less";

class Employer extends React.Component<IDefaultPageProps> {
  constructor(props) {
    super(props)
    this.state = {
      disclaimerVisibility: false,
    }
  }


  changeDisclaimerView = (value: boolean) => {
    this.setState({ setDisclaimerVisibility: value })
  }

  handleDisclaimerButton = (value: boolean) => {
    sessionStorage.setItem("clicked", value)
    this.setState({ buttonVisibility: value })
  }

  render() {
    const { disclaimerVisibility } = this.state

    return (
      <div className="overflowX-hidden">
        <PageContentEmployerScreen location={this.props.location}>
          {disclaimerVisibility && (
            <Disclaimer
              title="ATTENTION: RECRUITMENT FRAUD ALERT"
              isVisible={disclaimerVisibility as boolean}
              getValueDisclaimer={this.changeDisclaimerView}
              disclaimerData={DisclaimerData()}
              getValueButton={this.handleDisclaimerButton}
            />
          )}
          <Banner />
          <Solution />
          <Service />
          <Stories heading="Case Study" isReadMore={true} />
          <Trusted />
        </PageContentEmployerScreen>
      </div>
    )
  }
}

export default Employer
