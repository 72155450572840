import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "antd";
import longRight from "../../images/icons/long-right.webp";
import CallOurTeam from "../../images/icons/Call-our-team.webp";
import InternalCareer from "../../images/icons/Internal-Career.webp";

const ServiceSection = ({
  class_name,
  img,
  alt,
  title,
  largeDesc,
  desc,
  link,
  linkTitle,
  target,
}) => {
  return (
    <div className={class_name}>
      <div>
        <img loading="lazy"  src={img} width={100} alt={alt} />
      </div>
      <h3>{title}</h3>
      <p className="large">{largeDesc}</p>
      <p>{desc}</p>
      {target ?<Link to={link} target={target}>
        {linkTitle} <img loading="lazy"  src={longRight} alt={linkTitle}/>
      </Link>:
      <Link to={link}>
        {linkTitle} <img loading="lazy"  src={longRight} alt={linkTitle}/>
      </Link>}
    </div>
  )
}

const Service = () => {
  return (
    <section className="msgServiceSection px-3 px-md-6">
      <Row className="d-sm-flex justify-content-center">
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <ServiceSection
            class_name={"jobAlert"}
            img={InternalCareer}
            alt={"Internal Career"}
            title={"Internal Career"}
            largeDesc={
              "Get new jobs that are right for you, direct to your inbox"
            }
            desc={"Customise your job alerts"}
            link={"https://careers.adeccogroup.com/"}
            linkTitle={"Get started"}
            target={"_blank"}
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <ServiceSection
            class_name={"findJob"}
            img={CallOurTeam}
            alt={"Call our team"}
            title={"Call our team"}
            largeDesc={"Contact your local branch and speak to a job expert"}
            desc={"Find an office near you"}
            link={"/employer/locations"}
            linkTitle={"Find a branch"}
            target={""}
          />
        </Col>
      </Row>
    </section>
  )
}

export default Service
