import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "antd";
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent";
import { NewBanner } from "../../../components/Solutions/newBanner";
import BreadcrumbSec from "./Breadcrumb";
import Sidebar from "./sidebar";
import ExpertsImg from "../../../images/solutions/Payroll&Outsorching/experts-and-consultants.webp";
import CuttingImg from "../../../images/solutions/Payroll&Outsorching/Cutting-edge-technology.webp";
import SpeedyImg from "../../../images/solutions/Payroll&Outsorching/Speedy-resolution.webp";
import ComplexImg from "../../../images/solutions/Payroll&Outsorching/complex-payroll-processes.webp";
import banImg from "../../../images/solutions/banner/Payroll-&-Compliance-Outsourcing.webp";
import "./common.less";
import Stories from "../../../components/employerHomePage/stories";

const PayrollOutsourcingServices = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <NewBanner
        BannerImg={banImg}
        title={"Payroll Outsourcing"}
      />
      <BreadcrumbSec current={"Payroll Outsourcing"} />

      <section className="mainSolutionSection px-3 px-md-6">
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="float-right">
            <div className="box">
              <div className="heading">
                <h1>Payroll Outsourcing</h1>
                <span className="separator"></span>
              </div>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">
                Customized Solutions for your HRIS, Payroll and Compliance requirements to increase your return on investment and ensure employee satisfaction
                </h2>
              </div>
              <p className={""}>
              We offer end-to-end payroll and compliance outsourcing services through a state-of-the-art tech-enabled offering. We offer secure and data-driven processing with a special focus on security and confidentiality management. We collaborate with Indian and global clients both small and large across various sectors to help them to overcome their payroll, compliance, tax, and statutory challenges.
              </p>
              <p className={"text-danger text-bold"}>
              We maintain strict confidentiality of data and provide competent and hassle-free processing of payroll and reimbursements, provident funds, profession tax, and income tax-related planning while accommodating any changes in employee benefit management.
              </p>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">Why choose us?</h2>
              </div>
              <div className={"pl-3"}>
                <div className="d-flex align-items-center my-3">
                  <img loading="lazy"  src={ComplexImg} alt="ComplexImg" width={40} className="mr-2" />
                  <h3 className="p-0 m-0">
                    Extensive experience managing complex payroll processes
                  </h3>
                </div>

                <div className="d-flex align-items-center my-3">
                  <img loading="lazy"  src={CuttingImg} alt="CuttingImg" width={40} className="mr-2" />
                  <h3 className="p-0 m-0">Cutting-edge technology</h3>
                </div>

                <div className="d-flex align-items-center my-3">
                  <img loading="lazy"  src={ExpertsImg} alt="ExpertsImg" width={40} className="mr-2" />
                  <h3 className="p-0 m-0">
                    Strong expertise with in-house experts
                  </h3>
                </div>

                <div className="d-flex align-items-center my-3">
                  <img loading="lazy"  src={SpeedyImg} alt="SpeedyImg" width={40} className="mr-2" />
                  <h3 className="p-0 m-0">
                    Speedy resolution of employee issues
                  </h3>
                </div>
              </div>
              <p className="mt-2">
              You can choose from our centralized or decentralized payroll and compliance outsourcing solutions based on your specific organizational requirement and business model. We aim for seamless management of assignments, removing most manual processes and paperwork related to timesheets, payroll, and legal documentation ensuring lower non-compliance risks and delays and increased operational efficiency.
              </p>
              <p className="">
              Our team of in-house experts augment knowledge on multiple competencies - including but not limited to compliance, statutory, regulatory, legal, and human resources. We also utilize our global expertise to provide comprehensive solutions for assisting organizations in addressing crucial matters. Our standard operating procedures and digitally enabled systems ensure we deliver high-quality, value-added services. Our robust query handling system gets your employees extensive support via email, phone, or online portal.
              </p>
              <p>
              With our strong review mechanisms, we work as an extension of your team providing customized data and analytics beneficial for management review and planning of risk management on payroll-related matters. Our extensive presence across the nation supports effective coordination points, reducing cost and time constraints and providing speedy resolution of client and employee issues.
              </p>
              <div className="text-center">
                <Link
                  to="/employer/business-enquiry/"
                  state={{ interested: "Payroll Outsourcing" }}
                >
                  Business Enquiry
                </Link>
              </div>
            </div>
          </Col>
          <Sidebar />
        </Row>
        <Stories heading="Case Study" isReadMore={false} />
      </section>
    </PageContentEmployerScreen>
  )
}

export default PayrollOutsourcingServices
