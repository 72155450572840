import React from "react";
import { Row } from "antd";
import PageContent from "../components/PageContent/PageContent";
import { text } from "../constants";
import presentationPreviewImg from "../images/pf-help-center/PF_Self_Help_Preview-min.webp";
import pfPresentation from "../pdf/PF Self Help Online Portal_Final.pdf";

const PFSelfHelp = props => {
  return (
    <PageContent
      class="px-4 px-md-6 text-align-justify"
      location={props.location}
    >
      <Row justify="center" type="flex" className="pos-relative mt-6">
        <img loading="lazy" 
          src={presentationPreviewImg}
          alt="image loading"
          className="w-75 h-75"
          style={{ objectFit: "cover" }}
        />
      </Row>
      <Row justify="center" align="middle" type="flex" className="mt-3">
        <a
          download
          href={pfPresentation}
          className="font-h3 fw-500 pt-2 c-primary"
        >
          <span>{text.CLICK_TO_DOWNLOAD}</span>
        </a>
      </Row>
    </PageContent>
  )
}

export default PFSelfHelp
