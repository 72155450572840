import React, { createRef } from "react";
import { Col, Row } from "antd";
import SliderTestimonial from "react-slick";
import Arrow from "../../images/sliderArrow.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonial = ({ data }) => {
  const vidRef = createRef()

  function NextArrow(props) {
    const { onClick } = props

    return (
      <div
        className="nextArrow"
        onClick={() => {
          onClick()
        }}
      >
        <img loading="lazy"  src={Arrow}  alt='nextArrow'/>
      </div>
    )
  }

  function PrevArrow(props) {
    const { onClick } = props
    return (
      <div className="prevArrow" onClick={onClick}>
        <img loading="lazy"  src={Arrow} alt='prevArrow'/>
      </div>
    )
  }
  const testimonialData = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  return (
    <>
      <section className="testimonialSection">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <SliderTestimonial {...testimonialData}>
              {data?.map((data, index) => (
                <div className="items" key={index}>
                  <div className="testiDataNew">
                    {data.type === "IMG" && <img loading="lazy"  src={data.image} alt={data.desc}/>}
                    <div className="dataNew">
                      <h4>{data.desc}</h4>
                      <p className="p-text">{data.auther}</p>
                    </div>
                  </div>
                </div>
              ))}
            </SliderTestimonial>
          </Col>
        </Row>
      </section>
    </>
  )
}

export default Testimonial
