import React from "react"
import { Link } from "gatsby"
import SliderTestimonial from "react-slick"
import { Col, Row } from "antd"
import bannerImg from "../../images/LokingForJob/slider/slider1.webp"
import bannerImg1 from "../../images/LokingForJob/slider/slider2.webp"
import bannerImg2 from "../../images/LokingForJob/slider/slider3.webp"
import bannerImg3 from "../../images/LokingForJob/slider/slider4.webp"
import Arrow from "../../images/sliderArrow.webp"
import "./common.less"

const Banner = () => {
  function NextArrow(props) {
    const { onClick } = props
    return (
      <div
        className="nextArrow"
        onClick={() => {
          onClick()
        }}
      >
        <img loading="lazy"  src={Arrow} alt="nextArrow"/>
      </div>
    )
  }

  function PrevArrow(props) {
    const { onClick } = props
    return (
      <div className="prevArrow" onClick={onClick}>
        <img loading="lazy"  src={Arrow}  alt="prevArrow"/>
      </div>
    )
  }

  const SliderItem = ({
    dynamicCalls,
    title,
    desc,
    link1,
    link2,
    btn1,
    btn2,
    target,
    bannerImg,
  }) => {
    return (
      <div className="items">
        <Row className={`homeScreenSection ${dynamicCalls} px-3 px-md-6`}>
          <Col xs={24} sm={24} md={12} lg={12} xl={14}>
            <div className="bannerData">
              <h2>{title}</h2>
              <p>{desc}</p>
              <div className="bannerButton">
                  <Link className="ant-btn ant-btn-danger ant-btn-lg" to={link1}>{btn1}</Link>
                 {target?<Link className="ant-btn ant-btn-danger ant-btn-lg" to={link2} target={target}>
                    {btn2}
                  </Link>:
                  <Link className="ant-btn ant-btn-danger ant-btn-lg" to={link2}>
                    {btn2}
                  </Link>}
              </div>
            </div>
          </Col>
          <div
            className="bannerImg"
            style={{ backgroundImage: `url(${bannerImg})` }}
          ></div>
        </Row>
      </div>
    )
  }
  const testimonialData = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  return (
    <SliderTestimonial {...testimonialData} className="hom-slider">
      <SliderItem
        dynamicCalls={"data-0"}
        title={"Your story starts here"}
        desc={
          "Are you looking for a new challenge? Connect with us to know how we can help you."
        }
        link1={"/job-seeker/"}
        btn1={"Jobs with Client"}
        link2={"https://careers.adeccogroup.com/"}
        btn2={"Work for Us"}
        target={"_blank"}
        bannerImg={bannerImg}
      />
      <SliderItem
        dynamicCalls={"data-1"}
        title={"Find your dream job right here"}
        desc={"So, what are you waiting for? Talk to us today and get to work!"}
        link1={"/submit-your-cv/"}
        btn1={"Submit Your CV"}
        link2={"/refer-a-friend/"}
        btn2={"Refer a Friend"}
        target={""}
        bannerImg={bannerImg1}
      />
      <SliderItem
        dynamicCalls={"data-3"}
        title={"Ready for a future in technology"}
        desc={
          " You can now work with the latest technologies, advance your skills and grow your career."
        }
        link1={"/job-seeker/"}
        btn1={"Jobs with Client"}
        link2={"https://careers.adeccogroup.com/"}
        btn2={"Work for Us"}
        target={"_blank"}
        bannerImg={bannerImg3}
      />
      <SliderItem
        dynamicCalls={"data-2"}
        title={"The power to change lives"}
        desc={
          "Be part of a culture that inspires diversity, wellbeing and flexibility."
        }
        link1={"/submit-your-cv/"}
        btn1={"Submit Your CV"}
        link2={"/refer-a-friend/"}
        btn2={"Refer a Friend"}
        target={""}
        bannerImg={bannerImg2}
      />
    </SliderTestimonial>
  )
}
export default Banner
