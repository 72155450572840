import React, { useState } from "react";
import { Link } from "gatsby";
import { Card, Col, Icon, Modal, Row } from "antd";
import { WrappedMediaEnquiryForm } from "../Forms/MediaInquiryFrom";
import img from "../../images/Blogs/RestartImg.webp";
import connectImg from "../../images/Disconnect-to-reconnect.webp";

const { Meta } = Card
const ReportSections = () => {
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState(null)

  const onclose = () => {
    setVisible(false)
    setValue(null)
  }
  const onOpen = data => {
    setValue(data)
    setVisible(true)
  }
  return (
    <section className="report-section pt-6 pb-1">
      <Modal
        title="Select Language"
        visible={visible}
        footer={null}
        maskClosable
        onCancel={() => onclose()}
      >
        <WrappedMediaEnquiryForm
          span1={5}
          span2={19}
          isReport={true}
          onClose={onclose}
          pdf_type={value}
        />
      </Modal>
      <Row className="title">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h2 className="p-0 m-0">Reports</h2>
        </Col>
      </Row>
      <Row
        gutter={[12, 12]}
        className="mx-lx-8 mx-lg-8 mx-md-4 mx-3  custom-ant-row my-3"
      >
        <Col sm={24} md={12} lg={12} xl={12} style={{ padding: "30px" }}>
          <Card
            className="h-100"
            hoverable
            cover={<img loading="lazy"   src={connectImg} height={"250px"} alt="connectImg"/>}
            actions={[
              <Link to={"#"} onClick={() => onOpen("disconnect_to_reconnect")}>
                Download the report{" "}
                <Icon className="ml-2" type="arrow-right" key="arrow-right" />{" "}
              </Link>,
            ]}
          >
            <Meta
              title={
                <h1 className="m-0 p-0 taxt-danger card-title">
                  Disconnect to reconnect
                </h1>
              }
              description={
                <p>
                  The ‘Disconnect to Reconnect’ survey spoke to 1,116 workers in
                  131 companies around the world, in 16 countries including
                  India, to explore how the ways of working in the aftermath of
                  the global health pandemic have been shifted and are affecting
                  workers’ well-being. Topics asked about were work location,
                  workload, stress evolution, ability to disconnect from work,
                  and companies’ support for employees’ well-being. You can
                  explore all the insights in our survey report. Download now.
                </p>
              }
            />
          </Card>
        </Col>
        <Col sm={24} md={12} lg={12} xl={12} style={{ padding: "30px" }}>
          <Card
            className="h-100"
            hoverable
            cover={<img loading="lazy"   src={img} height={"250px"} alt="img" />}
            actions={[
              <Link to={"#"} onClick={() => onOpen("safely_back_to_work")}>
                Download the report{" "}
                <Icon className="ml-2" type="arrow-right" key="arrow-right" />{" "}
              </Link>,
            ]}
          >
            <Meta
              title={
                <h1 className="m-0 p-0 taxt-danger card-title">
                  Safely back to work
                </h1>
              }
              description={
                <p>
                  The India alliance of four HR services industry players was
                  launched by The Adecco Group, Randstad, ManpowerGroup, and Gi
                  Group. Through this alliance, the HR services industry issued
                  a call to action to all relevant stakeholders to contribute
                  and play their part with a first-of-a-kind `India Safely Back
                  To Work Guide' to enable our colleagues from the IT/ITeS,
                  Pharma, Healthcare, and Life Sciences, Infrastructure and
                  Manufacturing sectors to prepare for the new reality during
                  the pandemic and get back to work safely! Download now.
                </p>
              }
            />
          </Card>
        </Col>
      </Row>
    </section>
  )
}

export default ReportSections
