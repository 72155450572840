import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Breadcrumb, Col, Row } from "antd"
import { AdviceData } from "../../../services/jobSekeerHomePageData"
import SearchIcon from "../../images/jobSearch.svg"
import backIcon from "../../images/icons/icons8-back-24.webp"
import calendar from "../../images/calendar.webp";

import { Banner } from "./banner"

const AdviceDetails = ({ id }) => {
  const [careerData, setCareerData] = useState([])
  useEffect(() => {
    if (id) {
      const tem: any = AdviceData.find(data => data.id === id)
      if (tem) setCareerData(tem)
    }
  }, [id])

  return (
    <>
      <Banner BannerImg={careerData.bannerImg} />
      <Row className="custome-breadcrumb px-5 pt-5 pb-0">
        <Row gutter={[12, 12]}>
          <Col xl={12} lg={12} md={12} sm={12} className="p-0">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/career-advice/"}>
                  <img loading="lazy"  src={backIcon}  alt='Back to Career Advice'/> Back to Career Advice
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} className="p-0">
            <div className="float-right lookingJob">
              <Link to={'/job-seeker/'}>
                Looking for a job <img loading="lazy"  src={SearchIcon} alt={'Looking for a job'}/>
              </Link>
                <ul className="timeLocation">
                  <li>
                    <img loading="lazy"  src={calendar} alt="calendar img"/> <span>{careerData?.date}</span>
                  </li>
                </ul>
            </div>

          </Col>
        </Row>
      </Row>

      <section className="adviceDetailsSection px-3 px-md-6">
        <Row className="">
          {/* <Col xs={24} sm={24} md={24} lg={16} xl={16}>
        <div className='float-right lookingJob'><a>Looking for a job <img loading="lazy"  src={SearchIcon} /></a></div>
        </Col> */}
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <div className="heading my-1">
              <h1>{careerData.title}</h1>
            </div>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            {careerData?.details?.map((data, index) => (
              <div className="details" key={index}>
                {data.title && <h2>{data.title}</h2>}
                {data.desc.map((desc, ind) => (
                  <p key={ind}>{desc}</p>
                ))}
              </div>
            ))}
            {/* <div className='details '>
            <p>--------------------------------------------------</p>
            <p><b>Just like the way a good trailer is important to attract audience; a job description is vital to attract good and competent candidates.</b></p>
            <p>Gone are the days when candidates could be told what to do! In the same way, you can’t expect a good candidate profile without having a job description. If organizations need a competent pool of candidates, they need to do many things (circus), and one key thing to do is to <b>‘take time to prepare a sharp job description.</b>"</p>
          </div>
          <div className='details'>
             <h2>FIVE STATS ON WHAT CANDIDATES USUALLY LOOK FOR IN JD</h2> 
             <p>According to a study, <b>61%</b> of candidates would like to see a salary range offered, <b> 49%</b> focus on qualifications needed, <b>49%</b> would look for job-related details, <b>33%</b> focus on performance goals and <b>28%</b> on company culture. As a result, these five key aspects must be covered in any JD.</p>
             <p>A study also says that close to 50% of job seekers spend less than 5 minutes reading the JD. This simply means organizations and hiring managers have to pay a lot of attention while preparing JD to make it a good read in a short span of time and be to the point.</p>
          </div>
          <div className='details'>
             <h2>NORM</h2> 
             <p>While it is not a legal obligation for an employer to provide JD, it’s very good practice to do so. There are situations where a job description can play a vital role and become a base document if a situation goes out of control between the two parties.</p>
             <p>A well-written JD could also act as a goal-setting document too after the employee joins, if it is administered appropriately.</p>
          </div>
          <div className='details'>
             <h2>WHY</h2> 
             <p>JD also acts as a bridge between the employer and the candidate. These are demanding times and good candidates are in great demand. In order to attract good candidates, organizations now need to do a lot of circus (am sure you know what I mean). Believe me, a job description could act as a billboard for the job opening.</p>
             <p>I'd like to draw an analogy between a job description and a movie trailer. A well-written job description is like a movie trailer and can attract potential candidates in the same way that a trailer can attract its audience to a movie.</p>
          </div>
          <div className='details'>
             <h2>WRITING A WISE JD</h2> 
             <p>The best person to write a JD would be the immediate supervisor of the open position. One additional pair of eyes, either from HR or someone from the same team to validate, would make it perfect</p>
             <p>What should a smart JD include: Title, Internal pegging level in the organization, department, location, position reporting to, key technical skills required, behavioral skills required</p>
             <p>It needs to be written with the best choice of words and in such a way that expectation setting happens seamlessly between both parties (employer and candidate).</p>
          </div>
          <div className='details'>
             <h2>TOOL</h2> 
             <p>Using augmented writing for job descriptions is a trend these days. It’s a technology that serves as a writing coach and a virtual assistant for writing job descriptions. An augmented writing tool analyses the words you use in job descriptions and gives suggestions for more diversity and inclusion. It also helps you choose the right choice of words, which is far more welcoming to applicants. These days, more organizations are adapting to using such tools, technology and AI in talent acquisition to attract and hire the best talent and make a better business impact.</p>
          </div>
          <div className='details'>
             <h2>CHECKING AND VALIDATING THE QUALITY OF YOUR JD</h2> 
             <p>If you are writing a JD for a particular position, in parallel, try to write two more JD’s for one position above and one position below to check how things could get interchanged and how it becomes a puzzle! The outcome could be a mirror of the JD.</p>
          </div>
          <div className='details'>
             <h2>BEST PRACTICE</h2> 
             <p>Internally, while it’s good to have a JD in place for every open position, it would also act as documentation safeguarding the interests of the organization.</p>
          </div>
          <div className='details'>
             <h2>CONCLUSION</h2> 
             <p>Providing an effective job description shows the level of interest an organization has in hiring for a position and, most importantly, it would serve the purpose of attracting the right talent.</p>
          </div> */}
          </Col>
        </Row>
      </section>
    </>
  )
}

export default AdviceDetails
