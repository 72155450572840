import React, { useState } from "react";
import { Row } from "antd";
import { blogVideoData } from "../../../services/MediaData";
import VideoModal from "../../components/VideoModal/VideoModal";
import { PageContentEmployerScreen } from "../../components/PageContent/PageContent";
import { Banner } from "../../components/BlogDetails/banner";
import { aboutUs } from "../../../services/EmployerHeaderData";
import VideosCard from "../../components/videos/videosCard";
import { BredcrumbEmployer } from "../../components/common/bredcrumbEmployer";

const Videos = props => {
  const [showVideoModal, changeVisibilityModal] = useState(false)
  const [selectedItem, changeselectedItem]: any = useState({})

  return (
    <React.Fragment>
      {showVideoModal && (
        <VideoModal
          onCloseModal={() => changeVisibilityModal(false)}
          data={selectedItem}
        />
      )}
      <PageContentEmployerScreen location={props.location}>
        <Banner />
        <BredcrumbEmployer
          home_path={'/employer'}
          home={'Home'}
          perent={'About Us'}
          data={aboutUs}
          current={'Videos'}
        />
        <Row gutter={[12, 12]} className="custom-ant-row px-3 pb-5 px-md-6">
          {blogVideoData.map(data => (
            <VideosCard data={data} />
          ))}
        </Row>
      </PageContentEmployerScreen>
    </React.Fragment>
  )
}

export default Videos
