import React, { Fragment } from "react";
import { Row, Col, Breadcrumb, Card, Divider } from "antd";
import { Link } from "gatsby";
import { PressReleaseData } from "../../../../services/PressReleaseData";
import  {
  PageContentEmployerScreen,
} from "../../../components/PageContent/PageContent";
import banner_img1 from "../../../images/PressRelease/banner/NSDC-Adecco.webp";
import backIcon from "../../../images/icons/icons8-back-24.webp";
import fbIcon from "../../../images/icons/facebook.webp";
import ldIcon from "../../../images/icons/linkedin.webp";
import ttIcon from "../../../images/icons/twitter.webp";
import "./common.less";

export default function PressRelease(props) {
  const data={
    press_id:"PRESS0012",
    id:
      "nsdl_international",
      banner_img: banner_img1,
    category: "General News",
    date: "New Delhi, 12 December 2023",
    title:
      "NSDC International and The Adecco Group forge strategic partnership to facilitate International Employment Opportunities for Skilled Indian Candidates",
    description:
      "NSDC International (NSDCI), wholly owned subsidiary of National Skill Development Corporation (NSDC) and global workforce solutions company, The Adecco Group today announced a transformative collaboration aimed at fostering international mobility for proficient, certified Indian candidates. This strategic partnership framework seeks to unlock the vast potential of India’s youth for mutually beneficial opportunities and leverage the strengths of both the organizations.",
    content: ` <h1 class="text-align-center">NSDC International and The Adecco Group forge strategic partnership to facilitate International Employment Opportunities for Skilled Indian Candidates</h1>
    <p> <b><u>New Delhi, 12 December 2023: </u></b> NSDC International (NSDCI), wholly owned subsidiary of National Skill Development Corporation (NSDC) and global workforce solutions company, The Adecco Group today announced a transformative collaboration aimed at fostering international mobility for proficient, certified Indian candidates. This strategic partnership framework seeks to unlock the vast potential of India’s youth for mutually beneficial opportunities and leverage the strengths of both the organizations.</p>
    <p>Recognizing the global demand for skilled professionals, the partnership envisions to promote the employment of qualified Indian candidates in several countries including Germany, Spain, Canada, and Australia. The focus sectors will be Healthcare, IT & Engineering, Logistics, and other sectors identified collaboratively. The partnership will create pathways for skilled Indian candidates to access international employment opportunities through a multifaceted approach.</p>
    <p>Speaking on the collaboration, <b>Ved Mani Tiwari, CEO, NSDC and MD, NSDC International,</b> said, ‘This collaboration aligns with our commitment to empower skilled Indian candidates by offering them opportunities to showcase their expertise on a global stage. And to bridge the gap between Indian talent and global employers, we are ensuring a smoother transition and enhancing the competitiveness of Indian candidates through our skilling initiatives. With this vision, our collaboration with The Adecco Group, renowned for its global workforce solutions, marks the commencement of strong global prospects for India's workforce. Central to our vision is the evolution of a comprehensive skill development framework, enhancing synchronization between demand and supply through vocational and technical training.’</p>
    <p><b>Ian Lee, President, Geographic Regions, The Adecco Group, said,</b> ‘Through this partnership with NSDC International, The Adecco Group is championing a future where Indian talent converges seamlessly with global industries. This alliance reflects our dedication to bridging gaps and cultivating a dynamic, internationally linked workforce across sectors such as Healthcare, IT & Engineering, and specialized roles."</p>
    <p>The global landscape grapples with a talent deficit, notably in regions where the demand for skills—particularly in green and digitally-driven occupations—far surpasses their availability. And this challenge brims with the opportunity for establishing sustainable solutions in the evolving work landscape through continuous upskilling and reskilling of the workforce. Adapting to this new world of work hinges upon nurturing a more agile, versatile workforce equipped to meet the demands of today's dynamic job market.</p>
    <p>In this collaboration, The Adecco Group will actively interface with a range of stakeholders striving for a seamless process for the onboarding of the candidates. Acting as the pivotal entity, The Adecco Group will meticulously outline comprehensive job descriptions, delineating candidate eligibility criteria and technical prerequisites. Moreover, NSDCI will facilitate the smooth transition of professionals from India to the destination country. This concerted effort aims to facilitate a frictionless pathway towards international employment opportunities.</p>
    <h4><b>About National Skill Development Corporation (NSDC)</b></h4>
    <p>Giving boost to Government Skill India Mission, National Skill Development Corporation (NSDC), a nodal skill development agency, working under the Ministry of Skill Development & Entrepreneurship (MSDE), Government of India, is a unique Public Private Partnership (PPP) that aims to catalyse the creation of a large and quality vocational training ecosystem in India. Since inception in 2010, NSDC has trained over three crore people through its collaboration with training partners pan India.</p>
    <p>NSDC has established 37 Sector Skill Councils (SSCs) and implements the Government’s flagship skill development schemes such as Pradhan Mantri Kaushal Vikas Yojana (PMKVY), National Apprenticeship Promotion Scheme (NAPS), among others. NSDC also funds enterprises, companies and organisations that provide skill training. The organisation enables private-sector capacity building in skill development by offering concessional loans, other innovative financial products, and strategic partnerships.</p>
    <h4><b>About The Adecco Group</b></h4>
    <p>The Adecco Group is the world’s leading talent advisory and solutions company. We believe in making the future work for everyone, and every day enable more than 3.5 million careers. We skill, develop and hire talent in 60 countries, enabling organisations to embrace the future of work. We lead by example, creating shared value that fuels economies, and builds better societies. Our culture of inclusivity, fairness and teamwork empowers our employees.</p>
    <p>The Adecco Group is headquartered in Zurich, Switzerland and listed on the SIX Swiss Exchange.</p>
    <h4><b>For further information please contact:</b></h4>
    <p>Cauvery Uthappa | <a href="mailto:cauvery.uthappa@adecco.com" class="taxt-dark">cauvery.uthappa@adecco.com</a> | <a href="tel:+919845172958" class="taxt-dark">+919845172958</a></p>`,
    link:
      "/employer/press-release/press-release-12",
  }

  

  const listData: any = []
  for (let i = 0; i < 6; i++) {
    listData.push({
      href: "https://ant.design",
      title: `ant design part ${i}`,
      avatar: "https://joeschmoe.io/api/v1/random",
      description:
        "Ant Design, a design language for background applications, is refined by Ant UED Team.",
      content:
        "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
    })
  }
  return (
    <>
      {data?.banner_img && (
        <section className="PressSection">
          <Row>
            <div className="hero-section" style={{backgroundImage: `url('${data.banner_img}')`}}>
              <div className="overlay"></div>
              <div className="content-section"></div>
            </div>
          </Row>
        </section>
      )}

      <PageContentEmployerScreen
        location={props.location}
      >
        <Row className="custome-breadcrumb mt-5">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/employer/press-release"}>
                  <img loading="lazy"  src={backIcon} /> Back to Press Release
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="float-right">
            <div className="d-flex">
              <span>Share|</span>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={fbIcon} alt="Facebook" />
              </a>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={ldIcon} alt="linkedin" />
              </a>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={ttIcon} alt="twitter" />
              </a>
            </div>
          </Col>
        </Row>
        <Row type="flex" className="mobile-flex">
          <Col lg={9} md={24} className="px-3 related-press-release-card">
            <Card title="Related Press Release" size="small" className="">
              {PressReleaseData.map(
                (data, i) =>
                  i > 4 && (
                    <Fragment key={data.id}>
                      <div className="d-flex">
                        <img loading="lazy" 
                          src={data.releted_img}
                          alt="img"
                          className="rounded"
                          width={100}
                        />
                        <Link
                          to={data.link}
                          className="ml-2 fw-600 press-title"
                        >
                          {data.title}
                        </Link>
                      </div>
                      <Divider />
                    </Fragment>
                  )
              )}
            </Card>
          </Col>
          <Col lg={14} md={24} className="press-release-section">
            {data && (
              <Row
                className="fw-500 font-normal"
                dangerouslySetInnerHTML={{ __html: data?.content }}
              ></Row>
            )}
          </Col>
        </Row>
      </PageContentEmployerScreen>
    </>
  )
}
