import React from "react";
import {  Col, Row } from "antd";
import SliderTestimonial from "react-slick";
import { testimonialsData } from "../../../services/adecco-defense-alliance-data";
import Arrow from "../../images/sliderArrow.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./common.less";

function NextArrow(props) {
  const { onClick } = props
  return (
    <div className="nextArrow" onClick={onClick}>
      <img loading="lazy"  src={Arrow} alt={'nextArrow'}/>
    </div>
  )
}

function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className="prevArrow" onClick={onClick}>
      <img loading="lazy"  src={Arrow} alt={'prevArrow'}/>
    </div>
  )
}

const storiesData = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const testimonialData = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
}

const ItemList=({data})=>{
  return(
    <div className="items" >
                  <div className="testiDataNew">
                    <img loading="lazy"  src={data.img} alt={'testiDataNew'}/>
                    <div className="dataNew">
                      <h4>{data.desc}</h4>
                      <div className="designation">
                        <p style={{ fontSize: "24px" }}>{data.name}</p>
                        <p>{data.sub_name}</p>
                        <p>Adecco India</p>
                      </div>
                    </div>
                  </div>
                </div>
  )
}

const Testimonials = ({ heading, isReadMore }) => {
  return (
    <>
      <section className="testimonial-Section px-md-6  pt-3">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="heading">{heading && <h3>{heading}</h3>}</div>
          </Col>
        </Row>
      </section>
      <section className="testimonialSection">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <SliderTestimonial {...testimonialData}>
              {testimonialsData?.map((data, index) => <ItemList data={data} key={data.name}/>)}
            </SliderTestimonial>
          </Col>
        </Row>
      </section>
    </>
  )
}

export default Testimonials
