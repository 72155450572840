import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "antd";
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent";
import { NewBanner } from "../../../components/Solutions/newBanner";
import BreadcrumbSec from "./Breadcrumb";
import Sidebar from "./sidebar";
import QualityImg from "../../../images/solutions/PS/Quality.webp";
import ControlImg from "../../../images/solutions/PS/control.webp";
import PricingImg from "../../../images/solutions/PS/Pricing.webp";
import SpeedImg from "../../../images/solutions/PS/Speed.webp";
import banImg from "../../../images/solutions/banner/Professional-Staffing.webp";
import "./common.less";
import Stories from "../../../components/employerHomePage/stories";

const ProfessionalStaffing = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <NewBanner BannerImg={banImg} title={"Professional Staffing"} />
      <BreadcrumbSec current={"Professional Staffing"} />
      <section className="mainSolutionSection px-3 px-md-6">
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="float-right">
            <div className="box">
              <div className="heading">
                <h1>Professional Staffing</h1>
                <span className="separator"></span>
              </div>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">
                  Attracting top tech talent is critical, but made easier for
                  your organization
                </h2>
              </div>
              <p className={""}>
                Professional Staffing provides next-generation flexible
                workforce solutions to help your organization hire specialists
                for high-end skill fulfillment across mainstream and niche
                technologies. We help the world’s leading industry brands meet
                their temporary and project-related staffing needs and maintain
                high levels of service to our clients. Our professional legacy
                and deep industry expertise will enable you to better operate in
                the fast-paced technology environment. Our focus is to help
                expand your organization’s access to in-demand skills with
                increased competitiveness. For us, it means we accompany you as
                a partner and drive real value.
              </p>
              <p>
                Our recruitment consultants carry strong industry expertise and
                understand the pulse of the market and have a solid background
                and understanding of global and local trends. We offer clients
                business-critical recommendations that take into consideration
                levers such as current market trends and regulatory compliance.
                We constantly track the changing dynamics and provide talent
                advisory for the technology labour market. We work closely with
                our clients to guide, manage, and successfully bring the right
                professionals and organizations together through innovative
                professional staffing solutions.
              </p>
              <p className={"text-danger text-bold"}>
                As a leader amongst professional staffing companies in India, we
                understand that sourcing the right talent requires close
                consultation and our established engagement models guarantee
                your recruitment process runs smoothly.
              </p>

              <div className="solution-icons">
                <h2 className="title">
                  A partnership to drive businesses forward
                </h2>
                <div className="icon-sec justify-space-around">
                  <div className="icon">
                    <img loading="lazy"  src={QualityImg} alt="QualityImg" />
                    <h3>Quality</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={SpeedImg} alt="SpeedImg" />
                    <h3>Speed</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={PricingImg} alt="PricingImg" />
                    <h3>Right pricing</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={ControlImg} alt="ControlImg" />
                    <h3>Total Control: Pre & Post - selection</h3>
                  </div>
                </div>
              </div>
              <p>
                This also ensures we are aligned with the needs of our
                candidates and maintain a large network of qualified
                professionals in our database. With quick access to
                high-caliber, fully vetted candidates we can source highly
                skilled and proven interim resources quickly and efficiently.
                All our candidates are rigorously assessed to make sure they
                have the right skill set to meet your talent demand.
              </p>
              <p>
                Our suite of professional staffing services for the Information
                Technology and Engineering sector will enable you to focus on
                your core business and let us take on the rest. We leverage our
                digital technology and innovation expertise upgrading the tools
                available to our delivery team and making our operations more
                efficient. We take complete ownership of everything from your
                organization's one-off or cyclical challenges to complex
                projects. Simply put, we invest the right people and resources –
                so you can focus on your core business. In addition, our
                consultants will help you re-evaluate your current talent
                management ecosystem and work closely to help you transform your
                workforce.
              </p>
              <div className="text-center">
                <Link
                  to="/employer/business-enquiry/"
                  state={{ interested: "Professional Staffing" }}
                >
                  Business Enquiry
                </Link>
              </div>
            </div>
          </Col>
          <Sidebar />
        </Row>
        <Stories heading="Case Study" isReadMore={false} />
      </section>
    </PageContentEmployerScreen>
  )
}
export default ProfessionalStaffing
