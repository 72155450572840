import React from "react";
import { navigate } from "gatsby";
import { Button, Col, Row } from "antd";
import registerImg from "../../images/jobSeekerHome/ragisterImg.webp";

const Register = () => {
  return (
    <Row className="registerSection">
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <img loading="lazy"  className="ragisterImg" src={registerImg} alt="registerImg"/>
        <Col
          xs={24}
          sm={24}
          md={14}
          lg={14}
          xl={14}
          className="float-md-right pl-3 pr-3 pl-md-0 pr-md-0 pr-lg-6"
        >
          <div className="data">
            <h3>Register with Adecco</h3>
            <p>
              Do you want to get started, but have not found the ideal job
              vacancy yet? Register with us and take advantage of these
              benefits:
            </p>
            <ul>
              <li>
                Upload your resume and you will be contacted when we have a job
                that suits you
              </li>
              <li>You can also refer your friends and family members</li>
            </ul>
            <Button type="danger" size="large" className="registerBtn" onClick={()=>navigate('/submit-your-cv/')}>
              Submit your CV
            </Button>
          </div>
        </Col>
      </Col>
    </Row>
  )
}

export default Register
