import React from "react";
import { Col, Row } from "antd";
import BannerImg from "../../images/Awards&Recognition-banner.webp";
import "./common.less";

export const Banner = () => {
  return (
    <section className="blogBannerSection">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div
            className="hero-section"
            style={{ backgroundImage: `url(${BannerImg})` }}
          >
            <div className="overlay"></div>
            <div className="content-section">
              <h1>Videos</h1>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  )
}
