import React from "react";
import { Card, Col, Row } from "antd";
import { WrappedReferAFriendForm } from "../Forms/ReferAFriendForm";
import { formSectionData } from "../../../services/referAFriend";
import CustomeBreadcrumb from "../common/breadcrumb";
import "./common.less";

const ReferForm = () => {
  return (
    <section className="referFormSection">
      <CustomeBreadcrumb
        home_path={"/"}
        home={"Home"}
        current={"Refer a Friend"}
      />
      <Row gutter={[24, 24]} className="px-3 px-md-6 m-0 mt-5 mb-5">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="ml-auto mr-auto"
        >
          <div className="contact-text">
            {formSectionData?.desc?.map(data => (
              <p key={data}>{data}</p>
            ))}
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          className="ml-auto mr-auto"
        >
          <Card title={"Refer a Friend"} className="referCard">
            <WrappedReferAFriendForm />
          </Card>
        </Col>
      </Row>
    </section>
  )
}

export default ReferForm
