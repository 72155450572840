import img1 from "../src/images/pf-help-center/1-My-hub-portal.webp";
import img2 from "../src/images/pf-help-center/2-Activate-UAN&-KYC.webp";
import img3 from "../src/images/pf-help-center/3-PF-Statement.webp";
import img4 from "../src/images/pf-help-center/4-PF-Transfer.webp";
import img5 from "../src/images/pf-help-center/5-PF-part-withdrawal.webp";
import img6 from "../src/images/pf-help-center/6-PF-withdrawal.webp";

export const PfData = [
  {
    id: 1,
    img: img1,
    title: "My Hub Portal",
    hindi_link: "https://youtu.be/2UCbKHwtuXo",
    hindi_iframe: `<iframe width="100%" height="235" src="https://www.youtube.com/embed/2UCbKHwtuXo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    english_link: "https://youtu.be/EKcHBzfuVfI",
    english_iframe: `<iframe width="100%" height="235" src="https://www.youtube.com/embed/EKcHBzfuVfI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
  {
    id: 2,
    img: img2,
    title: "Activate UAN & KYC",
    hindi_link: "https://youtu.be/NjViO6AYF5Q",
    hindi_iframe: `<iframe width="100%" height="235" src="https://www.youtube.com/embed/NjViO6AYF5Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    english_link: "https://youtu.be/nmafRm4kOTE",
    english_iframe: `<iframe width="100%" height="235" src="https://www.youtube.com/embed/nmafRm4kOTE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
  {
    id: 3,
    img: img3,
    title: "PF Statement",
    hindi_link: "https://youtu.be/c3UVdT0Et6g",
    hindi_iframe: `<iframe width="100%" height="235" src="https://www.youtube.com/embed/c3UVdT0Et6g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    english_link: "https://youtu.be/a1HzN2DJjpo",
    english_iframe: `<iframe width="100%" height="235" src="https://www.youtube.com/embed/a1HzN2DJjpo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
  {
    id: 4,
    img: img4,
    title: "PF Transfer",
    hindi_link: "https://youtu.be/rbCMF8yqJbs",
    hindi_iframe: `<iframe width="100%" height="235" src="https://www.youtube.com/embed/rbCMF8yqJbs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    english_link: "https://youtu.be/Sjw8MH7JybM",
    english_iframe: `<iframe width="100%" height="235" src="https://www.youtube.com/embed/Sjw8MH7JybM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
  {
    id: 5,
    img: img5,
    title: "PF Part Withdrawal",
    hindi_link: "https://youtu.be/r4dFbEjxNMQ",
    hindi_iframe: `<iframe width="100%" height="235" src="https://www.youtube.com/embed/r4dFbEjxNMQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    english_link: "https://youtu.be/WmdJTUE__cY",
    english_iframe: `<iframe width="100%" height="235" src="https://www.youtube.com/embed/WmdJTUE__cY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
  {
    id: 6,
    img: img6,
    title: "PF Withdrawal",
    hindi_link: "https://youtu.be/xxB__T1dir0",
    hindi_iframe: `<iframe width="100%" height="235" src="https://www.youtube.com/embed/xxB__T1dir0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    english_link: "https://youtu.be/cyXgRlAePG4",
    english_iframe: `<iframe width="100%" height="235" src="https://www.youtube.com/embed/cyXgRlAePG4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
]
