import AdeccoSingaporeSalaryGuide2024 from '../src/pdf/new/all/AdeccoSingaporeSalaryGuide2024.pdf';
import Singapore2024 from '../src/images/salary-guid/cards/Singapore.webp'

import AdeccoTaiwanSalaryGuide2024 from '../src/pdf/new/all/AdeccoTaiwanSalaryGuide2024.pdf';
import Taiwan2024 from '../src/images/salary-guid/cards/Taiwan.webp'

import Adecco_SalaryGuide2024_HongKong from '../src/pdf/new/all/Adecco_SalaryGuide2024_HongKong.pdf';
import Hongkong2024 from '../src/images/salary-guid/cards/HongKong.webp'

import AdeccoSalaryGuide2024Australia from '../src/pdf/new/all/Adecco-Salary-Guide-2024Australia.pdf';
import Australia_2024 from '../src/images/salary-guid/cards/Australia.webp'

import AdeccoThailandSalaryGuide2024 from '../src/pdf/new/all/AdeccoThailandSalaryGuide2024.pdf';
import Thailand_2024 from '../src/images/salary-guid/cards/Thailand.webp'

import AdeccoVNSalaryGuide2024 from '../src/pdf/new/all/AdeccoVNSalaryGuide2024.pdf';
import Vietnam_2024 from '../src/images/salary-guid/cards/Veitnam.webp'




const SalaryData = [
{
    id:"Adecco-Singapore-Salary-Guide-2024",
    image: Singapore2024,
    date:'October, 2023',
    title:"Adecco Singapore Salary Guide 2024",
    docLink:AdeccoSingaporeSalaryGuide2024,
    desc:`Get access to labor market trends and salary reports from different countries in APAC.`,
    link:"https://adecco.co.th/en/knowledge-center/detail/salary-guide/salary-guide-2023-adecco-in-apac",
},
{
    id:"Adecco-Taiwan-Salary-Guide-2024",
    image: Taiwan2024,
    docLink:AdeccoTaiwanSalaryGuide2024,
    title:"Adecco Taiwan Salary Guide 2024",
    desc:`Adecco Thailand Salary Guide 2023: Get the latest salary insights of more than 800 positions`,
    link:"https://adecco.co.th/en/knowledge-center/detail/salary-guide/salary-guide-2023-adecco-in-apac",
},
{
    id:"Adecco-Hong-Kong-Salary-Guide-2024",
    image: Hongkong2024,
    docLink:Adecco_SalaryGuide2024_HongKong,
    title:"Adecco Hong Kong Salary Guide 2024",
    desc:`Get access to labor market trends and salary reports from different countries in APAC.`,
    link:"https://adecco.co.th/en/knowledge-center/detail/salary-guide/salary-guide-2023-adecco-in-apac",
},
{
    id:"Adecco-Australia-Salary-Guide-024",
    image: Australia_2024,
    docLink:AdeccoSalaryGuide2024Australia,
    title:"Adecco Australia Salary Guide 2024",
    desc:`Adecco Thailand Salary Guide 2023: Get the latest salary insights of more than 800 positions`,
    link:"https://adecco.co.th/en/knowledge-center/detail/salary-guide/salary-guide-2023-adecco-in-apac",
},
{
    id:"Adecco-Thailand-Salary-Guide-2024",
    image: Thailand_2024,
    docLink:AdeccoThailandSalaryGuide2024,
    title:"Adecco Thailand Salary Guide 2024",
    desc:`Adecco Thailand Salary Guide 2023: Get the latest salary insights of more than 800 positions`,
    link:"https://adecco.co.th/en/knowledge-center/detail/salary-guide/salary-guide-2023-adecco-in-apac",
},
{
    id:"Adecco-Vietnam-Salary-Guide-2024",
    image: Vietnam_2024,
    docLink:AdeccoVNSalaryGuide2024,
    title:"Adecco Vietnam Salary Guide 2024",
    desc:`Adecco Thailand Salary Guide 2023: Get the latest salary insights of more than 800 positions`,
    link:"https://adecco.co.th/en/knowledge-center/detail/salary-guide/salary-guide-2023-adecco-in-apac",
},
] 
export { SalaryData }