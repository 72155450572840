import React from "react";
import DefenceBanner from "../components/AboutUs/defenceBanner"
import DownloadOurBrochure from "../components/AboutUs/download-our-brochure"
import WhatIsDefense from "../components/AboutUs/what-is-defense"
import Testimonials from "../components/employerHomePage/testimonials"
import { PageContentSubmitCvScreen } from "../components/PageContent/PageContent"
import LinkAndResources from "../components/AboutUs/link-and-resources";

const VeteranHiring = props => {
  return (
    <PageContentSubmitCvScreen location={props.location}>
      <DefenceBanner />
      <WhatIsDefense />
      <LinkAndResources/>
      <Testimonials heading="Testimonials" isReadMore={false} />
      <DownloadOurBrochure />
    </PageContentSubmitCvScreen>
  )
}

export default VeteranHiring;
