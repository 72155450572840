import { Menu } from "antd"
import { Link } from "gatsby"
import React, { Fragment } from "react"

const RenderMenu = props => (
  <Menu>
    {props.data.map(aboutUsData =>
      aboutUsData?.sub_menu ? (
        aboutUsData?.sub_menu.map(sub => (
          <Menu.Item key={sub.url}>
            {sub.external ? (
              <a href={sub.url} target={sub.target || ""}>
                {sub.data.split("\n").map(menuItem => {
                  return (
                    <Fragment key={menuItem}>
                      {menuItem}
                      <br />
                    </Fragment>
                  )
                })}
              </a>
            ) : (
              <Link to={sub.url} target={sub.target || ""}>
                {sub.data.split("\n").map(menuItem => {
                  return (
                    <Fragment key={menuItem}>
                      {menuItem}
                      <br />
                    </Fragment>
                  )
                })}
              </Link>
            )}
          </Menu.Item>
        ))
      ) : (
        <Menu.Item key={aboutUsData.url}>
          {aboutUsData.external ? (
            <a href={aboutUsData.url} target={aboutUsData.target || ""}>
              {aboutUsData.data.split("\n").map(menuItem => {
                return (
                  <Fragment key={menuItem}>
                    {menuItem}
                    <br />
                  </Fragment>
                )
              })}
            </a>
          ) : (
            <Link to={aboutUsData.url} target={aboutUsData.target || ""}>
              {aboutUsData.data.split("\n").map(menuItem => {
                return (
                  <Fragment key={menuItem}>
                    {menuItem}
                    <br />
                  </Fragment>
                )
              })}
            </Link>
          )}
        </Menu.Item>
      )
    )}
  </Menu>
)

export default RenderMenu
