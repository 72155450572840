import React from "react";
import { Row, Col } from "antd";
import moment from "moment"
import { PressReleaseData } from "../../../../services/PressReleaseData";
import {
  PageContentEmployerScreen,
} from "../../../components/PageContent/PageContent";
import PressReleaseCardLayout from "../../../components/PressReleaseCard/PressReleaseCardLayout";
import {
  Modal,
  Button,
  Card,
  Radio,
  DatePicker,
  Form,
  Pagination,
} from "antd";
import { media } from "../../../../services/HeaderData";
import { WrappedMediaEnquiryForm } from "../../../components/Forms/MediaInquiryFrom";
import { BredcrumbEmployer } from "../../../components/common/bredcrumbEmployer";
import { ScrollToTop } from "../../../helper/dimensionHelper";

const { RangePicker } = DatePicker

const cardSize = 6

const PressRelease = props => {
  const dateFormat = "YYYY/MM/DD"
  const [value, setValue] = React.useState("All")
  const [dataFilter, setDataFilter] = React.useState(PressReleaseData)
  const [dateRange, setDateRange] = React.useState([moment(), moment()])
  const [current, setCurrent] = React.useState(1)
  const [minIndex, setMinIndex] = React.useState(0)
  const [maxIndex, setMaxIndex] = React.useState(cardSize)
  const [counter, setCounter] = React.useState(0)
  const [visible, setVisible] = React.useState(false)
  const [clearFilter, setClearFilter] = React.useState(false)

  React.useEffect(() => {
    counter < 11 && setTimeout(() => setCounter(counter + 1), 1000)
    if (counter === 10) {
      setVisible(true)
    }
  }, [counter])

  React.useEffect(() => {
    if (PressReleaseData) {
      const filterData = PressReleaseData.filter(data => {
        if (value === "All") {
          return PressReleaseData
        } else {
          return data.category === value
        }
      })
      setDataFilter(filterData)
    }
  }, [value])

  const handleCancel = () => {
    setVisible(false)
  }

  const onChange = e => {
    setValue(e.target.value)
  }

  const handleChangePagination = page => {
    setCurrent(page)
    setMinIndex((page - 1) * cardSize)
    setMaxIndex(page * cardSize);
    ScrollToTop();

  }

  const filterDate = (filterVals, data) => {
    let min = new Date(filterVals[0].format("YYYY-MM-DD"))
    let max = new Date(filterVals[1].format("YYYY-MM-DD"))
    const filteredData = data.filter(row => {
      let datejs = new Date(row.date)
      return min && datejs >= min && max && datejs <= max
    })
    setDataFilter(filteredData)
    setClearFilter(true)
  }

  function handleClearFilter() {
    setDataFilter(PressReleaseData)
    setClearFilter(false)
    setDateRange([])
  }

  return (
    <>
      <Row>
        <div className="hero-section">
          <div className="overlay"></div>
          <div className="content-section">
            <h1>Press Release</h1>
          </div>
        </div>
      </Row>
      <Modal
        title="Media Enquiry"
        visible={visible}
        footer={null}
        onCancel={handleCancel}
      >
        <WrappedMediaEnquiryForm span1={5} span2={19} />
      </Modal>
      <PageContentEmployerScreen location={props.location} pageSize={24}>
        <Row className="mt-8 p-3">
        <BredcrumbEmployer
          home_path={'/employer'}
          home={'Home'}
          perent={'News'}
          data={media}
          current={'Press Release'}
        />
         
        </Row>
        <Row type="flex" justify="space-between" className="p-3">
          <Col
            xs={24}
            xl={6}
            lg={6}
            md={8}
            sm={10}
            className="category-card pressCatCard"
          >
            <Card title="Browse by Category">
              <Radio.Group onChange={onChange} value={value}>
                <div className="d-flex flex-column w-100">
                  <Radio value={"All"}>All</Radio>
                  <Radio value={"Corporate Social Responsibility"}>
                    Corporate Social Responsibility
                  </Radio>
                  <Radio value={"Executive, Staff And"}>
                    Executive, Staff And Employee Announcement
                  </Radio>
                  <Radio value={"General News"}>General News</Radio>
                  <Radio value={"Thought Leadership"}>Thought Leadership</Radio>
                </div>
              </Radio.Group>
            </Card>
            <Card title="Browse by Date " className="mt-3">
              <Form.Item label="Start Date - End Date" colon={false}>
                <RangePicker
                  format="MMM DD, YYYY"
                  value={dateRange}
                  separator={"-"}
                  onChange={x => {
                    filterDate(x, dataFilter)
                    setDateRange(x)
                  }}
                  allowClear={false}
                />
                {clearFilter && (
                  <Button
                    icon="close"
                    className="ml-2"
                    onClick={handleClearFilter}
                  >
                    Clear Filter
                  </Button>
                )}
              </Form.Item>
            </Card>
          </Col>
          <Col xs={24} xl={17} lg={17} md={14} sm={13}>
            <PressReleaseCardLayout
              data={dataFilter}
              minIndex={minIndex}
              maxIndex={maxIndex}
            />
          </Col>
        </Row>
        <Row type="flex" justify="center" className="my-3">
          <Pagination
            pageSize={cardSize}
            current={current}
            total={PressReleaseData.length}
            onChange={e => handleChangePagination(e)}
          />
        </Row>
      </PageContentEmployerScreen>
    </>
  )
}

export default PressRelease
