import React from "react";
import { Link } from "gatsby";
import { Breadcrumb, Col, Row } from "antd";

const CustomeBreadcrumb = ({ home_path, home, current }) => {
  return (
    <Row className="custome-breadcrumb m-4">
      <Col>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={home_path}>{home}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="">{current}</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Col>
    </Row>
  )
}

export default CustomeBreadcrumb
