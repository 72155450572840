import React from "react"
import { Link } from "gatsby"
import { Col, Pagination, Row } from "antd"
import { AdviceData } from "../../../services/jobSekeerHomePageData"
import { ScrollToTop } from "../../helper/dimensionHelper"

const CareerAdviceList = () => {
  const cardSize = 6
  const [current, setCurrent] = React.useState(1)

  const [minIndex, setMinIndex]: any = React.useState(0)
  const [maxIndex, setMaxIndex]: any = React.useState(cardSize)

  const handleChangePagination = page => {
    setCurrent(page)
    setMinIndex((page - 1) * cardSize)
    setMaxIndex(page * cardSize)
    ScrollToTop()
  }
  return (
    <section className="CareerAdviceSection px-3 px-md-6">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h2>Latest Career Advice</h2>
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        {AdviceData?.map(
          (m: any, index: any) =>
            parseInt(index) >= parseInt(minIndex) &&
            parseInt(index) < parseInt(maxIndex) && (
              <Col xs={24} sm={12} md={12} lg={8} xl={8} key={m.id}>
                <div className="box">
                  <div className="images">
                    <img loading="lazy" src={m.image} alt={m.title} />
                  </div>
                  <div className="details">
                    <Link className="link-title" to={m.link}>
                      <h4>{m.title}</h4>
                    </Link>
                    <p>{m.desc}</p>
                    <Link to={m.link}>Read More</Link>
                  </div>
                </div>
              </Col>
            )
        )}
      </Row>
      <Row className="text-center">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-4 mb-4">
          <Pagination
            pageSize={cardSize}
            current={current}
            total={AdviceData?.length}
            onChange={e => handleChangePagination(e)}
          />
          {/* <Pagination className="text-center" defaultCurrent={1} total={100} /> */}
        </Col>
      </Row>
    </section>
  )
}

export default CareerAdviceList
