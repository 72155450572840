import React from "react";
import { navigate } from "gatsby";
import { Col, Row } from "antd";
import BannerImg from "../../images/ADA/ADA-Banner.webp";
import "./common.less";

const DefenceBanner = () => {
  return (
    <section
      className="adaBannerSection px-3 px-md-6 "
      style={{ backgroundImage: `url(${BannerImg})` }}
    >
      <Row className="py-8">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h1 className="text-center">Adecco Defense Alliance</h1>
          <div className="text-center head-btn pt-3">
            <button
              onClick={() => navigate("/employer/business-enquiry/")}
              className="mr-2  ant-btn fw-700 w-100 w-sm-auto h-6 px-4 ant-btn-primary ant-btn-lg"
            >
              Looking to hire
            </button>
            <button
              onClick={() => navigate("/veteran-submission-form/")}
              className="ml-2  ant-btn fw-700 w-100 w-sm-auto h-6 px-4 ant-btn-primary ant-btn-lg"
            >
              Looking for a job
            </button>
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default DefenceBanner
