
export const solutions = [
  {
    data: "Temporary Staffing",
    url: "/staffing-solutions/#general_staffing",
    key: "general_staffing",
    sub_menu: [
      {
        data: "General Staffing",
        url: "/employer/solutions/general-staffing/",
        key: "general_staffing",
      },
      {
        data: "Outsourcing Services",
        url: "/employer/solutions/outsourcing-services/",
        key: "/outsourcing-services",
      },
      {
        data: "Professional Staffing",
        url: "/employer/solutions/professional-staffing/",
        key: "/professional-staffing",
      },
      {
        data: "Hire Train Deploy",
        url: "/employer/solutions/hire-train-deploy/",
        key: "/professional-staffing",
      },
    ],
  },
  {
    data: "Field Sales and Marketing Outsourcing",
    url: "/employer/solutions/field-sales-marketing-outsourcing-services/",
    key: "/field-sales-marketing-outsourcing-services"
  },
  {
    data: "Permanent Recruitment",
    url: "/employer/solutions/permanent-recruitment/",
    key: "/permanent-placement"
  },
  {
    data: "RPO Lite",
    url: "/employer/solutions/rpo-services/",
    key: "/rpo-services",
  },
  {
    data: "Payroll Outsourcing",
    url: "/employer/solutions/payroll-outsourcing-services/",
    key: "/payroll-outsourcing-services",
  },
  {
    data: "Enterprise Solutions",
    url: "/employer/solutions/enterprise-solutions/",
    key: "professional_staffing"
  },
  {
    data: "International Engagement",
    url: "/employer/solutions/international-engagement/",
    key: "professional_staffing",
  },
  {
    data: "HR Advisory & Consulting Services",
    url: "/employer/solutions/hr-advisory-and-consulting-service/",
    key: "hr_advisory_consulting_services",
  },
  {
    data: "Training & Skilling",
    url: "/employer/solutions/training-and-skilling/",
    key: "/training-and-skilling",
  },
]

export const media = [
  { data: " Media Coverage", url: "/employer/media-coverage/" },
  { data: "Press Release", url: "/employer/press-release/" },
  { data: "Media Enquiry", url: "/employer/media-enquiry/" },
]

export const aboutUs = [
  { data: "About Us", url: "/employer/about-us/" },
  { data: "Our Values", url: "/employer/our-values/" },
  {
    data: "Videos",
    url: "/employer/videos/",
  },
  {
    data: "The Adecco Group",
    target: "_blank",
    external: true,
    url: "https://www.adeccogroup.com/",
  },
  {
    data: "Case Studies",
    url: "/employer/case-study/",
  },
]

export const contactUs = [
  { data: "Business Enquiry", url: "/employer/business-enquiry/" },
  { data: "Locations", url: "/employer/locations/" },
]

export const jobSeeker = [
  {
    data: "Search for Jobs",
    url: "http://jobs.adecco.in/home/jobs",
    target: "_blank",
    external: true,
  },
  {
    data: "Work for Us",
    url: "https://careers.adeccogroup.com/",
    target: "_blank",
    external: true,
  },
  { data: "Submit Your CV", url: "/submit-your-cv/" },
  { data: "Refer a Friend", url: "/refer-a-friend/" },
]

export const menu = [
  { title: "Solutions", data: [...solutions], key: "/solutions" },
  { title: "News", data: [...media], key: "/media" },
  { title: "About us", data: [...aboutUs], key: "/employer/about_us" },
  { title: "Salary Guide",key:"employer/salary-guide", url: "/employer/salary-guide/" },
  { title: "Contact us", data: [...contactUs], key: "employer/contact_us" },
  // { title: "Case Studies", url: "/employer/case-study/" },
]

export const headerText = {
  clientHub: "Client Login",
  associateHub: "Associate Login",
  solutions: "Our Solutions",
  media: "News",
  contactUs: "Contact Us",
  aboutUs: "About Us",
  // success_Stories: "Case Studies",
  salary_guide: "Salary Guide",
  adeccoEmail: "asc@adecco.com",
  phoneNumber: "18004199233",
  supportTitle: "Associate Support:",
  businessEnqiury: "Business Enquiry",
  downloadOurApp: "Download Our App",
}
