import React from "react";
import { PageContentEmployerScreen } from "../../components/PageContent/PageContent";
import { Banner } from "../../components/CSR/banner";
import { CRS } from "../../components/CSR/crs";
import { aboutUs } from "../../../services/EmployerHeaderData";
import { BredcrumbEmployer } from "../../components/common/bredcrumbEmployer";

const SocialResponsibilty = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <Banner />
      <BredcrumbEmployer
          home_path={'/employer'}
          home={'Home'}
          perent={'About Us'}
          data={aboutUs}
          current={'Corporate Social Responsibility'}
        />
      <CRS />
    </PageContentEmployerScreen>
  )
}

export default SocialResponsibilty
