import React, { Fragment } from "react";
import { Row, Col, Breadcrumb, Card, Divider } from "antd";
import { Link } from "gatsby";
import { PressReleaseData } from "../../../../services/PressReleaseData";
import  {
  PageContentEmployerScreen,
} from "../../../components/PageContent/PageContent";
import banner_img1 from "../../../images/PressRelease/banner/Adecco-India-logo.webp";
import backIcon from "../../../images/icons/icons8-back-24.webp";
import fbIcon from "../../../images/icons/facebook.webp";
import ldIcon from "../../../images/icons/linkedin.webp";
import ttIcon from "../../../images/icons/twitter.webp";
import "./common.less";

export default function PressRelease(props) {
  const data={
    press_id:"PRESS0010",
    id:
      "adecco-india-collaborates-with-jagriti-sewa-sansthan-to-encourage-rural-entrepreneurship-for-young-talents",
    banner_img: banner_img1,
    category: "Corporate Social Responsibility",
    date: "Bangalore, 17th May, 2023",
    title:
      "Adecco India collaborates with Jagriti Sewa Sansthan to encourage rural entrepreneurship for Young Talents",
    description:
      "Adecco, a leading global workforce provider, collaborates with Jagriti Sewa Sansthan (Jagriti) to promote Rural Entrepreneurship in India through a training program focused on skills development in Purvanchal. Adecco India will leverage its existing capabilities and expertise in this area to provide incubation support for Jagriti Sewa Sansthan, helping to build the Rural Entrepreneurship Ecosystem in Purvanchal's three districts. In this initiative, Adecco’s focus will be on building employability for young people by teaching them market-oriented skills, like communication, presentation, and personality development, all of which are essential for both, job seekers and entrepreneurs.",
    content: `
    <h1>Adecco India collaborates with Jagriti Sewa Sansthan to encourage rural entrepreneurship for Young Talents</h1>
<p> <b><u>Bangalore, 17th May, 2023: </u></b> Adecco, a leading global workforce provider, collaborates with Jagriti Sewa Sansthan (Jagriti) to promote Rural Entrepreneurship in India through a training program focused on skills development in Purvanchal. Adecco India will leverage its existing capabilities and expertise in this area to provide incubation support for Jagriti Sewa Sansthan, helping to build the Rural Entrepreneurship Ecosystem in Purvanchal's three districts. In this initiative, Adecco’s focus will be on building employability for young people by teaching them market-oriented skills, like communication, presentation, and personality development, all of which are essential for both, job seekers and entrepreneurs.</p>
<p> <b>Commenting on the collaboration Mr. Ajay Sethi, Country Head Adecco India, said,</b> "We need a strong focus on future skills to address the rising skills gap, especially with the young. Through partnering with the Rural Entrepreneurship Development program, we can lay the foundation for a sustainable future for them. Jagriti Sewa Sansthan is the perfect partner for Adecco in terms of building employability - this exciting collaboration will equip young people with the skills they need to thrive.”</p>
<p> <b>Mr. Ashutosh Kumar, CEO of Jagriti said,</b> "Young talents in the underprivileged areas of India are waiting to rise and contribute to the growth of the country, but, alas, they are often ignored, A supportive ecosystem with a long-term vision is needed. This is why we are delighted to join Adecco India in building these ecosystems for skilling and entrepreneurship in eastern Uttar Pradesh."</p>
<p>Adecco India commits to conducting sustainable social initiatives that provide education and job training opportunities, especially for underserved populations. The company has made an annual contribution of <b>Rs. 50 lakhs</b> to Jagriti Sewa Sansthan in order to build the Rural Entrepreneurship Ecosystem.</p>
<h4><b>About Adecco India</b></h4>
<p> Adecco India is one of the leading staffing and recruitment solutions providers in India, offering a broad range of tailored solutions to employers and flexible working opportunities for job seekers. With a strong presence and deep understanding of the Indian job market, we specialize in temporary staffing, permanent placement, outsourcing, and consulting services. As a trusted partner for businesses and individuals alike, we believe in skills focus to connect talents with rewarding opportunities, while helping organizations build and optimize their workforce for long-term success. When it comes to recruiting talent, <b>diversity</b> and <b>inclusion</b>are binding principles for us. We see inclusion not only as a business model that addresses talent scarcity and brings long-term economic benefits, but also as a responsibility toward society. We currently have over 900 employees working for us in various Adecco offices throughout the country, and over 35.000 associates placed with business clients in India.</p>
<h4><b>About Jagriti Sewa Sansthan</b></h4>
<p>Jagriti Sewa Sansthan is a registered NGO working at grass root level in Deoria and nearby districts in Eastern UP in the field of vocational training and entrepreneurship development since 2001. Jagriti has gained nationwide fame for its inspirational entrepreneurial train journey covering 8000 km across India for 500+ aspiring entrepreneurs every year. It has created a network of 7500+ Yatris since 2008. Jagriti now is setting up an enterprise centre called Jagriti Enterprise Centre – Purvanchal (JEC-P), an incubator beyond metros with a mission to strengthen the enterprise ecosystem in Tier 2 and 3 districts of India, starting from its home district Deoria. JEC-P is leading the creation of a visionary district enterprise ecosystem that will drive enterprise led development through Incubation, Innovation and Inspiration in 15 districts of Eastern UP with a population of 50 million people. It aims to further the spirit of entrepreneurship by establishing a physical centre to run and develop yearlong programmes for local youth, women, farmers and other stakeholders.</p>
<h4><b>For further information please contact:</b></h4>
<p>Cauvery Uthappa | <a href="mailto:cauvery.uthappa@adecco.com" class="taxt-dark">cauvery.uthappa@adecco.com</a> | <a href="tel:+919845172958" class="taxt-dark">+919845172958</a></p>
    `,
    link:
      "/employer/press-release/press-release-10",
  }

  

  const listData: any = []
  for (let i = 0; i < 6; i++) {
    listData.push({
      href: "https://ant.design",
      title: `ant design part ${i}`,
      avatar: "https://joeschmoe.io/api/v1/random",
      description:
        "Ant Design, a design language for background applications, is refined by Ant UED Team.",
      content:
        "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
    })
  }
  return (
    <>
      {data?.banner_img && (
        <section className="PressSection">
          <Row>
            <div className="hero-section" style={{backgroundImage: `url('${data.banner_img}')`}}>
              <div className="overlay"></div>
              <div className="content-section"></div>
            </div>
          </Row>
        </section>
      )}

      <PageContentEmployerScreen
        location={props.location}
      >
        <Row className="custome-breadcrumb mt-5">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/employer/press-release"}>
                  <img loading="lazy"  src={backIcon} /> Back to Press Release
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="float-right">
            <div className="d-flex">
              <span>Share|</span>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={fbIcon} alt="Facebook" />
              </a>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={ldIcon} alt="linkedin" />
              </a>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={ttIcon} alt="twitter" />
              </a>
            </div>
          </Col>
        </Row>
        <Row type="flex" className="mobile-flex">
          <Col lg={9} md={24} className="px-3 related-press-release-card">
            <Card title="Related Press Release" size="small" className="">
              {PressReleaseData.map(
                (data, i) =>
                  i > 4 && (
                    <Fragment key={data.id}>
                      <div className="d-flex">
                        <img loading="lazy" 
                          src={data.releted_img}
                          alt="img"
                          className="rounded"
                          width={100}
                        />
                        <Link
                          to={data.link}
                          className="ml-2 fw-600 press-title"
                        >
                          {data.title}
                        </Link>
                      </div>
                      <Divider />
                    </Fragment>
                  )
              )}
            </Card>
          </Col>
          <Col lg={14} md={24} className="press-release-section">
            {data && (
              <Row
                className="fw-500 font-normal"
                dangerouslySetInnerHTML={{ __html: data?.content }}
              ></Row>
            )}
          </Col>
        </Row>
      </PageContentEmployerScreen>
    </>
  )
}
