// import JayitaRoyImage from "../src/images/OurTeam/Jayita-300x200.webp"
import KarishmaParikhImage from "../src/images/OurTeam/KarishmaParikh.webp"
import ManuImage from "../src/images/OurTeam/Manu-300x200.webp"
import RamanImage from "../src/images/OurTeam/Raman-300x200.webp"
import ArnabImage from "../src/images/OurTeam/Arnab(300x200).webp"
import AartiImage from "../src/images/OurTeam/Aarthi-300x200.webp"
import karthikeyankesavan from "../src/images/OurTeam/karthikeyankesavan.webp"
import CauveryImage from "../src/images/OurTeam/Cauvery-300x200.webp"
// import RameshImage from "../src/images/OurTeam/Ramesh(300x200).webp"
import SankethImage from "../src/images/OurTeam/Sanketh.webp"
import VidyaSagarImage from "../src/images/OurTeam/Vidya-300x200.webp"
import RajganeshImage from "../src/images/OurTeam/Rajganesh.webp"
import AdarshImage from "../src/images/OurTeam/Adarsh-300x200.webp"
import SrinivasRaoImg from "../src/images/OurTeam/Srini-300x200.webp"
import employeesIcon from "../src/images/icons/employees.webp"
import officeIcon from "../src/images/icons/location.webp"
import clientIcon from "../src/images/icons/client.webp"
import womenworkforceIcon from "../src/images/icons/Diversity-Ratio.webp"
import placementsIcon from "../src/images/icons/placements.webp"
import Sathidevi from "../src/images/OurTeam/Sathidevi.png"
import AjaySeth from "../src/images/OurTeam/Sunil.webp"


import AryaMathew from "../src/images/OurTeam/Arya_300x200_-new-image.webp"

import BranchData from "./BranchData";




const aboutUsTeam = [
  {
    img: AjaySeth,
    category: "Country Manager",  
    name: "Sunil C",
    profession: "Country Manager",
    // email: "CMD.India@adecco.com",
    linkedin: "https://www.linkedin.com/in/sunilchemmankotil/",
  },
  {
    img: RajganeshImage,
    name: "Rajganesh Ramachandran",
    category: "Country Finance Officer",
    profession: "Country Finance Officer",
    email: "rajganesh.ramachandran@adecco.com",
    linkedin:
      " https://www.linkedin.com/in/rajganesh-ramachandran-94b6426/?originalSubdomain=in",
  },
  // {
  //   img: RajganeshImage,
  //   name: "Rajganesh Ramachandran",
  //   category: "Finance",
  //   profession: "Country Finance Officer",
  //   email: "rajganesh.ramachandran@adecco.com",
  //   linkedin:
  //     " https://www.linkedin.com/in/rajganesh-ramachandran-94b6426/?originalSubdomain=in",
  // },
  {
    // img: JayitaRoyImage,
    img: KarishmaParikhImage,
    name: "Karishma Parikh",
    category: "Human Resources",
    profession: "Human Resources",
    email: "Karishma.Parikh@adecco.com",
    linkedin: "https://www.linkedin.com/in/karishma-parikh-7352aa4",
  },
  // {
  //   img: ManuImage,
  //   name: "Manu Saigal",
  //   category: "Business Leaders",
  //   subCategory: "Client Services",
  //   profession: "General Staffing",
  //   email: "Manu.Saigal@adecco.com",
  //   linkedin: "https://www.linkedin.com/in/manu-saigal-098361/",
  // },
  {
    img: SrinivasRaoImg,
    name: "Srinivas Rao",
    category: "Business Leaders",
    subCategory: "Client Services",
    profession: "General Staffing",
    email: "Srinivas.Ra0@adecco.com",
    linkedin: "https://www.linkedin.com/in/gandlasrao/",
  },
  {
    img: karthikeyankesavan,
    name: "Karthikeyan Kesavan",
    category: "Business Leaders",
    subCategory: "Client Services",
    profession: "Permanent Recruitment",
    email: "",
    linkedin: "https://www.linkedin.com/in/karthikeyan-kesavan/?originalSubdomain=in",
  },
  {
    img: SankethImage,
    name: "Sanketh Chengappa",
    category: "Business Leaders",
    subCategory: "Client Services",
    profession: "Professional Staffing",
    email: "Sanketh.Chengappa@adecco.com",
    linkedin: "https://www.linkedin.com/in/sanketh-chengappa-894ba923",
  },
  {
    name: "Debabrata Debnath",
    profession: "Associate Director – Outsourcing",
    email: "Debabrata.Debnath@adecco.com",
    linkedin: "https://www.linkedin.com/in/debabrata-debnath-1563a24/",
  },
  {
    img: Sathidevi,
    name: "Sathidevi S Raj",
    category: "Information Technology",
    profession: "Director - IT",
    // email: "Raman.Prabhakaran@adecco.com",
    linkedin: "https://www.linkedin.com/in/sathidevi-s-raj-b2a19445/",
  },
  {
    img: ArnabImage,
    name: "Arnab Kumar Ganguly",
    category: "Business Transformation",
    profession: "Director - Business Transformation",
    email: "Arnab.Ganguly@adecco.com",
    linkedin: "https://www.linkedin.com/in/arnabganguly",
  },
  {
    img: AryaMathew,
    name: "Arya Mathew",
    category: "Legal & Compliance",
    profession: "Legal & Compliance",
    // email: "Roshan.Ramapuram@adecco.com",
    linkedin: "https://www.linkedin.com/in/mathewarya/",
  },
  {
    img: AdarshImage,
    name: "Adarshanand Sudhakar",
    category: "Shared Services Centre",
    profession: "Shared Services Centre",
    email: "Adarshanand.Sudhakar@adecco.com",
    linkedin: "https://www.linkedin.com/in/adarshanands/",
  },
  {
    img: CauveryImage,
    name: "Cauvery Uthappa",
    category: "Marketing and Communications",
    profession: "Marketing & Communications",
    email: "Cauvery.Uthappa@adecco.com",
    linkedin: "https://www.linkedin.com/in/cauvery-uthappa-she-her-bb40046/",
    // in/cauvery-uthappa-she-her-bb40046
  },
  {
    // img: AnjaliImage,
    img: VidyaSagarImage,
    name: "Anjali Vaishal",
    profession: "Managing Director – LHH",
    email: "Anjali.Vaishal@lhhindia.co.in",
    linkedin: "https://www.linkedin.com/in/anjali-vaishal-96263513/",
  },
  {
    // img:JaishankarImage,
    img: VidyaSagarImage,
    name: "Jaishankar Adhinarayanan",
    profession: "Director - Delivery Center Pontoon Solutions",
    email: "",
    linkedin: "",
  },
]

export default aboutUsTeam

export const activityData = [
  {
    img: officeIcon,
    number:BranchData.BranchDetails.length,
    desc: "offices",
  },
  {
    img: employeesIcon,
    number: "1400+",
    desc: "core employees",
  },
  {
    img: womenworkforceIcon,
    number: "50% ",
    desc: "women workforce",
  },
  {
    img: clientIcon,
    number: "700+",
    desc: "clients",
  },
  {
    img: placementsIcon,
    number: "15 Lakh+",
    desc: "placements since 2002",
  },
  // {
  //   img: candidatesIcon,
  //   number: "65,000+",
  //   desc: "candidates placed every year",
  // },
]

export const ourLocalPresenceData = [
  {
    img: womenworkforceIcon,
    number: "37,000+",
    desc: "Colleagues",
  },
  {
    img: officeIcon,
    number: "20",
    desc: "Banches",
  },
  {
    img: womenworkforceIcon,
    number: "1,500+",
    desc: "Clients",
  },
]
