import React from "react";
import "./carouselStyle.less";

const CustomCarousel = () => {
  return (
    <div className="marquee ">
      <div
        className="banner pos-fixed px-6 pt-2"
        style={{ bottom: "0", zIndex: 100 }}
      >
        <p className="content">
          <span>
            Welcome to Adecco. Beware of fraudulent job offers. Adecco does not
            charge a fee at any stage of the recruitment process. To report the
            complaint, please mail us at{" "}
            <a href="mailto:we.care@adecco.com">we.care@adecco.com</a>.
          </span>
        </p>
      </div>
    </div>
  )
}

export default CustomCarousel
