import * as React from "react"
import { request } from "./dimensionHelper"

export const APIContext = React.createContext({
  loading: false,
  getJobsbyCityAndIndustry: () => {},
})

export const APIProvider = ({ children }) => {
  const [loading, setLoading] = React.useState(false)
  const [jobsbyCityData, setJobsByCityData] = React.useState([])
  const [jobsbyIndustryData, setJobsByIndustryData] = React.useState([])
  const [jobsbyTypeData, setJobsByTypeData] = React.useState([])
  const [jobspageLocationData, setJobspageLocationData] = React.useState([])
  const [jobspageIndstryData, setJobspageIndstryData] = React.useState([])
  const [locationData, setLocationData] = React.useState([]);
  const [veteranData, setVeteranData] = React.useState({
    location:[],
    language:[],
    division:[]
  });

  


  React.useEffect(() => {
    getJobsbyCityAndIndustry()
  }, [])

  const haldleOption=(lable,value)=>({
    lable,value
  })
  const getJobsbyCityAndIndustry = async () => {
    setLoading(true)
    await request("GET", "JobSite/Location")
      .then(res => {
        if (res.data) {
          const cityData = res.data.map(data =>
            data.locationDescription === "Other"
              ? {
                  ...data,
                  title: `Jobs in ${data.locationDescription} Cities`,
                  value: data.loctionCode,
                  label: data.locationDescription,
                  url: handleCreateIndustryUrl(data.locationDescription),
                }
              : {
                  ...data,
                  title: `Jobs in ${data.locationDescription}`,
                  value: data.loctionCode,
                  label: data.industryDescription,
                  url: handleCreateIndustryUrl(data.locationDescription),
                }
          )
          const geData = res.data.find(
            data => data.locationDescription === "Other"
          )
          const lData = res.data
            ?.sort((a: any, b: any) => {
              if (a.locationDescription < b.locationDescription) return -1
              if (a.locationDescription > b.locationDescription) return 1
              return 0
            })
            .filter(data => data.locationDescription !== "Other")
          lData.push(geData)
          setLocationData(lData)
          setJobsByCityData(cityData)
          setJobspageLocationData(
            res.data.map(data => ({
              value: data.loctionCode,
              label: data.locationDescription,
            }))
          )
        }
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))

    setLoading(true)
    await request("GET", "JobSite/IndustryType")
      .then(res => {
        if (res.data) {
          setJobsByIndustryData(
            res.data.map(data => ({
              ...data,
              title: `Jobs in ${data.industryDescription}`,
              value: data.industryCode,
              label: data.industryDescription,
              industryCode: data.industryCode,
              industryDescription: data.industryDescription,
              url: handleCreateIndustryUrl(data.industryDescription),
            }))
          )
          setJobspageIndstryData(
            res.data.map(data => ({
              value: data.industryCode,
              label: data.industryDescription,
            }))
          )
        }
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
    setLoading(true)
    await request("GET", "JobSite/JobType")
      .then(res => {
        if (res.data) {
          const typeData = res.data.map(data => ({
            value: data.jobTypeCode,
            label: data.jobTypeDescription,
          }))
          typeData.push({
            value: "ALL",
            label: "All",
          })
          setJobsByTypeData(typeData)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }
  const getVeteranData = async () => {
    setLoading(true);
    await request("GET", "JobSite/VeteranLocation")
      .then(res => {
        if (res.data) {
          setVeteranData(prev=>({
            ...prev,
            location:res.data.map(ld=>haldleOption(ld.locationDescription,ld.veteranLocationCode))
          }))
        }
      }).catch(err=>console.log(err))
      .finally(()=>setLoading(false))

    setLoading(true);
      await request("GET", "JobSite/VeteranLanguage")
      .then(res => {
        if (res.data) {
          setVeteranData(prev=>({
            ...prev,
            language:res.data.map(ld=>haldleOption(ld.languageDescription,ld.languageCode))
          }))
        }
      }).catch(err=>console.log(err))
      .finally(()=>setLoading(false))

      setLoading(true);
      await request("GET", "JobSite/VeteranMaster/DIV")
      .then(res => {
        if (res.data) {
          setVeteranData(prev=>({
            ...prev,
            division:res.data.map(ld=>haldleOption(ld.dataDescription,ld.subCode))
          }))
        }
      }).catch(err=>console.log(err))
      .finally(()=>setLoading(false))
      
  }
  const handleCreateIndustryUrl = url => {
    if (url.includes("/") || url.includes(",")) {
      return url.includes("/")
        ? url
            .split("/ ")
            .join("-")
            .split(" : ")
            .join("-")
            .split(" ")
            .join("-")
            .toLowerCase()
        : url
            .split(", ")
            .join("-")
            .split(" ")
            .join("-")
            .toLowerCase()
    } else {
      if (url.includes(" ")) {
        return url
          .split(" ")
          .join("-")
          .toLowerCase()
      } else {
        return url.toLowerCase()
      }
    }
  }
  const valueData = {
    data: {
      loading,
      jobsbyCityData,
      jobsbyIndustryData,
      jobsbyTypeData,
      jobspageLocationData,
      jobspageIndstryData,
      locationData,
      veteranData
    },
    actions: {
      getJobsbyCityAndIndustry,
      getVeteranData
    },
  }

  return <APIContext.Provider value={valueData}>{children}</APIContext.Provider>
}
