import React from 'react'
import { Card, Col, Row } from 'antd';
import AwardData from '../../../services/newAwardRecognition';
import "./common.less";



const AwardsAndRecognition = () => {
    const showAwards = (start: number, end: number) => {
        return (
            AwardData.slice(start, end).map(data => {
                return (
                  <>
                  <Col
                   key={AwardData.indexOf(data)}
              xs={24}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ padding: "20px" }}
            >
              <div className="box">
                <div className="images">
                  <img loading="lazy"  src={data.img}  alt={data.title+"image"}/>
                </div>
                <div className="details">
                  
                    <h4>{data.title}</h4>
                  <p>{data.content}</p>
                </div>
              </div>
            </Col>
                    
          </>
                )
              })
        )
      }
  return (
    <section className="program-new px-lx-7 px-lg-7 px-md-4 px-5 py-6">
    <Row>
      <div className="prog-main-head">
        <h3>Awards & Recognition</h3>
        <p>
            When you put everything you’ve got into being a good employer,
            finding the perfect someone for that perfect job, and giving young
            graduates and disadvantaged job seekers a leg up on the job ladder,
            people can’t help but notice. We’re very thankful for all the
            honours we’ve received over the years.
          </p>
        
        <Row gutter={[24, 24]} className="pt-2 " type="flex" >
          {showAwards(0, 5)}


        </Row>
    </div>
    </Row>
    </section>
  )
}

export default AwardsAndRecognition