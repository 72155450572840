import React from "react";
import { Link } from "gatsby";
import { Col, Row } from "antd";
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent";
import { NewBanner } from "../../../components/Solutions/newBanner";
import BreadcrumbSec from "./Breadcrumb";
import Sidebar from "./sidebar";
import OrganizationalDesignImg from "../../../images/solutions/HR/Organizational-Design.webp";
import OrganizationalDevelopmentImg from "../../../images/solutions/HR/Organizational-Development.webp";
import TalentManagementSolutionsImg from "../../../images/solutions/HR/Talent-Management-Solutions.webp";
import TotalRewardsAdvisoryImg from "../../../images/solutions/HR/Total-Rewards-Advisory.webp";
import WorkforceAnalyticsImg from "../../../images/solutions/HR/Workforce-Analytics.webp";
import TechnologyAdoptionImg from "../../../images/solutions/HR/Technology-Adoption.webp";
import banImg from "../../../images/solutions/banner/HR-Advisory-&-Consulting-Services.webp";
import "./common.less";
import Stories from "../../../components/employerHomePage/stories";

const HrAdvisoryAndConsultingService = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <NewBanner
        BannerImg={banImg}
        title={"HR Advisory & Consulting Services"}
      />
      <BreadcrumbSec current={"HR Advisory & Consulting Services"} />

      <section className="mainSolutionSection px-3 px-md-6">
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="float-right">
            <div className="box">
              <div className="heading">
                <h1>HR Advisory & Consulting Services</h1>
                <span className="separator"></span>
              </div>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">
                  Focus on your core business with our HR Advisory Services
                </h2>
              </div>
              <p className={""}>
                Clients value our ability to help them navigate the challenges
                of reinventing their HR function – where they need our
                consultancy on innovative, dynamic, and efficient solutions. We
                believe our approach has been grounded in understanding the
                specific needs of our clients and the experience of the local
                context. Our scope and scale to work globally and operate
                through our talent pool of local experts, enables us to meet
                people where they are. We will continue to invest in technology
                and in our people to ensure we deliver the best customer
                experience.{" "}
              </p>
              <p>
                We assemble and deploy a team of multi-skilled professionals, to
                drive the strategic initiatives at our partner organizations. We
                also serve either as consultants or work as a fully integrated
                part of the partner organization.{" "}
              </p>
              <p className={"text-danger text-bold"}>
                We offer personalized HR consulting services and integrated
                organizational, and people practices advisory services to our
                partners.
              </p>
              <p>
                Combined, these capabilities represent a clear competitive
                differentiator for us in the market creating value and impact:{" "}
              </p>
              <div className="d-flex align-items-center">
                <img loading="lazy"  src={TechnologyAdoptionImg} width={48} className="mr-2"  alt="TechnologyAdoptionImg"/>
                <h2 className="p-0 m-0">
                  HR Function Design / Optimization / Technology Adoption
                </h2>
              </div>
              <ul className={"pl-7"}>
                <li>HR practices maturity analysis</li>
                <li>Identification of gaps, process design, and re-design</li>
                <li>Optimization including anchors for technology adoption</li>
                <li>Determining the organization's AI/ML maturity</li>
                <li>
                  Identifying anchors for AI/ML / RPA / Automation levers across
                  all knowledge areas of people practices
                </li>
              </ul>

              <div className="d-flex align-items-center">
                <img loading="lazy" 
                  src={OrganizationalDevelopmentImg}
                  width={48}
                  className="mr-2"
                  alt="OrganizationalDevelopmentImg"
                />
                <h2 className="p-0 m-0">Organizational Development</h2>
              </div>
              <p className="pl-6">
                We engage end-to-end with the client right from identification
                of the opportunity/sizing problem(s), customized diagnostics,
                data-driven decisions for changes, and implementing solutions
                through systematic change management & management of change
                frameworks.{" "}
              </p>

              <div className="d-flex align-items-center">
                <img loading="lazy" 
                  src={OrganizationalDesignImg}
                  width={48}
                  className="mr-2"
                  alt="OrganizationalDesignImg"
                />
                <h2 className="p-0 m-0">Organizational Design</h2>
              </div>
              <ul className={"pl-7"}>
                <li>
                  Understand anchors influencing the current organizational
                  design, identify gaps against strategic intent, adopt
                  benchmark framework(s) to identify ideal design / re-design,
                  and partner throughout the implementation cycle
                </li>
                <li>
                  Job Evaluation (point factor method) and cohort results with
                  total rewards & other people's practice areas
                </li>
              </ul>

              <div className="d-flex align-items-center">
                <img loading="lazy" 
                  src={TalentManagementSolutionsImg}
                  width={48}
                  className="mr-2"
                  alt="TalentManagementSolutionsImg"
                />
                <h2 className="p-0 m-0">Talent Management Solutions</h2>
              </div>
              <ul className={"pl-7"}>
                <li>Performance Management Design</li>
                <li>Talent Development Framework</li>
                <li>Assessment & Development Center Designs</li>
                <li>Drivers for Talent Engagement</li>
                <li>Competency Framework</li>
              </ul>

              <div className="d-flex align-items-center">
                <img loading="lazy" 
                  src={TotalRewardsAdvisoryImg}
                  width={48}
                  className="mr-2"
                  alt="TotalRewardsAdvisoryImg"
                />
                <h2 className="p-0 m-0">Total Rewards Advisory</h2>
              </div>
              <ul className={"pl-7"}>
                <li>Leveraging Total Rewards to drive growth</li>
                <li>Drafting Statutory & Regulatory compliance guidelines</li>
              </ul>

              <div className="d-flex align-items-center">
                <img loading="lazy"  src={WorkforceAnalyticsImg} alt="WorkforceAnalyticsImg" width={48} className="mr-2" />
                <h2 className="p-0 m-0">Workforce Analytics</h2>
              </div>
              <p className="pl-6">
                We partner with clients in creating the data infrastructure to
                use data for key decisions and helping organizations navigate
                from descriptive to prescriptive maturity.
              </p>
              <p className="pl-6">
                Help leverage analytics engine for key talent decisions across
                all the knowledge areas of people practices.
              </p>

              <div className="text-center">
                <Link
                  to="/employer/business-enquiry/"
                  state={{ interested: "HR Advisory & Consulting Services" }}
                >
                  Business Enquiry
                </Link>
              </div>
            </div>
          </Col>
          <Sidebar />
        </Row>
        <Stories heading="Case Study" isReadMore={false} />
      </section>
    </PageContentEmployerScreen>
  )
}

export default HrAdvisoryAndConsultingService
