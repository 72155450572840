import React from "react";
import {  Col, Row } from "antd";
import { Link } from "gatsby";
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent"
import AttractionSourcingImg from "../../../images/solutions/GS/Attraction-&-Sourcing.webp";
import candidateShortlistingImg from "../../../images/solutions/GS/candidate-shortlisting.webp";
import InterviewingImg from "../../../images/solutions/GS/interviewing.webp";
import onboardingImg from "../../../images/solutions/GS/onboarding.webp";
import payrollAdminImg from "../../../images/solutions/GS/payroll-&-admin.webp";
import trainingImg from "../../../images/solutions/GS/training.webp";
import legalImg from "../../../images/solutions/GS/legal.webp";
import ConsumerProductsImg from "../../../images/solutions/GS/Consumer-Products.webp";
import FoodImg from "../../../images/solutions/GS/Food-Beverage-&-Tobacco.webp";
import HotelImg from "../../../images/solutions/GS/Hotel-Catering-Tourism.webp";
import FinancialImg from "../../../images/solutions/GS/Financial-Institutes-&-Insurance.webp";
import ChemicalImg from "../../../images/solutions/GS/Chemical.webp";
import ManufacturingImg from "../../../images/solutions/GS/Manufacturing.webp";
import MetalsMiningImg from "../../../images/solutions/GS/Metals-&-Mining.webp";
import HealthcareImg from "../../../images/solutions/GS/Healthcare.webp";
import PharmaceuticalImg from "../../../images/solutions/GS/Pharmaceutical.webp";
import LogisticsTransportationImg from "../../../images/solutions/GS/Logistics-&-Transportation.webp";
import RetailImg from "../../../images/solutions/GS/Retail.webp";
import ConsultingImg from "../../../images/solutions/GS/Consulting.webp";
import TelecomImg from "../../../images/solutions/GS/Telecom.webp";
import AerospaceDefenseImg from "../../../images/solutions/GS/Aerospace-&-Defense.webp";
import AutomotiveImg from "../../../images/solutions/GS/Automotive.webp";
import ConstructionImg from "../../../images/solutions/GS/Construction.webp";
import GovernmentInstitutesImg from "../../../images/solutions/GS/Government-Institutes.webp";
import OilGasImg from "../../../images/solutions/GS/Oil-&-Gas.webp";
import MediaEntertainmentImg from "../../../images/solutions/GS/Media-&-Entertainment.webp"
import BreadcrumbSec from "./Breadcrumb"
import Sidebar from "./sidebar"
import banImg from "../../../images/solutions/banner/General-Staffing.webp";
import { NewBanner } from "../../../components/Solutions/newBanner"

import "./common.less"
import Stories from "../../../components/employerHomePage/stories";

const GeneralStaffing = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <NewBanner BannerImg={banImg} title={"General Staffing"} />
      <BreadcrumbSec current={"General Staffing"} />

      <section className="mainSolutionSection px-3 px-md-6">
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="float-right">
            <div className="box">
              <div className="heading">
                <h1>General Staffing</h1>
                <span className="separator"></span>
              </div>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">
                  Unlocking human potential to achieve equal access to decent
                  work for all
                </h2>
              </div>
              <p className={""}>
                General Staffing provides flexibility to reconcile with the
                changing demands of the business in both the short and long
                term. With companies across the globe laying critical emphasis
                on their human resource management, temporary staffing service
                is a fast-catching trend. Many organizations are challenged in
                their ability to acquire the required skill sets to cater to
                dynamic market shifts. The labour demand for speciﬁc skillsets
                often emerges unplanned, leaving the in-house recruiting
                function with a practical challenge to move with a speed and
                expertise, that it is not capable of handling.
              </p>
              <p className={"text-danger text-bold"}>
                As a global leader in temporary staffing services, we offer our
                clients innovative models and tailored solutions for their
                workforce requirements all while assuring full compliance with
                local labour laws. With continuous investments in statutory
                compliance, we support our clients' success with agility and
                transparency.
              </p>
              <div className="solution-icons">
                <div className="icon-sec  justify-space-around">
                  <div className="icon">
                    <img loading="lazy" 
                      src={AttractionSourcingImg}
                      alt="Attraction Sourcing IMG"
                    />
                    <h3>Attraction & Sourcing</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy" 
                      src={candidateShortlistingImg}
                      alt="Candidate Shortlisting IMG"
                    />
                    <h3>Candidate Shortlisting</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={InterviewingImg} alt="Interviewing IMG" />
                    <h3>Interviewing</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={onboardingImg} alt="onboardingImg" />
                    <h3>Onboarding</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={payrollAdminImg} alt="payrollAdminImg" />
                    <h3>Payroll Admin</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={trainingImg} alt="trainingImg" />
                    <h3>Training</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={legalImg} alt="legalImg" />
                    <h3>Legal Counsel</h3>
                  </div>
                </div>
              </div>
              <p className={""}>
                We provide customers with a hassle-free experience to quickly
                bring talent into the business. Whether seasonal, to cope with
                spikes in demand or to fulfill project work, temporary employees
                can help companies tackle the scarcity of talent. We recruit and
                manage flexible workers for our clients, taking care of
                sourcing, onboarding, training, payroll, and administration.
                Some of the job roles we work with regularly are - client
                servicing personnel, shop floor assistants, HR/ Admin/ Back
                office profiles, field sales force, brand promoters,
                telecallers, and delivery executives.
              </p>
              <div className="solution-icons">
                <h2 className="title">
                  Our key differentiator | Industry specialization
                </h2>
                <div className="icon-sec justify-space-around">
                  <div className="icon">
                    <img loading="lazy"  src={ConsumerProductsImg} alt="Consumer-ProductsIMG" />
                    <h3>Consumer-Products</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={FoodImg} alt="Food,-Beverage-&-TobaccoIMG" />
                    <h3>Food, Beverage & Tobacco</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={HotelImg} alt="Food,-Beverage-&-TobaccoIMG" />
                    <h3>Hotel, Catering, Tourism</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy" 
                      src={FinancialImg}
                      alt="Financial Institutes & InsuranceIMG"
                    />
                    <h3>Financial Institutes & Insurance</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={ChemicalImg} alt="ChemicalImgIMG" />
                    <h3>Chemical</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={ManufacturingImg} alt="ManufacturingIMG" />
                    <h3>Manufacturing</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={MetalsMiningImg} alt="MetalsMiningIMG" />
                    <h3>Metals Mining</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={HealthcareImg} alt="HealthcareImg" />
                    <h3>Medical/Healthcare/Hospital</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={PharmaceuticalImg} alt="PharmaceuticalImg" />
                    <h3>Pharmaceutical</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy" 
                      src={LogisticsTransportationImg}
                      alt="LogisticsTransportationImg"
                    />
                    <h3>Logistics & Transportation</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={RetailImg} alt="RetailImg" />
                    <h3>Retail</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={ConsultingImg} alt="ConsultingImg" />
                    <h3>Consulting/Diversified Services</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={TelecomImg} alt="TelecomImg" />
                    <h3>Telecom</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={AerospaceDefenseImg} alt="AerospaceDefenseImg" />
                    <h3>Aerospace & Defense</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={AutomotiveImg} alt="AutomotiveImg" />
                    <h3>Automotive/Transport Equipment/Utilities</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={ConstructionImg} alt="ConstructionImg" />
                    <h3>Construction & Real Estate</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy" 
                      src={GovernmentInstitutesImg}
                      alt="GovernmentInstitutesImg"
                    />
                    <h3>Government Institutes</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy"  src={OilGasImg} alt="OilGasImg" />
                    <h3>Oil & Gas / Petrol,Chemical</h3>
                  </div>
                  <div className="icon">
                    <img loading="lazy" 
                      src={MediaEntertainmentImg}
                      alt="MediaEntertainmentImg"
                    />
                    <h3>Media & Entertainment</h3>
                  </div>
                </div>
              </div>
              <p>
                Our clients rely on us to manage the end-to-end process,
                ensuring recruitment efficiency and time to fill; from the
                proper integration of new entries to devising replacements, from
                the analysis of the quality of candidates on-boarded to reducing
                attrition levels. We bring in operating efficiencies, such as
                higher recruiter productivity, recruitment competitiveness, and
                harmonize hiring practices while reducing the cost to hire, thus
                serving a competitive advantage for our clients.
              </p>
              <p>
                With the largest general staffing network of 20+ offices, it
                brings us closer to clients, associates, and candidates,
                enabling us to deliver staffing solutions for almost every
                sector, including specialist industries.
              </p>
              <div className="text-center">
                <Link
                  to="/employer/business-enquiry/"
                  state={{ interested: "General Staffing" }}
                >
                  Business Enquiry
                </Link>
              </div>
            </div>
          </Col>
          <Sidebar />
        </Row>
        <Stories heading="Case Study" isReadMore={false} />
      </section>
    </PageContentEmployerScreen>
  )
}

export default GeneralStaffing
