export const jobSeeker = {
  state: [
    {
      abbreviation: "AN",
      name: "Andaman and Nicobar Islands",
    },
    {
      abbreviation: "AP",
      name: "Andhra Pradesh",
    },
    {
      abbreviation: "AR",
      name: "Arunachal Pradesh",
    },
    {
      abbreviation: "AS",
      name: "Assam",
    },
    {
      abbreviation: "BR",
      name: "Bihar",
    },
    {
      abbreviation: "CG",
      name: "Chandigarh",
    },
    {
      abbreviation: "CH",
      name: "Chhattisgarh",
    },
    {
      abbreviation: "DH",
      name: "Dadra and Nagar Haveli",
    },
    {
      abbreviation: "DD",
      name: "Daman and Diu",
    },
    {
      abbreviation: "DL",
      name: "Delhi",
    },
    {
      abbreviation: "GA",
      name: "Goa",
    },
    {
      abbreviation: "GJ",
      name: "Gujarat",
    },
    {
      abbreviation: "HR",
      name: "Haryana",
    },
    {
      abbreviation: "HP",
      name: "Himachal Pradesh",
    },
    {
      abbreviation: "JK",
      name: "Jammu and Kashmir",
    },
    {
      abbreviation: "JH",
      name: "Jharkhand",
    },
    {
      abbreviation: "KA",
      name: "Karnataka",
    },
    {
      abbreviation: "KL",
      name: "Kerala",
    },
    {
      abbreviation: "LD",
      name: "Lakshadweep",
    },
    {
      abbreviation: "MP",
      name: "Madhya Pradesh",
    },
    {
      abbreviation: "MH",
      name: "Maharashtra",
    },
    {
      abbreviation: "MN",
      name: "Manipur",
    },
    {
      abbreviation: "ML",
      name: "Meghalaya",
    },
    {
      abbreviation: "MZ",
      name: "Mizoram",
    },
    {
      abbreviation: "NL",
      name: "Nagaland",
    },
    {
      abbreviation: "OR",
      name: "Odisha",
    },
    {
      abbreviation: "PY",
      name: "Puducherry",
    },
    {
      abbreviation: "PB",
      name: "Punjab",
    },
    {
      abbreviation: "RJ",
      name: "Rajasthan",
    },
    {
      abbreviation: "SK",
      name: "Sikkim",
    },
    {
      abbreviation: "TN",
      name: "Tamil Nadu",
    },
    {
      abbreviation: "TS",
      name: "Telangana",
    },
    {
      abbreviation: "TR",
      name: "Tripura",
    },
    {
      abbreviation: "UP",
      name: "Uttar Pradesh",
    },
    {
      abbreviation: "UK",
      name: "Uttarakhand",
    },
    {
      abbreviation: "WB",
      name: "West Bengal",
    },
  ],
  jobsData: [
    {
      jobId: 1,
      jobTitle: "Manager - Home Care Packages Packages",
      jobDesc: [
        "*Join a company that cares about the community - Locally owned and operated",
        "*Manager of Home Care Packages Team",
        " *Generous salary offered combined with additional perks! Enquire now!",
      ],
      jobType: "Permanent",
      jobLocation: "Ahmedabad",
      jobPostDate: "Apr 07, 2022",
      link: "/job-seeker/1",
    },
    {
      jobId: 2,
      jobTitle: "Front end developer (Reactjs)",
      jobDesc: [
        "*Join a company that cares about the community - Locally owned and operated",
        "*Manager of Home Care Packages Team",
        " *Generous salary offered combined with additional perks! Enquire now!",
      ],
      jobType: "Temporary",
      jobLocation: "Ahmedabad",
      jobPostDate: "Apr 07, 2022",
      link: "/job-seeker/2",
    },
    {
      jobId: 3,
      jobTitle: ".Net Backed developer",
      jobDesc: [
        "*Join a company that cares about the community - Locally owned and operated",
        "*Manager of Home Care Packages Team",
        " *Generous salary offered combined with additional perks! Enquire now!",
      ],
      jobType: "Freelancer",
      jobLocation: "Ahmedabad",
      jobPostDate: "Apr 07, 2022",
      link: "/job-seeker/3",
    },
    {
      jobId: 4,
      jobTitle: "Project Manager - Home Care Packages Packages",
      jobDesc: [
        "*Join a company that cares about the community - Locally owned and operated",
        "*Manager of Home Care Packages Team",
        " *Generous salary offered combined with additional perks! Enquire now!",
      ],
      jobType: "Temporary",
      jobLocation: "Ahmedabad",
      jobPostDate: "Apr 07, 2022",
      link: "/job-seeker/4",
    },
  ],
}

export const staticJobs = [
  {
    jobCode: "JB0000007",
    jobTitle: "CTI L3 Engineer",
    jobShortDesc: ` We are currently looking for a CTI L3 Engineer with experience in Genesys CTI. Knowledge of Virtual Hold Technologies, NICE Call Recording Solution, Genesys Micro Apps / Intelligent Automation, Genesys REST API’s/SDK, JavaScript and Java development skills will be an added advantage. `,
    jobAboutDesc: [
      "We are currently looking for a CTI L3 Engineer with experience in Genesys CTI. Knowledge of Virtual Hold Technologies, NICE Call Recording Solution, Genesys Micro Apps / Intelligent Automation, Genesys REST API’s/SDK, JavaScript and Java development skills will be an added advantage. ",
    ],
    jobRoleDesc: [
      `Excellent knowledge of Genesys framework, inbound, outbound, routing, GVP, SIP, GMS, GIR, micro apps, and genesys purecloud.`,
      `Good hands-on experience in the installation and configuring of Genesys applications on RHEL & windows.`,
      `Proficient in reading & analyzing Genesys T-Server, router, GVP, and SIP application logs to trace calls, and agent details to troubleshoot issues.`,
      `Manage and maintain Genesys reporting applications (8. x) - ICON/infomart/insights reporting.`,
      `Working knowledge of Genesys administrator, pulse, CME, CC pulse, and SCI for monitoring and troubleshooting.`,
      `Strong hands-on experience in developing custom reports from Icon & infomart.`,
      `Hands-on experience in creating custom reports, and dashboards in tableau/power BI, creating real-time dashboards in pulse/ccpulse.`,
      `Hands-on experience in routing development using IRD & composer.`,
      `Exposure and knowledge of IVR development using Genesys composer.`,
      `Play a key role and work with team members in resolving service-impacting critical issues.`,
      `Plan and Perform infrastructure maintenance & upgrades for CTI infrastructure.`,
      `Manage and resolve problems identified in the CTI environment.`,
      `Receive little instruction on day-to-day work, and general instructions on new assignments.`,
      `Flexible to work during odd hours/weekends to support production issues/infrastructure upgrades / releases/war rooms.`,
      `Good communication and coordination skills to be able to work with customers to troubleshoot issues over the phone and email.`,
    ],
    jobType: "PER",
    jobTypeDescription: "Permanent",
    jobExp: "5",
    jobSalaty: "26 Lakh per annum",
    jobLocation: "BAN",
    locationDescription: "Bangalore",
    industryType: "18",
    industryDescription: "IT/ ITES/ BPO/ KPO : Consulting and Diversified",
    targetDate: "2022-10-14T00:00:00",
    link: "/job-seeker/JB0000008",
  },
  {
    jobCode: "JB0000008",
    jobTitle: "Java Developer",
    jobShortDesc: ` Hands-on experience in developing, and maintaining robust software applications, web apps and back-office apps. Design, implement and maintain Java-based `,
    jobAboutDesc: [
      "Hands-on experience in developing, and maintaining robust software applications, web apps and back-office apps. Design, implement and maintain Java-based technologies, continual knowledge management and adherence to the organizational guidelines and processes.",
    ],
    jobRoleDesc: [
      `Should possess analytical, design and documentation skills and be able to solve challenging problems.`,
      `Hands-on experience writing software in java and web using HTML, CSS, JavaScript, JSON, XML.`,
      `Experience building applications using struts/spring & J2EE frameworks, web services/RESTful services.`,
      `RDBMS experience with exposure to writing stored procedures and SQL queries.`,
      `Experience working in both linux and windows host environments.`,
      `Experience working with ESBs or similar technologies and backend scheduled jobs is a plus.`,
      `Exposure to agile/scrum and waterfall methodologies.`,
      `Experience working on the banking/finance sector is a plus.`,
      `Able to utilize tools like JIRA, visio and MS Office tools for documentation and work tracking purposes.`,
      `Responsible for software design, development, testing, automation and maintenance of applications across the enterprise.`,
      `Work closely with project managers/scrum leads, QA, product owners and CIOs in creating project breakdowns, and technical delivery schedules and helping them meet all identified milestones.`,
      `Design and develop software solutions that fit within the guidelines set forth by security, architecture and solution leads.`,
      `Responsible for mentoring and guiding junior team members on complex or challenging situations that require advanced knowledge.`,
      `Create documentation and test scripts supporting the development work. Also, provide documentation to release/implementation teams to help them successfully deploy changes to the production environment.`,
      `Support business as usual activities (BAU) with application support and customer operations team including but not limited to resolving production defects, performance issues and system outages.`,
      `Act as a primary support person for applications under his/her responsibility including monitoring for availability and performance.`,
      `Identify and implement new and improved technologies & tools that would benefit supported systems and business.`,
    ],
    jobType: "PER",
    jobTypeDescription: "Permanent",
    jobExp: "5",
    salary: "26 Lakh per annum",
    jobLocation: "BAN",
    locationDescription: "Bangalore",
    industryType: "10",
    industryDescription: "BFSI and Financial",
    targetDate: "2022-10-14T00:00:00",
  },
]

export const ArcodianJobsData = [
  {
    name: "Pune",
    desc: [
      {
        tite: "",
        text: [
          `Pune is one of the fast growing cities in India, with rewarding career prospects and wage growth for professionals at all career levels. As a leading metropolitan city in Maharashtra, the city offers a good quality of life. Pune has all the essential amenities businesses require to thrive and excellent infrastructure. This is the reason the city has been witnessing incredible growth for the last few decades. Pune is an excellent choice for young people new to the job market and early career aspirants to find opportunities and community. This is due to the presence of many colleges, universities, research institutes, and other educational centers, making Pune a popular education hub in India. Exploring job vacancies in Pune might be the game-changing decision that boosts your career.`,
        ],
      },
      {
        title: "Which are the top industries for jobs in Pune?",
        text: [
          `Pune is a vibrant city with a growing job market that offers a multitude of opportunities for professionals. With the IT industry flourishing and attracting huge investments, the top companies for jobs in Pune are accentuated with some of India's most renowned multinationals. Many leading organizations across key sectors like automotive, chemical, engineering, telecommunications, healthcare, manufacturing, and banking & finance have marked their presence in the city.`,
          `With a growing number of vacancies in both blue and grey-collar jobs, Pune offers lucrative prospects to fast-track and advance one's career. As a promising landscape for job seekers, Pune presents numerous opportunities for professional growth and development. As the Indian job market transitions into a digital age, recruiters are seeking blue and grey-collar workers with English language proficiency, computer knowledge, multitasking capabilities, and soft skills such as problem-solving, communication, and time management are highly valued for these roles.`,
          `Skilled jobs in demand for Pune location are software developers, banking, accounting jobs, HR, marketing, advertising, management jobs, healthcare and medical jobs. Semi-skilled jobs in demand are tele-sales executives, field sales, business development, retail/counter sales and BPO, customer service representatives, construction, delivery, logistics, housekeeping, and security.`,
        ],
      },
    ],
  },
  {
    name: "Bangalore",
    desc: [
      {
        title: "",
        text: [
          `Bangalore the capital city of Karnataka also known as the “Silicon Valley of India” or the “IT capital of India” and the nation's leading information technology (IT) exporter, due to the presence of many IT and software companies, including India Inc, global giants, as well as numerous start-ups. As a metro city, the quality of life is notable and it is often considered one of the best places to live in India because of its great weather, cosmopolitan culture, good schools, access to several top-notch educational institutions, decent cost of living, good environment, good standard of living with a range of amenities and affordable public transport.`,
          `Bangalore is a bustling city with several IT companies, making it an attractive destination for job seekers. Finding the latest job opportunities is a hassle-free task due to the frequent availability of job vacancies in Bangalore.`,
          `Bangalore is also known for its vibrant start-up culture, with many new companies set up every year. This provides job seekers with a unique opportunity to work in a dynamic and growing environment and be a part of the innovation culture.`,
        ],
      },
      {
        title: "Which are the top industries for jobs in Bangalore?",
        text: [
          `The city is home to major sectors like information technology, electronics, telecommunications, aerospace, pharmaceuticals, biotechnology, food processing, automobiles, banking and finance, healthcare, finance, retail, e-commerce mining, steel, and cement production that create a substantial number of jobs every year.`,
          `This diversity in industries provides job seekers with a wide range of job options to explore based on their skills and interests. This creates a high demand for skilled professionals in areas such as software development, data analytics, cloud computing, artificial intelligence, data engineers machine learning, data analyst, business development, product and account manager, HR jobs, finance/accounting sales and marketing to name a few. Bangalore also has a large and diverse professional network of manual jobs like delivery personnel, pickers, packers, construction workers, electricians, plumbers, drivers, security guards, housekeepers and cooks.`,
          `However, it is worth noting that competition for jobs in Bangalore can be high due to the large number of job seekers and the demand for skilled talent. It's important to research the job market, understand the skill requirements, and prepare yourself accordingly to enhance your chances of success. Bangalore is a prime destination for professional seeking growth and development.`,
        ],
      },
    ],
  },
  {
    name: "Ahmedabad",
    desc: [
      {
        title: "",
        text: [
          `Ahmedabad is known as the textile hub of India and is famous for its textile industry. One of the fastest-growing cities in India, it is a centre for information technology, education, and industries. It is the fifth most populated city in India & was ranked third in the Forbes list of fastest-growing cities of the decade. This development has led to the creation of job vacancies in Ahmedabad across various industries which is leading to fast-phase growth in long-term hiring trends amongst emerging non-metro cities. There are more than 23,000 small-scale industries and more than 400 large and medium-scale industries. It is the hottest investment destination due to the presence of several industrial estates and industrial parks.`,
        ],
      },
      {
        title: "Which are the top industries for jobs in Ahmedabad?",
        text: [
          `Ahmedabad boasts a wide range of job opportunities in different industries, catering to both entry-level job seekers and seasoned professionals. The key industries in Ahmedabad include chemical, fabric, jewellery, gems manufacturing, pharmaceutical, agriculture & food processing, automobiles, engineering, electronics, tourism, and textiles. The demand for job openings is driven by the requirement of trainers, digital marketing professionals, software developers, finance & HR specialists to name a few followed by data entry, banking, security, back-office jobs, machine operators, assemblers, packers, quality control inspectors, construction jobs, healthcare, operations, and customer support executives. All these factors create high compatibility for job seekers to find their dream job.`,
          `Looking for a job in Ahmedabad apply here on the Adecco India website and social channels for opportunities with top organizations.`,
        ],
      },
    ],
  },
  {
    name: "Bhubaneswar",
    desc: [
      {
        title: "",
        text: [
          `Bhubaneswar is rapidly growing to be one of the leading industrial and financial locations in the country. It is scenic, lively, green, and clean, with a rich cultural life. Known for its temples, the city is a major center of attraction for tourists. A lot of work opportunities are being generated in the city due to consistent growth in many sectors and industries and there is immense scope for work opportunities in Bhubaneswar. The presence of three major IT parks: Info Park, Knowledge Industry Township, and IDCO-Genpact BPO Complex has added the necessary infrastructure and pace to the city's economic growth. The development of these industries in the past decade has paved the way for the growth of subsidiary sectors such as banking, medical services, electricity, and firefighting services in Bhubaneswar.`,
        ],
      },
      {
        title: "Which are the top industries for jobs in Bhubaneswar?",
        text: [
          `The key industries creating jobs in Bhubaneswar are travel and tourism, textile, agriculture, sericulture, IT services, horticulture, agro and chemical products, engineering, metal, and minerals. These sectors also contribute significantly to the city's economy and offer many employment opportunities. With continuous investments and focus the authorities are taking sizable steps to introduce many new sectors and manufacturing plants, guaranteed to generate new jobs in Bhubaneswar. Hiring activities have been on the rise for small and medium businesses for job roles such as digital marketing, event marketing, civil engineers, business development executives, hospitality, telecallers/BPO, sales, computer/data entry operators, delivery persons, electricians, machine operators, packers, quality control inspectors, retails sales, housekeeping amongst others.`,
          `Connect with us if you are scouting for a job in Bhubaneswar with prominent Indian and multinational companies and we will guide you to follow a systematic approach to get your dream job.`,
        ],
      },
    ],
  },
  {
    name: "Chennai",
    desc: [
      {
        title: "",
        text: [
          `Chennai is the capital city of Tamil Nadu and is popularly called the 'Gateway to South India'. Chennai is known for its South Indian architecture and traditional art forms, such as ancient temples, vibrant arts, natural wonders, and a bustling culinary scene. It is also touted as the second-largest exporter of IT & BPO services in India and is one of the most popular cities for job seekers. Chennai has made remarkable progress in the industrial, healthcare, and service sectors which has led to an exponential increase in career opportunities. The city is home to several leading hospitals and medical research centers, as well as top universities and business schools. The finance sector is also expanding, with major banks and financial institutions setting up operations in the city. Chennai offers multiple employment opportunities for talented individuals, as it attracts some of the brightest minds in the country. With a thriving economy, there are several job vacancies in Chennai waiting to be filled.`,
        ],
      },
      {
        title: "Which are the top industries for jobs in Chennai?",
        text: [
          `Are you looking for a job in Chennai? There are various opportunities in the city across industries. The city has a healthy employment rate with top companies and popular job vacancies in IT, technology, engineering, automobile, healthcare, education, finance, back office, travel & hospitality, and HR amongst others. There is a multitude of jobs in Chennai like developers, AI and ML, data scientists, store managers, production associates, HR, Finance, medical assistance, digital marketing, telecaller, data analyst, technical consultant, machine operators, maintenance personnel, quality manager, construction workers, electricians, plumbers, mechanics, and factory workers, that require manual labor and/or technical skills.`,
          `Adecco can help connect ambitious and skilled fresh graduates and seasoned professionals with job opportunities offered by some of the world’s leading organizations - reputed small mid and large Indian companies and start-ups. If you are looking to build a professional career, apply here where all the available jobs in Chennai are listed and updated regularly. Once you apply to your preferred job, prepare well for the next stage you can follow up on the application status with the HR team and the rest will fall into place.`,
        ],
      },
    ],
  },{
    name:"Coimbatore",
    desc:[{
      title:"",
      text:[`Coimbatore is the second largest city in the southern Indian state of Tamil Nadu. It is one of the most industrialized cities and is famous as the textile capital of South India or the Manchester of the South. With a growing economy and a thriving industrial sector and organizations that have established themselves the city is generating numerous opportunities for job seekers who are looking to build a career. Coimbatore is also the 15th most competitive city in India in terms of business scope and opportunities. The city has been experiencing high job growth with wage growth and a low unemployment rate.`]
    },{
      title:"Which are the top industries for jobs in Coimbatore?",
      text:[`Prominent industries in Coimbatore include textile manufacturing, varied engineering goods and services, information technology, automobile, healthcare, education, manufacturing, cement, and construction industries. There is consistent demand for tech positions such as software developers, data scientists, analytics managers, big data engineers, cloud system administrators, QA testers. DevOps and DevSec engineers, and data scientists respectively. Some of the most common non-tech jobs in Coimbatore include machine operators, textile consultants, pickers, packers, merchandisers, and quality control inspectors.`,
    `Many industries and sectors have established themselves in the city, and there are a multitude of jobs in Coimbatore for fresher as well as experienced professionals. If you are looking for a job in Coimbatore apply here on the Adecco India website`]
    }]
  },{
    name:"Goa",
    desc:[{
      title:"",
      text:[`Goa is one of the most famous and popular tourist destinations among both international and domestic tourists due to its pristine beaches, rich heritage, warm and friendly people, tropical climate, scenic beaches, and exotic handicrafts. Goa is one of the richest states with the highest GDP per capita and tourism is Goa’s core industry. The increasing number of visitors to Goa each year has led to the rise of private jobs in Goa, making it relatively easier for job seekers to find work due to the rapid growth of various industries and local companies with something for everyone. The state's vibrant culture and lifestyle make it an attractive destination for job seekers looking for a work-life balance.`]
    },{
      title:"Which are the top industries for jobs in Goa?",
      text:[`he state's economy is primarily driven by tourism, mining, agriculture, and manufacturing, which offer various employment opportunities to the residents of Goa. The state also offers diverse employment opportunities in several other sectors like IT, education, healthcare hotel management, food and beverage service, travel agencies, tour operators, event management, mining engineering, operations & processing, geology, farming, livestock management, horticulture, agro-processing, production management, quality control, research and development, technical operations, software development, web development, mobile application development, digital marketing, teachers, professors, and administrative staff. There are many other jobs available in Goa for freshers such as telecaller, sales, cleaner, cook, delivery boy, beverage manager, housekeeping, front office etc.`,
    `If you are looking to find a job in Goa connect with us through our website as well as our social channels where you will have access to all the recent job vacancies in your field of interest and skill as there are many well-paying openings in the city, keep a track and connect with the HR team directly to check and schedule interviews.`]
    }]
  }
]
