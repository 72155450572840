import AdeccoSupportsPlanInternationalImg from "../src/images/CSR/Adecco-Supports-Plan-Internationals-Saksham.webp"
import CEOImg from "../src/images/CSR/CEOforOneMonth(CSR).webp"
import contributionImg from "../src/images/CSR/contribution-to-PM-fund.webp"
import Win4YouthImg from "../src/images/CSR/Win4Youth(CSR).webp"

export const socialResponsibilty = {
  title: "Social Responsibility",
  desc:
    "The world of work is changing rapidly, and it is important that no one is left behind. At Adecco, we are removing the barriers that prevent thousands from finding meaningful work, while we strive to create social and economic value to our stakeholders. By focusing on real challenges that underserved populations face, we are scanning the landscape and building and scaling practical solutions, and partnerships that have big impact on employability and access to labour markets. An impactful future hinge on collaboration and complementarity, as no entity can create this change alone. We invite you to be part of this journey.",
  dataTitle: "Programs",
  responsibilityData: [
    {
      img: Win4YouthImg,
      subTitle: "Win4Youth",
      description: "Changing lives through sports",
      content:
        "Encourages colleagues, associates, and clients to participate in athletic activities to raise funds to support children and young people to be work-ready. Win4Youth inspires Adecco Group employees, associates, and clients to live an active and healthy life by engaging in sports. The model is simple: log your kilometers or time spent doing your favorite sports, and raise money for programmes that help young people around the world to be work-ready.",
    },
    {
      img: CEOImg,
      subTitle: "CEO for One Month",
      description: "Helping young talent get ready for work",
      content:
        "Empowers young people with the skills and experience necessary to join in the world of work. Many young people struggle to find a way into work because they lack the experience and skills that companies look for. Through CEO for One Month and Experience Work Day, we give young people hands-on experience to help them to get a foot on the career ladder and prepare for success.",
    },

    {
      img: AdeccoSupportsPlanInternationalImg,
      subTitle: "Adecco Supports Plan International's Saksham",
      description: "",
      content:
        "Joined hands with Plan India, through their Saksham initiative which undertakes an intensive upskilling program for underprivileged youth. The program supported over 200 young women & men between 18-24 years from the disadvantaged communities of Bengaluru improve their technical, vocational, and life skill knowledge enabling them to build their careers in new economy jobs across hospitality, retail and IT-enabled services; reiterating our commitment towards ethical social projects to provide education and job training opportunities.",
    },

    {
      img: contributionImg,
      subTitle: "Contribution to PM-CARES Fund",
      description: "",
      content:
        "In our endeavor to support the fight against the COVID-19 pandemic and make a difference to the vulnerable community, we contributed INR 1.04 crores to the honorable Prime Minister's relief fund. We also encouraged our extended community of clients, associates, and employees to step forward to do their personal best. Moreover, we worked in lockstep with our stakeholders, candidates and job seekers leveraging technology and online tools to ensure hiring was not impacted and career aspirations remained unaffected.",
    },
  ],
}
