export const LocationData = [
  {
    cityname: "Ahmedabad",
    url: "#",
  },
  {
    cityname: "Mumbai",
    url: "#",
  },
  {
    cityname: "Delhi",
    url: "#",
  },
  {
    cityname: "Agra",
    url: "#",
  },
  {
    cityname: "Bangalore",
    url: "#",
  },
  {
    cityname: "Chennai",
    url: "#",
  },
  {
    cityname: "Kolkata",
    url: "#",
  },
  {
    cityname: "Lucknow",
    url: "#",
  },
  {
    cityname: "Nagpur",
    url: "#",
  },
  {
    cityname: "Jaipur",
    url: "#",
  },
  {
    cityname: "Surat",
    url: "#",
  },
  {
    cityname: "Pune",
    url: "#",
  },
  {
    cityname: "Kochi",
    url: "#",
  },
  {
    cityname: "Hyderabad",
    url: "#",
  },
  {
    cityname: "Bhopal",
    url: "#",
  },
  {
    cityname: "Indore",
    url: "#",
  },
  {
    cityname: "Goa",
    url: "#",
  },
]

export const hardWorkData = {
  bannerDesc: `Send your CV to us and we'll start searching for your next role.`,
  desc: [
    `Adecco has hundreds of jobs available in locations across the country each week. By sending your CV to us we will be able to match your skills, qualifications and expertise to the right job for you.`,
    `Once you submit your CV an Adecco team member will get in touch with you to discuss exactly what you are looking for. Our team of talented recruiters can't wait to meet you.`,
    `Together, let’s create positive, long-lasting value for our clients and communities.`,
  ],
}
