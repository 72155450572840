import { Col, Row } from "antd"
import React from "react"
import "./common.less"

const Activity = ({ data, css }) => {
  return (
    <section className={`aboutActivitySection ${css}`}>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="mainGrid">
            {data.map(m => (
              <div className="activity" key={m.number}>
                <div className="icon">
                  <img loading="lazy"  src={m.img} alt={m.number} />
                </div>
                <h4>{m.number}</h4>
                <p>{m.desc}</p>
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </section>
  )
}

export default Activity
