import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { Button, Col, Empty, Row, Skeleton } from "antd"
import { APIContext } from "../../helper/APIContex"
import longRight from "../../images/icons/long-right.webp"

const Vacancies = () => {
  const { data } = React.useContext(APIContext)
  const { loading, jobsbyCityData, jobsbyIndustryData } = data
  const [tab, setTab] = useState("state")

  return (
    <section className="vacancieSection px-3 px-md-6">
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="heading">
              <h2
                className={tab === "state" ? "active" : ""}
                onClick={() => setTab("state")}
              >
                Jobs by City
              </h2>
              <span className="px-2">|</span>
              <h2
                className={tab === "industry" ? "active" : ""}
                onClick={() => setTab("industry")}
              >
                Industry
              </h2>
            
          </div>
        </Col>
      </Row>
      {tab === "state" ? (
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="jobData">
              {jobsbyCityData && jobsbyCityData?.length > 0 ? (
                loading ? (
                  <Skeleton
                    paragraph={{
                      rows: 10,
                    }}
                  />
                ) : (
                  <ul>
                    {jobsbyCityData
                      ?.sort((a: any, b: any) => {
                        if (a.locationDescription < b.locationDescription)
                          return -1
                        if (a.locationDescription > b.locationDescription)
                          return 1
                        return 0
                      })
                      ?.map(
                        data =>
                          data.locationDescription !== "Other" && (
                            <li
                              className="d-flex  justify-content-space-between align-items-center"
                              key={data.loctionCode}
                            >
                              <Link
                                state={{
                                  type: "JobByLocation",
                                  data: data.loctionCode,
                                }}
                                to={`/job-seeker/jobs-in-${data.url}?type=JobByLocation&ID=${data.loctionCode}`}
                              >
                                {data.title} <img loading="lazy"  src={longRight} alt="rigth-Arro-Image" />
                              </Link>
                            </li>
                          )
                      )}
                    {jobsbyCityData.map(
                      data =>
                        data.locationDescription === "Other" && (
                          <li
                            className="d-flex  justify-content-space-between align-items-center"
                            key={data.loctionCode}
                          >
                            <Link
                              state={{
                                type: "JobByLocation",
                                data: data.loctionCode,
                              }}
                              to={`/job-seeker/jobs-in-${data.url}?type=JobByLocation&ID=${data.loctionCode}`}
                            >
                              {data.title} <img loading="lazy"  src={longRight} alt="rigth-Arro-Image" />
                            </Link>
                          </li>
                        )
                    )}
                  </ul>
                )
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className={"text-center"}
          >
            <Button type="danger" size="large" onClick={()=>navigate("/job-seeker/")}>
                View all vacancies
            </Button>
          </Col>
        </Row>
      ) : null}
      {tab === "industry" ? (
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="jobData1">
              {jobsbyIndustryData && jobsbyIndustryData?.length > 0 ? (
                loading ? (
                  <Skeleton
                    paragraph={{
                      rows: 10,
                    }}
                  />
                ) : (
                  <ul>
                    {jobsbyIndustryData
                      ?.sort((a: any, b: any) => {
                        if (a.industryDescription < b.industryDescription)
                          return -1
                        if (a.industryDescription > b.industryDescription)
                          return 1
                        return 0
                      })
                      ?.map(data => (
                        <li
                          className="d-flex  justify-content-space-between align-items-center"
                          key={data.industryCode}
                        >
                          <Link
                            state={{
                              type: "JobByIndustry",
                              data: data.industryCode,
                            }}
                            to={`/job-seeker/jobs-in-${data.url}?type=JobByIndustry&ID=${data.industryCode}`}
                          >
                            {data.title}
                            <img loading="lazy"  src={longRight} alt="rigth-Arro-Image" />
                          </Link>
                        </li>
                      ))}
                  </ul>
                )
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className={"text-center"}
          >
            <Button type="danger" size="large" onClick={()=>navigate("/job-seeker/")}>
                View all vacancies
            </Button>
          </Col>
        </Row>
      ) : null}
    </section>
  )
}

export default Vacancies
