import React from "react";
import { Link } from "gatsby";
import { Col, Row,Card } from "antd";
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent";
import { NewBanner } from "../../../components/Solutions/newBanner";
import BreadcrumbSec from "./Breadcrumb";
import Sidebar from "./sidebar";
import fistImag from "../../../images/solutions/FSM/5.png";
import secondImag from "../../../images/solutions/FSM/4.png";
import ThirdImg from "../../../images/solutions/FSM/1.png";
import fourthImg from "../../../images/solutions/FSM/2.png";
import FifthImg from "../../../images/solutions/FSM/3.png";
import banImg from "../../../images/solutions/banner/Outsourcing-Services.webp";
import Stories from "../../../components/employerHomePage/stories";
import { blogVideoData } from "../../../../services/MediaData";
import VideosCardSolution from "../../../components/videos/videosCardSolution";

import "./common.less";

const FieldSalesMarketingOutsourcingServices  = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <NewBanner BannerImg={banImg} title={"Field Sales and Marketing Outsourcing"} />
      <BreadcrumbSec current={"Field Sales and Marketing Outsourcing"} />

      <section className="mainSolutionSection px-3 px-md-6">
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="float-right">
            <div className="box">
              <div className="heading">
                <h1>Field Sales and Marketing Outsourcing</h1>
                <span className="separator"></span>
              </div>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">
                Building agile & adaptable solutions{" "}
                </h2>
              </div>
              <p className={""}>
              We specialize in Field Sales and Marketing Outsourcing activities and other critical functions. Our expert teams operate across various venues, including retail locations, events, airports, and other points of sale. Leveraging our workforce management expertise, we ensure comprehensive coverage across all distribution channels and industries. 
              </p>
              <p className={"text-danger text-bold"}>
              Our Field Sales and Marketing Outsourcing services support successful market entries, product launches, and end-to-end sales force management. We deliver cost-effective, adaptable solutions for global and multi-country projects, combining cutting-edge technology, innovation, and expertise. 
              </p>
              <p>
              By outsourcing non-core functions, our clients can focus on core activities, increasing productivity, speed, scale, and cost savings while ensuring compliance. Our industry-leading solutions build business intelligence and compliance excellence through a structured process of project initiation, design, execution, and continuous improvement. With years of experience, AI-powered FSM technology, and a focus on global standardization and talent management, we deliver exceptional service and compliance. 
              </p>

              <div className="solution-icons c-card">
                <h2 className="title">Areas of expertise </h2>
                <Row gutter={[10,24]} type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div style={{height:'100%'}}>
                <Card title={
                  <div className="d-flex justify-content-space-between align-items-center">
                  <h5 className="m-0 p-0"><strong>Salesforce</strong></h5>
                    <img loading="lazy"  src={fistImag} alt="Salesforce" width={'10%'} />
                  </div>}>
                  <div>
                    <ul>
                      <li className="card-li">Permanent Salesforce (Morden / Traditional Trade),</li>
                      <li className="card-li">Seasonal Selesforce</li>
                      <li className="card-li">Business Concepts selling</li>
                      <li className="card-li">B-2-C selling</li>
                    </ul>
                  </div>
                </Card>
                </div>
                </Col>

                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div style={{height:'100%'}}>
                <Card title={
                  <div className="d-flex justify-content-space-between align-items-center">
                  <h5 className="m-0 p-0"><strong>Merchandising</strong></h5>
                    <img loading="lazy"  src={secondImag} alt="merchandisingImg" width={'10%'} />
                  </div>}>
                  <div>
                    <ul>
                    <li className="card-li">Shel Space Management</li>
                    <li className="card-li">Inventory Management</li>
                    <li className="card-li">Planogram Maintenance</li>
                    <li className="card-li">Product Displays, Point Of Purchase and Promotional Material Installatons</li>
                    <li className="card-li">Fixture Installation and Tracking</li>
                    <li className="card-li">Shelf Resets and Remodels</li>
                    </ul>
                  </div>
                </Card>
                </div>
                </Col>

                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <div style={{height:'100%'}}>
                <Card title={
                  <div className="d-flex justify-content-space-between align-items-center">
                  <h5 className="m-0 p-0"><strong>Promotions</strong></h5>
                    <img loading="lazy"  src={ThirdImg} alt="Promotions" width={'10%'} />
                  </div>}>
                  <div>
                    <ul>
                    <li className="card-li">Brands Ambassadorship incl. products sell-out</li>
                    <li className="card-li">Products presentations</li>
                    <li className="card-li">Sampling</li>
                    <li className="card-li">Tasting Events</li>
                    <li className="card-li">Exhibitions</li>
                    </ul>
                  </div>
                </Card>
                </div>
                </Col>
                </Row>
                <Row gutter={[10,24]} type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                <div style={{height:'100%'}}>
                <Card title={
                  <div className="d-flex justify-content-space-between align-items-center">
                  <h5 className="m-0 p-0"><strong>Marketing Field Audits</strong></h5>
                    <img loading="lazy"  src={fourthImg} alt="Marketing Field Audits" width={'10%'} />
                  </div>}>
                  <div>
                    <ul>
                    <li className="card-li">Sales Audits</li>
                    <li className="card-li">Price, Facings, Shell Space</li>
                    <li className="card-li">Data Collection</li>
                    <li className="card-li">Planogram & Fixture Compliance</li>
                    <li className="card-li">Mystery Shopping</li>
                    <li className="card-li">Customer Surveys</li>
                    </ul>
                  </div>
                </Card>
                </div>
                </Col>

                <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                <div style={{height:'100%'}}>
                <Card title={
                  <div className="d-flex justify-content-space-between align-items-center">
                  <h5 className="m-0 p-0"><strong>Business Intelligence</strong></h5>
                    <img loading="lazy"  src={FifthImg} alt="Business Intelligence" width={'10%'} />
                  </div>}>
                  <div>
                    <ul>
                    <li className="card-li">Support & optimization of SFA system</li>
                    <li className="card-li">Single reports or analysis</li>
                    <li className="card-li">Multi-data analytics</li>
                    <li className="card-li">Advance Power BI tools</li>
                    </ul>
                  </div>
                </Card>
                </div>
                </Col>
                </Row>
                
              </div>
              <br/>
              <p>
              Looking to optimize your field sales and marketing efforts? Whether you seek to understand how outsourcing can benefit your business or need a bespoke solution, our experts are here to assist you. Contact us today for a free consultation and discover how Adecco’s comprehensive solution ecosystem can support all your needs. 
              </p>
              <div className="text-center">
                <Link
                  to="/employer/business-enquiry/"
                  state={{ interested: "General Staffing" }}
                >
                  Business Enquiry
                </Link>
              </div>
            </div>
          </Col>
          <Sidebar title={'Field Sales and Marketing Outsourcing'} />
        </Row>
        {/* <Row type="flex" align="end">
        <Col xs={24} sm={24} md={16} lg={16} xl={16} >
          <Row type="flex" align="center">
            <Col xs={24} sm={24} md={16} lg={10} xl={10}>
            <VideosCardSolution data={blogVideoData[0]} isvc={true} />
            </Col>
          </Row>
        </Col>
        </Row> */}
        <Stories heading="Case Study" isReadMore={false} />
      </section>
    </PageContentEmployerScreen>
  )
}

export default FieldSalesMarketingOutsourcingServices 
