import blog1 from "../src/images/Blogs/Welcome-video.png"
import blog2 from "../src/images/Blogs/CEO-for-One-Month(testimonial).png"
import blog3 from "../src/images/Blogs/CEO-for-One-Month-2022-(Bootcamp).png"


import outsourcingThum from "../src/images/solutions/videothum/outsourcing.webp";
import permanentplacementsThum from "../src/images/solutions/videothum/permanentplacements1.webp";




export const mediaData = [
  {
    link: "https://www.youtube.com/watch?v=zO1GDko4HTE",
    author_name: "The Adecco Group, India",
    img: "https://i.ytimg.com/vi/zO1GDko4HTE/hqdefault.jpg",
    type: "video",
    title: "#LifeAtAdecco | Sanketh Chengappa",
  },
  {
    title: "Adecco Global Corporate video - #Adecco #Pontoon & #LHH",
    img: "https://i.ytimg.com/vi/DCufY4b2XgQ/hqdefault.jpg",
    type: "video",
    link: "https://www.youtube.com/watch?v=Rson7582i2c",
    author_name: "The Adecco Group, India",
  },
  {
    img: "https://i.ytimg.com/vi/Rson7582i2c/hqdefault.jpg",
    type: "video",
    link: "https://www.youtube.com/watch?v=9hHJUvr3avY",
    author_name: "The Adecco Group, India",
    title: "Global Talent Competitiveness Index 2020.",
  },
  {
    type: "video",
    img: "https://i.ytimg.com/vi/Rson7582i2c/hqdefault.jpg",
    title: "Global Talent Competitiveness Index 2020.",
    link: "https://www.youtube.com/watch?v=DCufY4b2XgQ",
    author_name: "The Adecco Group, India",
  },
  {
    title: "Aditya Bansal, CEO for One Month 2019, The Adecco Group India",
    link: "https://www.youtube.com/watch?v=Ii2koaKl7-w&t=4s",
    author_name: "The Adecco Group, India",
    type: "video",
    img: "https://i.ytimg.com/vi/Ii2koaKl7-w/hqdefault.jpg",
  },
]

export const SolutionsVideo=[
  {
    img: outsourcingThum,
    type: "video",
    title:'Outsourcing Services',
    iframe: `<iframe width="100%" height="300" src="https://www.youtube.com/embed/C5oaQKkEG1I?si=FyLzd7L3Jt7ADjUH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
  {
    img: outsourcingThum,
    type: "video",
    title:'Field Sales and Marketing Outsourcing',
    iframe: `<iframe width="100%" height="300" src="https://www.youtube.com/embed/C5oaQKkEG1I?si=FyLzd7L3Jt7ADjUH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
  {
    img: permanentplacementsThum,
    type: "video",
    title:'Permanent Recruitment',
    iframe: `<iframe width="100%" height="300" src="https://www.youtube.com/embed/tuVEC9oYf1A?si=Gae-9_HYi_-VXn2E" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
  },
]
export const blogVideoData = [
  {
    img: blog1,
    type: "video",
    title: "Adecco India - Welcome Video",
    iframe: `<iframe width="100%" height="300" src="https://www.youtube.com/embed/dvy0QGNsfuo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
  {
    title: "CEO for One Month 2022 | Testimonials",
    img: blog2,
    type: "video",
    iframe: `<iframe width="100%" height="300" src="https://www.youtube.com/embed/VLWhuqKMhEI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
  {
    img: blog3,
    type: "video",
    title: "CEO for One Month 2022 | Bootcamp",
    iframe: `<iframe width="100%" height="300" src="https://www.youtube.com/embed/d1TajLt16Sg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
  },
]
