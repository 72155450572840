import React from "react";
import data from "../../services/homepageData";
import Footer from "../components/Footer/Footer";
import { PageContentHomeScreen } from "../components/PageContent/PageContent";
import { IDefaultPageProps } from "../constants";
import Banner from "../components/JobSeekerHomePage/banner";
import Vacancies from "../components/JobSeekerHomePage/vacancies";
import Benefits from "../components/JobSeekerHomePage/benefits";
import Testimonial from "../components/JobSeekerHomePage/testimonial";
import Register from "../components/JobSeekerHomePage/register";
import Advice from "../components/JobSeekerHomePage/advice";
import CustomCarousel from "../components/CustomCarousel/CustomCarousel";
import Disclaimer from "../components/Disclaimer/disclaimer";
import { DisclaimerData } from "../../services/disclaimerData";
import { testimonialsData } from "../../services/jobSekeerHomePageData";
import "../styles/app.less";
import "../components/HomePage/HomePageStyle.less";

class App extends React.Component<IDefaultPageProps> {
  constructor(props) {
    super(props)
    this.state = {
      showVideoModal: false,
      selectedMediaItem: {},
      fullpageApiObject: null,
      disclaimerVisibility: false,
      buttonVisibility: false,
      loading: false,
      jobsbyCityData: [],
      jobsbyIndustryData: [],
    }
  }

  componentDidMount() {
    let visited = sessionStorage["alreadyVisited"]

    if (visited && sessionStorage.getItem("clicked")) {
      this.setState({ disclaimerVisibility: false })
    } else {
      sessionStorage["alreadyVisited"] = true
      this.setState({ disclaimerVisibility: true })
    }
  }
  changeDisclaimerView = (value: boolean) => {
    this.setState({ setDisclaimerVisibility: value })
  }

  handleDisclaimerButton = (value: boolean) => {
    sessionStorage.setItem("clicked", value)
    this.setState({ buttonVisibility: value })
  }

  render() {
    const { disclaimerVisibility } = this.state
    return (
      <div className="overflowX-hidden">
        <PageContentHomeScreen location={this.props.location}>
          <Banner />
          <Vacancies />
          <Benefits />
          <Testimonial data={testimonialsData} />
          <Register />
          <Advice title={"Career Advice"} slidesToShow={4} />
          <CustomCarousel />
          {disclaimerVisibility && (
            <Disclaimer
              title="ATTENTION: RECRUITMENT FRAUD ALERT"
              isVisible={disclaimerVisibility as boolean}
              getValueDisclaimer={this.changeDisclaimerView}
              disclaimerData={DisclaimerData()}
              getValueButton={this.handleDisclaimerButton}
            />
          )}
          <Footer pageData={data.footer} />
        </PageContentHomeScreen>
      </div>
    )
  }
}

export default App
