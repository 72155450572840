import React, { useEffect, useState } from "react";
import { Link } from "gatsby"
import { Breadcrumb, Col, Row } from "antd";
import Banner from "./banner"
import { StoriesData } from "../../../../services/EmployerHomePageDara"
import AtAGlance from "./at-a-glance"
import calendar from "../../../images/calendar.webp";
import backIcon from "../../../images/icons/icons8-back-24.webp";

export default function DetailsPage({ id }) {
  const [caseStudyData, setCaseStudyData] = useState([])
  useEffect(() => {
    if (id) {
      const tem: any = StoriesData?.data?.find(data => data.id === id)
      if (tem) setCaseStudyData(tem)
    }
  }, [id])
  return (
    <>
      <Banner title={"Case Study"} BannerImg={caseStudyData?.bannerImg} />
      <section className="case-details mx-sm-6 mx-2">
        <Row className="custome-breadcrumb mt-5 pt-5">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/employer/case-study"}>
                  <img loading="lazy"  src={backIcon} alt="Back to Case Study Icon"/> Back to Case Study
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className="m-3 m-sm-0">
          <Col xs={24} xl={14} lg={14} md={12} sm={24}>
            <Row>
              <Col xs={24} lg={24} md={24} sm={24}>
                {caseStudyData?.title && (
                  <h2 className="js_heading">{caseStudyData?.title}</h2>
                )}
              </Col>
              <Col xs={24} lg={24} md={24} sm={24}>
                <ul className="timeLocation">
                  <li>
                    <img loading="lazy"  src={calendar} alt="calendar img"/> <span>{caseStudyData?.date}</span>
                  </li>
                </ul>
              </Col>
              {caseStudyData?.subTitle && (
                <Col xs={24} lg={24} md={24} sm={24} className="js_details">
                  <h2>{caseStudyData?.subTitle}</h2>
                </Col>
              )}
              {caseStudyData?.details?.map(data => (
                <Col
                  xs={24}
                  lg={24}
                  md={24}
                  sm={24}
                  className="js_details"
                  key={data.title}
                >
                  {data.title && <h2>{data.title}</h2>}
                  {data.IsBulletPoints ? (
                    <>
                      <p>{data.desc}</p>
                      <br />
                      <ul>
                        {data?.points?.map(point => {
                          if (data.isSubPoints) {
                            if (point.sub.length > 0) {
                              return (
                                <li>
                                  <p>{point.main}</p>
                                  <u style={{ textDecoration: "none" }}>
                                    {point.sub.map(subP => (
                                      <li key={subP}>
                                        <p>{subP}</p>
                                      </li>
                                    ))}
                                  </u>
                                </li>
                              )
                            } else {
                              return (
                                <li key={point.main}>
                                  {" "}
                                  <p>{point.main}</p>
                                </li>
                              )
                            }
                          } else {
                            return (
                              <li key={point}>
                                {" "}
                                <p>{point}</p>
                              </li>
                            )
                          }
                        })}
                      </ul>
                    </>
                  ) : (
                    <p>{data.desc}</p>
                  )}
                </Col>
              ))}
            </Row>
          </Col>
          <Col
            xs={24}
            xl={8}
            lg={8}
            md={11}
            sm={24}
            className="ml-md-auto float-md-right pos_sticy"
          >
            <AtAGlance data={caseStudyData?.atGlance ?? []} />
          </Col>
        </Row>
      </section>
    </>
  )
}
